import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.css']
})
export class CookieComponent implements OnInit {
  accepted = ""
  showItem = false;
  route:any;
  isHome=false
  constructor(location: Location, router: Router) {
    router.events.subscribe((val) => {
      if(location.path() != ''){
       this.isHome = false
      } else {
        this.isHome = true 
      }
      // console.log('roote',this.route);
      
    });
  }

  ngOnInit() { 
  this.accepted = localStorage.getItem("cookies")
  if(this.accepted=="accepted"){
    this.showItem=false;
  }
  else {
    this.showItem=true;
  }

  }
  acceptCookie(){ 
    this.accepted = "accepted";
    localStorage.setItem("cookies",this.accepted);
    this.showItem=false;

  }
  close(){
    this.accepted="NoAccepted";
    this.showItem=false;
  }
}
