import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { News } from 'src/app/modules/products/news';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  @Input() listNews: News[]= [];
  @Output() viewDetails: EventEmitter<News> = new EventEmitter<News>();

  
  constructor(private router: Router) { }

  ngOnInit() {
  }
  navigateTo(cardId: any){
    this.viewDetails.emit(cardId)
    console.log('cardId111111: ', cardId);
    this.router.navigate([`news/newsletter/${cardId}`])
  }

}
