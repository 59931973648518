<footer class="pq_footerBlock">
    <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-3 logoFooter">
            <img loading="lazy"  src="/assets/headerfooter/logo.webp" alt="logo"/>
              <span>Unlocking Business Value</span>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-3 ContactInfo">
            <h2 class="titleItemFooter"  [routerLink]="['about-us']">About Us</h2>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-3 ContactInfo">
            <h2 class="titleItemFooter" [routerLink]="['careers']">Careers</h2>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-3 socials" style="padding-right: 0%;">
            <a href="https://www.linkedin.com/company/peaqock-financials/mycompany/" aria-label="linkedin" accesskey="l" target="_blank">
                <div class="image-back linked-img"></div>
            </a>
            <a href="https://www.facebook.com/peaqock" aria-label="facebook" target="_blank" accesskey="f">
                <div style="margin: 0 8px" class="image-back fb-img"></div>
            </a>
            <a href="https://twitter.com/peaqockf" aria-label="Twitter" target="_blank" accesskey="t">
                <div class="image-back twitter-img"></div>
            </a>
        </div>
    </div>
    <div class="footer-separator"> </div>
    <div class="row">
        <div class="privacy-state col-md-2 col-sm-12 col-xs-12" style="padding-right:0% ;" [routerLink]="['/privacy']">
            Privacy Statement
        </div>
        <div class="privacy-state privacy-policy col-md-2 col-sm-12 col-xs-12" [routerLink]="['/privacy']">
            Cookie policy
        </div>
        <div class="rights col-md-8 col-sm-12 col-xs-12">
            <div >© 2020 Peaqock. All Rights Reserved.</div>
        </div>
    </div>
</footer>
