
<div id="who-we-are"></div>

<div class="video full-width">
    <div class="video-container">
        <video autoplay muted loop id="myVideo">
            <source src="/assets/slidehome/video_slide.mp4" type="video/mp4">
            Your browser does not support HTML5 video.
        </video>
        <div class="video-title">
          We are experts in providing fast and intelligent technology solutions to our clients. We are devoted to delivering innovative services using the most recent technologies such as Artificial Intelligence, Blockchain, and Machine Learning, to empower businesses to stay ahead of the competition.
        </div>
        <div class="video-background">
        </div>
    </div>
</div>
<div class="block1 core-section">
    <div style="width: 100%;" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right" class="row">
        <div class="blockDetail head-core" style="width: 100%;">
            <div>
                <h2>Core Values</h2>
                <div class="separator"></div>
            </div>
        </div>
        <div class="blockDetail" style="width: 100%;">
            <div style="margin-top: 15px !important;" class="core-head  col-md-5">
                <div class="low-core">
                    <div class="title">Innovation</div>
                    <div class="descrp">We are dedicated to consistently developing new ideas, products, and processes that drive growth and increase competitiveness for our clients. </div>
                </div>
                <div class="core-separator"></div>
            </div>

            <div class="core-head  col-md-5">
                <div class="low-core">
                    <div class="title">Excellence</div>
                    <div class="descrp">We are dedicated to continuous improvement of our services & we strive for the highest level of quality and performance. </div>
                </div>
                <div class="core-separator"></div>
            </div>

            <div class="core-head  col-md-5">
                <div class="low-core">
                    <div class="title">Ambition</div>
                    <div class="descrp">We are determined to achieve success and facing challenges is a part of who we are.</div>
                </div>
                <div class="core-separator"></div>
            </div>

            <div class="core-head  col-md-5">
                <div class="low-core">
                    <div class="title">Ethics</div>
                    <div class="descrp">We operate with transparency, honesty, and integrity and take pride in the work we do.
                    </div>
                </div>
                <div class="core-separator"></div>
            </div>

        </div>
        <!-- </div> -->
    </div>
    <!-- <div class="block12"></div> -->
    <!--  <div class="block13"></div> -->

</div>

<div class="forbes-block">

    <div style="width: auto;" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right" class="row head-core">

      <div ></div>

        <div id="partners" class="blockDetail" style="width: 100%;">
            <div>
                <h2>Our Partner</h2>
                <div class="separator"></div>
            </div>
        </div>

        <div class="bloc22">
            <div class="row">
                <div class="col-md-6">
                    <img loading="lazy"  src="/assets/images/fis.png" alt="Our Partner">
                </div>
                <div class="col-md-6">
                    <div class="centerElemnt">
                        <div class="">
                             <h2>Partnership </h2>
                        <div class="desc">
                            We are pleased to work with <span>FIS</span> to provide
                            financial institutions  with innovative and powerful solutions to face their challenges and
                            become
                            more customer-centric.
                            <br>
                            <span>FIS</span> is a leader in technology and services that help businesses and
                            communities thrive by
                            advancing commerce and the
                            financial world.
                        </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>


    <div style="width: 100%;" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right" class="row head-core">
        <div class="blockDetail" style="width: 100%;">
            <div>
                <h2>International Prizes</h2>
                <div class="separator"></div>
            </div>
        </div>
    </div>
    <div style="width: 100%; padding: 0 15px;" class="row inter-images">
        <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" *ngIf="forbes"
            src="/assets/aboutus/Forbes.png">
        <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" *ngIf="labelfinanceinnovation"
            src="/assets/aboutus/Labelfinanceinnovation.png">
        <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" *ngIf="financialSector"
            src="/assets/aboutus/Financial-Sector.png">
        <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" *ngIf="rd" src="/assets/aboutus/R&D.png">

    </div>
    <div style="width: 100%; padding: 0 15px;" class="row inter-images innovation">
        <div class="col-md-3 ino-head" [ngClass]="forbes ?'ino-head-checked':''" (click)="showForbes()">
            <div class="arrow-up" *ngIf="forbes"></div>
            <div class="ino-text">
                Recognized by one of the most prestigious magazines in the world 2 years in a row
            </div>
            <div class="ino-title">
                Forbes
            </div>
        </div>
        <div class="col-md-3 ino-head" [ngClass]="labelfinanceinnovation ?'ino-head-checked':''"
            (click)="showLabelfinanceinnovation()">
            <div class="arrow-up" *ngIf="labelfinanceinnovation"></div>
            <div class="ino-text">Certified innovative by Paris Europlace</div>
            <div class="ino-title">Label finance innovation</div>
        </div>
        <div class="col-md-3 ino-head" [ngClass]="financialSector ?'ino-head-checked':''"
            (click)="showFinancialSector()">
            <div class="arrow-up" *ngIf="financialSector"></div>
            <div class="ino-text">Total command of the financial sector (banks, insurance, asset management) with solid
                references</div>
            <div class="ino-title">Financial Sector</div>
        </div>
        <div class="col-md-3 ino-head" [ngClass]="rd ?'ino-head-checked':''" (click)="showrd()">
            <div class="arrow-up" *ngIf="rd"></div>
            <div class="ino-text">R&D divisions in Morocco and France in the following fields: imaging, AI, and
                blockchain</div>
            <div class="ino-title">R&D</div>
        </div>
    </div>
</div>
<div  ></div>
<div id="trust" class="block2">
    <h2  data-aos-once="true" data-aos-duration="1000" data-aos="fade-up">They trust us</h2>
    <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="separator"></div>
    <div class="row spon-images">
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-left">
            <img loading="lazy"  src="/assets/aboutus/1.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-left">
            <img loading="lazy"  src="/assets/aboutus/2.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-left">
            <img loading="lazy"  src="/assets/aboutus/3.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-left">
            <img loading="lazy"  src="/assets/aboutus/3a.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-left">
            <img loading="lazy"  src="/assets/aboutus/4.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-right">
            <img loading="lazy"  src="/assets/aboutus/5.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-right">
            <img loading="lazy"  src="/assets/aboutus/6.png">
        </div>
        <div class=" sponsors">
            <img loading="lazy"  src="/assets/aboutus/7.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-right">
            <img loading="lazy"  src="/assets/aboutus/8.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-right">
            <img loading="lazy"  src="/assets/aboutus/9.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-right">
            <img loading="lazy"  src="/assets/aboutus/10.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-right">
            <img loading="lazy"  src="/assets/aboutus/11.png">
        </div>

        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-left">
            <img loading="lazy"  src="/assets/aboutus/12.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-left">
            <img loading="lazy"  src="/assets/aboutus/13.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-left">
            <img loading="lazy"  src="/assets/aboutus/14.png">
        </div>

        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-left">
            <img loading="lazy"  src="/assets/aboutus/15.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-left">
            <img loading="lazy"  src="/assets/aboutus/16.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-left">
            <img loading="lazy"  src="/assets/aboutus/17.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-right">
            <img loading="lazy"  src="/assets/aboutus/18.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-right">
            <img loading="lazy"  src="/assets/aboutus/19.jpg">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-right">
            <img loading="lazy"  src="/assets/aboutus/20.png">
        </div>
        <div class=" sponsors" data-aos-once="true" data-aos-duration="1500" data-aos="fade-right">
            <img loading="lazy"  src="/assets/aboutus/21.png">
        </div>
    </div>
</div>
