<div class="row block1">
    <div class="col-md-5">
        <h2> P-Advisor </h2>
        <div class="desc">
                Provides automated investment services, with little or no human supervision. The P-Advisor automates and optimizes different investment strategies based on client preferences and artificial intelligence algorithms.
        </div>
    </div>
    <div class="col-md-7">
        <div class="blocImgsq">
            <img loading="lazy"  src="assets/innovation/interfaces.png" alt=""/>
        </div>
    </div>
</div>

<div class="bloc2">
    <div class="blockFeatures">
        <div class="tile">Features</div>
        <div class="sep"></div>

        <div class="row">
            <div class="col-md-6">
                <div class="blockDetail">
                    <div class="blocImg"><img loading="lazy"  src="assets/consulting/10.png" alt=""/></div>
                    <div class="blocTxt">
                        <div class="title">Predictive Analytics</div>
                        <div class="descrp">The P-Advisor automates and optimizes different investment strategies based on client preferences and artificial intelligence algorithms.</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="blockDetail">
                    <div class="blocImg"><img loading="lazy"  src="assets/consulting/13.png" alt=""/></div>
                    <div class="blocTxt">
                        <div class="title">Behavior Analysis</div>
                        <div class="descrp">The P-Advisor automates and optimizes different investment strategies based on client preferences and artificial intelligence algorithms.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>



<div class="bloc3 mt-3">
    <div class="tile">Process</div>
    <div class="sep"></div>
    <div data-aos-once="true" data-aos-duration="1000" data-aos="fade-up" class="blocImgs">
        <img loading="lazy"  src="assets/innovation/14.png" alt=""/>
    </div>
</div>
</div>
