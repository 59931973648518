import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoadingService } from 'src/app/commons/services/loading.service';
import { filter } from 'rxjs/operators';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('300ms', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('300ms', style({ opacity: 0 }))
  ])
]);

@Component({
  selector: 'app-pq-products',
  templateUrl: './pq-products.component.html',
  styleUrls: ['./pq-products.component.css'],
  animations: [fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PqProductsComponent implements OnInit {
  @ViewChild(NzCarouselComponent, { static: false }) myCarousel: NzCarouselComponent;
  effect = 'fade';
  dotPosition = 'right';
  items = [
    "assets/images/interfaces.png",
    "assets/images/img1.png",
    "assets/images/img2.png"
  ];

  activeComponent: string = 'dynamicDashboard'; // Default active component
  private scrollPosition = 0;

  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private router: Router
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.isSameRoute(event.urlAfterRedirects)) {
          window.scrollTo(0, this.scrollPosition);
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  private saveScrollPosition() {
    this.scrollPosition = window.pageYOffset;
  }

  setActiveComponent(component: string) {
    this.saveScrollPosition();
    this.activeComponent = component;
    this.router.navigate([`/products/${component}`]);
  }

  private isSameRoute(url: string): boolean {
    const currentUrl = this.router.url.split('?')[0];
    const newUrl = url.split('?')[0];
    return currentUrl.startsWith('/products') && newUrl.startsWith('/products');
  }

  ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    const productIdFromRoute = routeParams.get('page');
    if (productIdFromRoute) {
      this.activeComponent = productIdFromRoute;
    }
    this.loadingService.stop();
  }

  pre() {
    this.myCarousel.pre();
  }

  next() {
    this.myCarousel.next();
  }
}
