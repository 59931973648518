<div
  *ngIf="showSplash"
  class="app-splash-screen"
  [ngStyle]="{ opacity: opacityChange, transition: splashTransition }"
>
  <div class="app-splash-content">
    <img
      loading="lazy"
      class="img"
      src="assets/loading/peaqock1.gif"
      alt="peaqock1"
    />
  </div>
</div>
