<div class="">
    <div class="headerBLock">
        <div class="headerBg">
            <div data-aos-once="true" data-aos-duration="1300" data-aos="fade-right" class="title">Security services</div>
        </div>
        <div data-aos-once="true" data-aos-duration="1300" data-aos="fade-right" class="blockInfo">
            <div class="blockinfoTxt ">
                <div class="cstmWidth">
                    <div class="desp"></div>
                    <div class="separateur"></div>
                    <div class="titleDesc">We support our client to succeed their business transformation </div>
                    <div class="desp">with digital technologies including process automation, blockchain, advanced analytics and more. Our solutions help client to find the better way to manage the enterprise, finance and risk management.</div>
                </div>
            </div>
            <div  class="infoImg">
                <img loading="lazy"  data-aos-once="true" data-aos-duration="1300" data-aos="fade-left" src="assets/industries__vector/Security services_.png"  alt=""/>
            </div>
        </div>
    </div>

    <div class="blockSLid bgBloc">
        <div class="row">
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-4">
                <div class="blocImg"><img loading="lazy"  src="assets/industries/Security services_.png" alt=""/></div>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                <div class="flexCenter">
                    <div class="blocData">
                        <div class="title">We offer a powerfull Dynamic Dashboard </div>
                        <div class="separateur"></div>
                        <div class="descp">Built to be 360° configurable, our Dynamic Dashboard is able to process all types of data coming from various sources (Databases, Excel, PDF, Image, Manual…) in order to execute real-time analytics and visualise needed metrics. </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- <nz-carousel [nzAutoPlay]="false"   [nzEffect]="effect">
            <div nz-carousel-content >
                <div class="row">
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="col-md-4">
                        <div class="blocImg"><img loading="lazy"  src="assets/industries/layer1.png" alt=""/></div>
                    </div>
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                        <div class="flexCenter">
                            <div class="blocData">
                                <div class="title">Discover our Capabilities</div>
                                <div class="separateur"></div>
                                <div class="descp"><span>Our solution</span>  helps you <span>streamline reporting</span>  between the headquarters of a group and its subsidiaries. Among the different components managed by this solution, we find: the management of administrative statements, the management of legal papers, cash management and Shareholding ...</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
               <div nz-carousel-content >
                <div class="row">
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="col-md-4">
                        <div class="blocImg"><img loading="lazy"  src="assets/industries/layer1.png" alt=""/></div>
                    </div>
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                        <div class="flexCenter">
                            <div class="blocData">
                                <div class="title">Discover our Capabilities</div>
                                <div class="separateur"></div>
                                <div class="descp"><span>Our solution</span>  helps you <span>streamline reporting</span>  between the headquarters of a group and its subsidiaries. Among the different components managed by this solution, we find: the management of administrative statements, the management of legal papers, cash management and Shareholding ...</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </nz-carousel>
        -->
    </div>

    <div class="blocInfoDetail">
        <div class="title">Solution Features</div>
        <div class="row">
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left"  class="col-md-3">
                <div class="">
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/7.png" alt="Reports Generation"/>
                        <div class="txt"> Overviex KPIs & visualise graphic repotings</div>
                    </div>
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/8.png" alt="Simple data processing  workflow for all stakeholders "/>
                        <div class="txt">Management and Monitoring </div>
                    </div>
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/6.png" alt="KPIs"/>
                        <div class="txt">Arrange several dashboards with several data areas </div>
                    </div>
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/1.png" alt="Data consistency check"/>
                        <div class="txt">Notifications</div>
                    </div>
                </div>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up"  class="col-md-6">
                <img loading="lazy"  class="centreIamge" src="assets/industries/layer2.png" alt=""/>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"  class="col-md-3">
                <div class="blocDetail1 rightTxt">
                    <img loading="lazy"  src="assets/industries/5.png" alt="Standardization of exchanged data"/>
                    <div class="txt">Download dashboards in different formats (HTML, PDF, Image)</div>
                </div>
                <div class="blocDetail1 rightTxt">
                    <img loading="lazy"  src="assets/industries/3.png" alt="Management of delivery time and frequencie"/>
                    <div class="txt">Sharing features and authorizations</div>
                </div>
                <div class="blocDetail1 rightTxt">
                    <img loading="lazy"  src="assets/industries/4.png" alt="User management & Authorizations"/>
                    <div class="txt">Creation of dynamic, personalized and nested filters</div>
                </div>

            </div>
        </div>
    </div>
</div>
