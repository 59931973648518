<div class="imgForData1" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
  <img loading="lazy"  alt="" src="assets/joinus/manager/projectmanager.png">
</div>
<div class="weare">Position Summary</div>
<div class="weare-text">
  As a Junior QA tester, you will be responsible for designing and executing test cases, identifying defects, and
  ensuring the quality of software products. You will work closely with other members of the development team to ensure
  that all software products are thoroughly tested and meet customer requirements. Experience with Jira, Trello, and
  functional tests is required.
</div>


<div class="mt-4 mb-4 row">
  <div class="col-md-4">
    <div class="imgForData respo-image"><img loading="lazy"  alt="" src="assets/joinus/3.png"></div>
  </div>
  <div class="col-md-8 ">
    <div class="titleData">Primary Responsibilities</div>
    <div class="ssseparateurProduct"></div>
    <div class="sousTitleData">Job Responsibilities</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Create and maintain test cases and test plans</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Execute functional tests, regression tests, and performance tests</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Identify, document, and track defects and issues</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Work with developers and other team members to ensure issues are resolved</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Collaborate with stakeholders to ensure product requirements are met</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Maintain and update test documentation</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Work with the team to continuously improve the testing process</span>
    </div>
    <div class="sousTitleData sousTitleData2">Soft Skills</div>

    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <!--      <span> More than 7 years of overall experience and minimum of 2 years of IT experience. </span>-->
      <span> Strong attention to details and problem-solving skills </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Good communication skills and ability to work collaboratively with team members </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Ability to work in a fast-paced environment and manage multiple tasks simultaneously </span>
    </div>
    <div class="sousTitleData sousTitleData2">Desired Skills</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Knowledge of Agile development methodologies </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Familiarity with SQL and databases</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>ISTQB certification or similar </span>
    </div>
  </div>

</div>
<div class="row">
  <div class="col-md-6 mt-4 mb-4">
    <div class="titleData">Job Requirements</div>
    <div class="ssseparateurProduct"></div>
    <div class="sousTitleData sousTitleData2">Education</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Bachelor’s degree in computer science, software engineering, or related field  </span>
    </div>

    <div class="sousTitleData sousTitleData2">Experience</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>2-4 years of professional experience as a software tester or related role. </span>
    </div>


    <div class="sousTitleData sousTitleData2">Knowledge and Skills</div>

    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Experience with automated testing tools and scripting languages</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Experience with Jira and Trello</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Experience with functional testing </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Knowledge of software testing principles and methodologies</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Intermediate English</span>
    </div>
  </div>

  <div class="col-md-6 mt-4 mb-4">
    <!--    <div class="imgForData qualif-image"><img loading="lazy"  src="assets/joinus/4.png" alt=""/></div>-->

    <div class="titleData">Primary Contacts</div>
    <div class="ssseparateurProduct"></div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Reports to CEO and project managers</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Development teams </span>
    </div>
  </div>

  <!--  <div class="col-md-12 mt-4 mb-4">-->
  <!--    <div class="weare-text">-->
  <!--      We offer a fast-paced, collaborative work environment and the opportunity to work on challenging and meaningful projects. If you are a self-starter with a passion for technology and a desire to make a real impact, we would love to hear from you!-->
  <!--    </div>-->
  <!--  </div>-->
</div>

