<div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" class="imgForData1">
  <img loading="lazy"  src="assets/joinus/manager/projectmanager.png" alt=""/>
</div>
<div class="weare">Position Summary</div>
<div class="weare-text">
  We are seeking a highly skilled backend software engineer to join our dynamic team. The
  ideal candidate will have experience with Java and the Spring ecosystem, as well as familiarity with
  cloud-based solutions like AWS, GCP, and AZURE. The successful candidate will also have a strong
  understanding of microservices and at least 2 years of professional experience in software
  engineering.

</div>




<div class="mt-4 mb-4 row">
  <div class="col-md-4">
    <div class="imgForData respo-image"><img loading="lazy"  src="assets/joinus/3.png" alt=""/></div>
  </div>
  <div class="col-md-8 ">
    <div class="titleData">Primary Responsibilities</div>
    <div class="ssseparateurProduct"></div>
    <div class="sousTitleData">Job Responsibilities</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Develop and maintain microservices in Java and Spring framework.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Design and implement scalable, robust, and high-performance systems.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Collaborate with cross-functional teams to identify and solve complex software problems.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Build and deploy applications on cloud platforms like AWS, GCP, and AZURE.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Write clean, well-documented, and maintainable code.</span>
    </div>

    <div class="sousTitleData sousTitleData2">Soft Skills</div>

    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <!--      <span> More than 7 years of overall experience and minimum of 2 years of IT experience. </span>-->
      <span> Problem-solving skills. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Attention to details. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Communication and collaboration skills. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Collaboration &amp; Teamwork. </span>
    </div>
  </div>

</div>
<div class="row">
  <div class="col-md-6 mt-4 mb-4">
    <div class="titleData">Job Requirements</div>
    <div class="ssseparateurProduct"></div>
    <div class="sousTitleData sousTitleData2">Education</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Bachelor’s or master’s degree in Computer Science or a related field.  </span>
    </div>

    <div class="sousTitleData sousTitleData2">Experience</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>At least 2 years of professional experience in software engineering.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Strong experience with Java and the Spring ecosystem.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Familiarity with cloud-based solutions like AWS, GCP, and AZURE.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Understanding of microservices architecture and design patterns.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Excellent problem-solving skills and attention to detail.  </span>
    </div>


    <div class="sousTitleData sousTitleData2">Knowledge and Skills</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Strong experience with Java and the Spring ecosystem.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Familiarity with cloud-based solutions like AWS, GCP, and AZURE.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Understanding of microservices architecture and design patterns.  </span>
    </div>
  </div>

  <div class="col-md-6 mt-4 mb-4">
    <!--    <div class="imgForData qualif-image"><img loading="lazy"  src="assets/joinus/4.png" alt=""/></div>-->

    <div class="titleData">Primary Contacts</div>
    <div class="ssseparateurProduct"></div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Reports to Tech Lead. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> IT team and staff. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>CEO. </span>
    </div>
  </div>

  <div class="col-md-12 mt-4 mb-4">
    <div class="weare-text">
      We offer a fast-paced, collaborative work environment and the opportunity to work on challenging
      and meaningful projects. If you are a self-starter with a passion for technology and a desire to make a
      real impact, we would love to hear from you!

    </div>
  </div>
</div>

