import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';

@Component({
  selector: 'app-loyalty-solutions',
  templateUrl: './loyalty-solutions.component.html',
  styleUrls: ['./loyalty-solutions.component.css']
})
export class LoyaltySolutionsComponent implements OnInit {

  constructor(private loadingService:LoadingService) { }

  ngOnInit() {
    setTimeout(() => {
      this.loadingService.stop();
   }, 5000);
  }

}
