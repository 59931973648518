<div class="">
  <div class="headerService">
    <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" class="titleService">
      Join Us
    </div>
    <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="separator"></div>
    <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"
         class="descriptionService">
      We are recruiting profiles at all seniority levels, to support our clients in building
      the future of their businesses and their organizations
    </div>
  </div>
  <div class="mainConetnt">
    <div class="row offerTitle">
      <div class="col-md-3 blue">Job offers</div>
      <div class="col-md-9 text">Develop your skills and grow with us!</div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="blockNavigation">
          <div class="itemNavigation" [ngClass]="dataToShow==='sm' ?'itemNavigationActive':''">
            <div (click)="dataToShow='sm'"> Sales Manager </div>
          </div>
          <div class="itemNavigation" [ngClass]="dataToShow==='pm' ?'itemNavigationActive':''">
            <div (click)="dataToShow='pm'"> Project Manager </div>
          </div>
          <div class="itemNavigation" [ngClass]="dataToShow==='qa' ?'itemNavigationActive':''">
            <div (click)="dataToShow='qa'"> QA Tester </div>
          </div>
          <div class="itemNavigation" [ngClass]="dataToShow==='de' ?'itemNavigationActive':''">
            <div (click)="dataToShow='de'">Data Engineer</div>
          </div>
          <div class="itemNavigation" [ngClass]="dataToShow==='fre' ?'itemNavigationActive':''">
            <div (click)="dataToShow='fre'">  Frontend Engineer </div>
          </div>

          <!-- <div class="itemNavigation" [ngClass]="dataToShow==='fei' ?'itemNavigationActive':''">
            <div (click)="dataToShow='fei'"> Frontend Engineer Intern  </div>
          </div> -->
<!--          <div class="itemNavigation" [ngClass]="dataToShow==='fet' ?'itemNavigationActive':''">-->
<!--            <div (click)="dataToShow='fet'">  Frontend Trainee </div>-->
<!--          </div>-->

          <div class="itemNavigation" [ngClass]="dataToShow==='backend' ?'itemNavigationActive':''">
            <div (click)="dataToShow='backend'"> Backend Engineer </div>
          </div>

          <div class="itemNavigation" [ngClass]="dataToShow==='net' ?'itemNavigationActive':''">
            <div (click)="dataToShow='net'"> .NET Engineer </div>
          </div>
          <div class="itemNavigation" [ngClass]="dataToShow==='devops' ?'itemNavigationActive':''">
            <div (click)="dataToShow='devops'"> DevOps Engineer Intern </div>
          </div>

        </div>
      </div>
      <div class="col-md-9">
        <div *ngIf="dataToShow==='sm'">
          <app-sales-manager></app-sales-manager>
        </div>
        <div *ngIf="dataToShow==='de'">
          <app-front-end></app-front-end>
        </div>
        <div *ngIf="dataToShow==='backend'">
          <app-back-trainee></app-back-trainee>
        </div>
        <div *ngIf="dataToShow==='pm'">
          <app-project-manager></app-project-manager>
        </div>
        <div *ngIf="dataToShow==='qa'">
          <app-qa-tester></app-qa-tester>
        </div>
        <div *ngIf="dataToShow==='fet'">
          <app-front-trainee></app-front-trainee>
        </div>
        <div *ngIf="dataToShow==='net'">
          <app-dotnet></app-dotnet>
        </div>
        <div *ngIf="dataToShow==='devops'">
          <app-devops></app-devops>
        </div>
        <div *ngIf="dataToShow==='fei'">
          <app-frontend-interne></app-frontend-interne>
        </div>

        <div *ngIf="dataToShow==='fre'">
          <app-frontend-engineer></app-frontend-engineer>
        </div>

        <!--        <div class="">-->
<!--          <div *ngIf="frontEnd || frontTrainee || backTrainee || dataScienceTrainee" data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" class="imgForData">-->
<!--            <img loading="lazy"  src="assets/joinus/1.png" alt=""/>-->
<!--          </div>-->
<!--          <div *ngIf="manager" data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" class="imgForData">-->
<!--            <img loading="lazy"  src="assets/joinus/manager/projectmanager.png" alt=""/>-->
<!--          </div>-->
<!--          <div *ngIf="designer" data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" class="imgForData">-->
<!--            <img loading="lazy"  src="assets/joinus/Designer/Designer.png" alt=""/>-->
<!--          </div>-->
<!--          &lt;!&ndash; <div class="weare">We are</div>-->
<!--          <div class="weare-text">-->
<!--          Peaqock Financials was launched as part of a large vision of bringing intelligent and user-friendly solutions for-->
<!--          executives, by automating repetitive tasks like producing reports, by facilitating complex data management,-->
<!--          securing data ﬂows with blockchain technology, and increasing the reliability of data analytics and visualization-->
<!--          based on AI and machine learning.-->
<!--          We provide a vast range of data management solutions and secured-->
<!--          platforms. To list a few:-->
<!--          </div> &ndash;&gt;-->

<!--          &lt;!&ndash; <div class="strategics">-->
<!--              <div class="strategicTitle">-->
<!--                  <img loading="lazy"  src="/assets/joinus/right.png">-->
<!--                  <span>-->
<!--                      Strategic Advice-->
<!--                  </span>-->
<!--              </div>-->
<!--              <div class="strategicText">-->
<!--                  during each exploration meetings with our prospects, we try to understand deeply-->
<!--                  the cultural background and the great goals that drive-->
<!--                  their daily process.-->
<!--                  We offer the client the best of our expertise to understand-->
<!--                  the advantages and limits of their business models-->
<!--                  and how they can innovate their processes-->
<!--              </div>-->
<!--              <div class="strategicTitle">-->
<!--                  <img loading="lazy"  src="/assets/joinus/right.png">-->
<!--                  <span>-->
<!--                      Data Management-->
<!--                  </span>-->
<!--              </div>-->
<!--              <div class="strategicText">-->
<!--                  helping customers decide on their data architecture (big data or traditional data management);-->
<!--                  study different sources of data (ofﬁce tools, IoT, social networks, etc ...)-->
<!--              </div>-->
<!--              <div class="strategicTitle">-->
<!--                  <img loading="lazy"  src="/assets/joinus/right.png">-->
<!--                  <span>-->
<!--                      Data Science-->
<!--                  </span>-->
<!--              </div>-->
<!--              <div class="strategicText">-->
<!--                  Beyond the aspect of Data cleaning and structuring, we assist our clients to build the best models-->
<!--                  based on AI and/or machine learning to improve proﬁtability, make a study of proﬁling to better target customers,-->
<!--                  increase operational efﬁciency, predict future sales, etc...-->
<!--              </div>-->
<!--              <div class="strategicTitle">-->
<!--                  <img loading="lazy"  src="/assets/joinus/right.png">-->
<!--                  <span>-->
<!--                      Data Visualization-->
<!--                  </span>-->
<!--              </div>-->
<!--              <div class="strategicText">-->
<!--                  Provide the best tools to visualize KPIs so that decision-makers have access to data in an optimized-->
<!--                   way with all possible scenarios...-->
<!--              </div>-->
<!--          </div> &ndash;&gt;-->




<!--        </div>-->
<!--        -->

        <!-- <div class="weare">You are</div>
        <div class="weare-text">
             Front-End Web Developer who is motivated to combine the art of design with the art of
            programming. Responsibilities will include translation of the UI/UX design wireframes to actual code
            that will produce visual elements of the application. You will work with the UI/UX designer and bridge
            the gap between graphical design and technical implementation,
            taking an active role on both sides and  defining how the application looks as well as how it works.
        </div>
        <div class="mt-4 mb-4 row">
            <div class="col-md-4">
                <div class="imgForData respo-image"><img loading="lazy"  src="assets/joinus/3.png" alt=""/></div>
            </div>
            <div class="col-md-8">
                <div class="titleData">Responsibilities</div>
                <div class="ssseparateurProduct"></div>

                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Develop new user-facing features</span>
                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Build reusable code and libraries for future use</span>
                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Ensure the technical feasibility of UI/UX designs</span>
                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Optimize application for maximum speed and scalability</span>
                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Assure that all user input is validated before submitting to back-end</span>

                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Collaborate with other team members and stakeholders</span>
                </div>
            </div>

        </div>
        <div class="mt-4 mb-4 row">
            <div class="col-md-8">
                <div class="titleData">Qualifications</div>
                <div class="ssseparateurProduct"></div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>1-2 years’ experience</span>
                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Associate or Bachelor’s Degree</span>
                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Familiar with ANGULAR / REACT</span>
                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Strong desire to learn and embrace new technologies</span>
                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Ability to prioritize, multitask and handle pressure and deadlines</span>
                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Strong analytical and organizational skills</span>
                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Hard-working and goal-oriented</span>
                </div>
                <div class="respon-text">
                    <img loading="lazy"  src="/assets/joinus/right.png">
                    <span>Self-starter and proactive</span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="imgForData qualif-image"><img loading="lazy"  src="assets/joinus/4.png" alt=""/></div>
            </div>

        </div> -->
      </div>
    </div>
  </div>

  <div class="row cv-image-parent">
    <div class="cv-image">
      <div> Candidates should send their CV to
        <a href = "mailto:jobs@peaqock.com">jobs@peaqock.com</a>
      </div>
    </div>

  </div>

  <div class="row life-at">
    <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" class="titleService">
      Life at Peaqock
    </div>
    <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="separator"></div>
    <div class="be-part">
      Be part of a team that is continuously trying to bring new & innovative products to market
      that meet the needs of clients.
    </div>

    <div class="row life-images">
      <!-- <div class="cols-md-4 blueviolet">
          <div class="flip-card-inner">
              <div class="flip-card-front">
                  <img loading="lazy"  src="/assets/joinus/6.png">
              </div>
              <div class="flip-card-back">
                  <h1>John Doe</h1>
                  <p>Architect & Engineer</p>
                  <p>We love that guy</p>
              </div>
          </div>
      </div> -->
      <!-- <div class="cols-md-4 mediumspringgreen">
          <img loading="lazy"   src="/assets/joinus/8.png">
      </div> -->
      <!-- <div class="cols-md-4">
          <img loading="lazy"   src="/assets/joinus/9.png">
      </div> -->
      <div class="cols-md-4">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front flp1">
              <div class="desc">Training & development</div>
            </div>
            <div class="flip-card-back bg1">
              <div>we offer educational activities to enhance the knowledge and skills of our employees.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="cols-md-4">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front flp2">
              <!-- <img loading="lazy"  src="/assets/joinus/8.png" alt="Avatar"  > -->
              <div class="desc">Work environment</div>
            </div>
            <div class="flip-card-back bg2">
              <div> Develop your skills and grow within a company that helps drive innovation and creativity.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="cols-md-4">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front flp3">
              <!-- <img loading="lazy"  src="/assets/joinus/9.png" alt="Avatar"  > -->
              <div class="desc"> Rewards & benefits</div>
            </div>
            <div class="flip-card-back bg3">
              <div> employee recognition is our priority</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
