/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 35, "footer", [["class", "pq_footerBlock"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-md-3 col-sm-3 col-xs-3 logoFooter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "logo"], ["loading", "lazy"], ["src", "/assets/headerfooter/logo.webp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unlocking Business Value"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "col-md-3 col-sm-3 col-xs-3 ContactInfo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "h2", [["class", "titleItemFooter"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵted(-1, null, ["About Us"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "col-md-3 col-sm-3 col-xs-3 ContactInfo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "h2", [["class", "titleItemFooter"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵted(-1, null, ["Careers"])), (_l()(), i1.ɵeld(16, 0, null, null, 6, "div", [["class", "col-md-3 col-sm-3 col-xs-3 socials"], ["style", "padding-right: 0%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [["accesskey", "l"], ["aria-label", "linkedin"], ["href", "https://www.linkedin.com/company/peaqock-financials/mycompany/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "image-back linked-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "a", [["accesskey", "f"], ["aria-label", "facebook"], ["href", "https://www.facebook.com/peaqock"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "div", [["class", "image-back fb-img"], ["style", "margin: 0 8px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "a", [["accesskey", "t"], ["aria-label", "Twitter"], ["href", "https://twitter.com/peaqockf"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "div", [["class", "image-back twitter-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "div", [["class", "footer-separator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 3, "div", [["class", "privacy-state col-md-2 col-sm-12 col-xs-12"], ["style", "padding-right:0% ;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 26).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(27, 1), (_l()(), i1.ɵted(-1, null, [" Privacy Statement "])), (_l()(), i1.ɵeld(29, 0, null, null, 3, "div", [["class", "privacy-state privacy-policy col-md-2 col-sm-12 col-xs-12"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 30).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(30, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(31, 1), (_l()(), i1.ɵted(-1, null, [" Cookie policy "])), (_l()(), i1.ɵeld(33, 0, null, null, 2, "div", [["class", "rights col-md-8 col-sm-12 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A9 2020 Peaqock. All Rights Reserved."]))], function (_ck, _v) { var currVal_0 = _ck(_v, 9, 0, "about-us"); _ck(_v, 8, 0, currVal_0); var currVal_1 = _ck(_v, 14, 0, "careers"); _ck(_v, 13, 0, currVal_1); var currVal_2 = _ck(_v, 27, 0, "/privacy"); _ck(_v, 26, 0, currVal_2); var currVal_3 = _ck(_v, 31, 0, "/privacy"); _ck(_v, 30, 0, currVal_3); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i3.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i3.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
