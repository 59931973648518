<!-- <div class="container" >
    <div class="title">
        <span>Latest News</span>
    </div>
    <br><br>
    <div class="parent">
        <div class="card"  *ngFor="let news of listNews">
            <img class="card-img-top" src="{{news.image}}" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title">{{news.title}}</h5>
              <p class="card-text">{{news.description}}</p>
              <div class="button">
                <button routerLink="newsletter/{{news.id}}" class="btn-read">Read More <img src="assets\images\send.svg" alt="send"></button>
              </div>
            </div>
        </div>
    </div>
    
</div> -->

<!-- <div class="container">
    <div class="title">
        <span>Latest News</span>
    </div>
    <br><br>
    <div class="parent">
        <div class="card" *ngFor="let news of listNews">
            <img class="card-img-top" src="{{news.image}}" alt="Card image cap">
            <div class="card-body">
                <h5 class="card-title">{{news.title}}</h5>
                <p class="card-text">{{news.description.slice(0, 145)}}{{!news.showFullDesc ? '...' : news.description.slice(145)}}</p>
                <div class="button">
                    <button routerLink="newsletter/{{news.id}}"  class="btn-read">Read More <img src="assets\images\send.svg" alt="send"></button>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="container">
    <div class="title">
        <span>Latest News</span>
    </div>
    <br><br>
    
        <app-card [listNews]="listNews" (navigateTo)="onNavigation($event)"></app-card>
    
   
</div>



