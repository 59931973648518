import * as i0 from "@angular/core";
export class BlogsService {
    constructor() {
        this.blogs = [
            {
                id: '1',
                title: "Harnessing AI in Back Office Automation for Financial Institutions",
                date: '20/02/2024',
                image: '/assets/images/ArticleAi.png',
                description: "Hey there, fellow finance aficionados! Today, we're diving deep into the heart of back office operations and exploring the transformative power of AI."
            },
            {
                id: '2',
                title: "Navigating the Evolution: How Web Technologies Revolutionized Finance",
                date: '27/02/2024',
                image: '/assets/images/Yasser Blog.png',
                description: "In the world of finance, the big changes caused by technology have been truly game-changing. Remember the days when dealing with money meant lots of paper, long lines, and waiting forever? Then the internet came along, with all its new tech, and completely changed the game for finance. Now, things are way easier to access, more secure, and built to really help the user."
            },
            {
                id: '3',
                title: "Empowering Innovation: The Role of Leadership in Fintech's Evolution.",
                date: '05/03/2024',
                image: '/assets/images/Art_toubali.png',
                description: "Navigating the Fintech world feels a lot like exploring uncharted territory. It's thrilling, packed with discoveries, and yes, it comes with its share of unknowns. What makes the journey not just successful but truly remarkable isn't solely the tech we create; it's about the people at the helm. Today, let's shine a spotlight on our leaders."
            },
            {
                id: '4',
                title: "Smart Finance, Smart Decisions Exploring Generative AI.",
                date: '19/03/2024',
                image: 'assets/images/Amlo.png',
                description: "Generative AI isn't just another buzzword, it's a creative powerhouse revolutionizing the way businesses think and operate. Imagine having an intelligent assistant at your disposal, capable of analyzing vast amounts of data and generating tailor-made solutions and ideas unique to your company. This capability is not just about finding answers, it's about unlocking new opportunities and staying ahead of the curve in a dynamic marketplace."
            },
        ];
    }
    getAllBlogs() {
        console.log(this.blogs, 'BLOGGGS SERVICEEE');
        return this.blogs;
    }
    getBlogById(id) {
        return this.blogs.find(blog => blog.id === id);
    }
}
BlogsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlogsService_Factory() { return new BlogsService(); }, token: BlogsService, providedIn: "root" });
