 
<div class="cookie" *ngIf="showItem" >
    <div style="width: 100%;" class="row">
        <div class="col-sm-9">
            <p >
                Peaqock uses cookies to improve functionality and performance of this site. More information can be found in our 
               <a [routerLink]="['/privacy']">Privacy Policy</a> .
                By continuing to browse this site, you consent to the use of cookies.
            </p>
        </div>
        <div class="col-sm-3">
          <div class="flexItem">
            <button  class="btnFrst"  (click)="acceptCookie()">ACCEPT</button>
            <button   class="btnlst" (click)="close()">CLOSE</button>
          </div>
        </div>
    </div>

<!--
 <button class="btn">
    ACCEPT
</button>
 -->

</div>