import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class LoadingService {
    constructor() {
        this.subject = new Subject();
    }
    subscribe(onNext) {
        return this.subject.subscribe(onNext);
    }
    stop() {
        this.subject.next(false);
    }
}
LoadingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(); }, token: LoadingService, providedIn: "root" });
