/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./news.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/card/card.component.ngfactory";
import * as i3 from "../../../components/card/card.component";
import * as i4 from "@angular/router";
import * as i5 from "./news.component";
import * as i6 from "../../../commons/services/loading.service";
import * as i7 from "../newsletter/blogs.service";
var styles_NewsComponent = [i0.styles];
var RenderType_NewsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewsComponent, data: {} });
export { RenderType_NewsComponent as RenderType_NewsComponent };
export function View_NewsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Latest News"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-card", [], null, [[null, "navigateTo"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("navigateTo" === en)) {
        var pd_0 = (_co.onNavigation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CardComponent_0, i2.RenderType_CardComponent)), i1.ɵdid(7, 114688, null, 0, i3.CardComponent, [i4.Router], { listNews: [0, "listNews"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listNews; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_NewsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-news", [], null, null, null, View_NewsComponent_0, RenderType_NewsComponent)), i1.ɵdid(1, 114688, null, 0, i5.NewsComponent, [i6.LoadingService, i4.Router, i7.BlogsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsComponentNgFactory = i1.ɵccf("app-news", i5.NewsComponent, View_NewsComponent_Host_0, {}, {}, []);
export { NewsComponentNgFactory as NewsComponentNgFactory };
