import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { LoadingService } from 'src/app/commons/services/loading.service';
import { SharedService } from 'src/app/commons/services/shared.service';
import { ActivatedRoute } from '@angular/router';
export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);
@Component({
  selector: 'app-innovation',
  templateUrl: './innovation.component.html',
  styleUrls: ['./innovation.component.css'],
  animations: [fadeAnimation]
})
export class InnovationComponent implements OnInit {

  public isRegTech=false;
  public isSmartReport =false;
  public isAdvisro=true;
  constructor(private loadingService:LoadingService,private sharedService :SharedService, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.data.subscribe(data => {
   this.sharedService.nextdataRoute(data);
  }) }

  ngOnInit() {
    setTimeout(() => {
      this.loadingService.stop();
   }, 5000);
  }
  showRegTech(){
    this.isAdvisro=false;
    this.isSmartReport=false;
    this.isRegTech=true;
  }
  showSmartReport(){
    this.isAdvisro=false;
    this.isSmartReport=true;
    this.isRegTech=false;
  }
  showAdvisro(){
    this.isAdvisro=true;
    this.isSmartReport=false;
    this.isRegTech=false;
  }
}
