import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aml',
  templateUrl: './aml.component.html',
  styleUrls: ['./aml.component.css']
})
export class AmlComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
