<div class="headerBlock">
    <H1 data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"><span>Software Development</span> </H1>
    <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="separator"></div>
    <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="descr">We ensure the delivery of
        powerful software solutions to enhance businesses of our clients and to increase their revenues.</div>
    <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" src="assets/bg/1.png"
        alt="Software Development">
</div>
<div class="block1">
    <div style="width: 100%;" class="row">
        <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class="col-md-5">
            <img loading="lazy"  class="imgBlock" src="assets/consulting/2.png" alt=""/>
        </div>
        <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-right" class="col-md-6">
            <div class="blockDetail">
                <h2>We deliver world-class custom software solutions</h2>
                <div class="separator"></div>
                <div class="descrp">Our skilled and experienced software developers assist you in many different
                    areas,<span>from product development and UX/UI design to software architecture.</span> </div>
                <div class="descrp"><span> From IT strategy consulting and comprehensive technology roadmaps to the
                        end-to-end development of scalable solutions.</span> Our team define your functional and
                    technical requirements,<span>design, build, integrate, test, scale, and upgrade software
                        solutions</span> to deliver your needs based on your specific business.</div>
            </div>
        </div>
    </div>
    <div class="block12"></div>
    <!--  <div class="block13"></div> -->

</div>

<div class="block2">
    <h2 data-aos-once="true" data-aos-duration="1000" data-aos="fade-up">Discover Our Approach</h2>
    <div data-aos-once="true" data-aos-duration="1000" data-aos="fade-up" class="description">Our product software
        development approach is <span>Agile ,</span> we combine sophisticated,
        well-proven Agile practices with <span>Lean</span> and <span>Design Thinking</span> to ensure the customer
        remains the central focus during the product development lifecycle. Our Agile methodology is <span>Scrum</span>
        , which is an iterative and incremental approach to software project management.</div>
    <div data-aos-once="true" data-aos-duration="1000" data-aos="fade-up" class="separator"></div>
    <div class="mt-4 mb-4">
        <div class="flexDetail">
            <div data-aos-once="true" data-aos-duration="1000" data-aos="flip-up" class="blockImg">
                <div class="img"><img loading="lazy"  src="assets/consulting/4.png" alt="COURAGE"></div>
                <div class="title">COURAGE</div>
            </div>
            <div data-aos-once="true" data-aos-duration="1000" data-aos="flip-down" class="blockImg">
                <div class="img"><img loading="lazy"  src="assets/consulting/5.png" alt="OPENNESS"></div>
                <div class="title">OPENNESS</div>
            </div>
            <div data-aos-once="true" data-aos-duration="1000" data-aos="flip-up" class="blockImg">
                <div class="img"><img loading="lazy"  src="assets/consulting/6.png" alt="RESPECT"></div>
                <div class="title">RESPECT</div>
            </div>
            <div data-aos-once="true" data-aos-duration="1000" data-aos="flip-down" class="blockImg">
                <div class="img"><img loading="lazy"  src="assets/consulting/7.png" alt="COMMITMENT"></div>
                <div class="title">COMMITMENT</div>
            </div>
            <div data-aos-once="true" data-aos-duration="1000" data-aos="flip-up" class="blockImg">
                <div class="img"><img loading="lazy"  src="assets/consulting/8.png" alt="FOCUS"></div>
                <div class="title">FOCUS</div>
            </div>
        </div>
    </div>
</div>

<div class="bloc4">
    <h2>Capabilities</h2>
    <div class="sepr"></div>

    <div class="listProducts">

        <div (click)="showBlocChain()" class="itemProject" [ngClass]="{'activeItem': isBlocChain}">
            <i nz-icon nzType="arrow-right" nzTheme="outline"></i>  BlockChain
        </div>
        <div (click)="showMicroservices()" class="itemProject " [ngClass]="{'activeItem': isMicroservices}">
            <i nz-icon nzType="arrow-right" nzTheme="outline"></i> Microservices technology
        </div>

    </div>

    <div class="mainConetnt">
        <div @fadeAnimation *ngIf="isBlocChain" class="containerProject ">
            <app-bloc-chain></app-bloc-chain>
        </div>
        <div @fadeAnimation *ngIf="isMicroservices" class="containerProject ">
            <app-microservices></app-microservices>
        </div>

    </div>

</div>
<!-- <div class="block3">
    <h2>Discover Our Capabilities</h2>
    <div class="separator"></div>
    <div class="row">
        <div class="col-md-12">

            <div class="blockDetailTechnology">
                Our team develop solutions that support  <span> scalability</span>,
                <span>security</span>, availability,
                <span>performance</span>, quality
                    and high adaptability
                     to internal and external integrations,
                      and uses the lateast trends of
                    <span> technologies </span> </div>
        </div>

    </div>
    <div class="row">

        <div class="col-md-12">
            <div   class="row">

                <div class="col-sm-4">
                    <div class="blocFildest">
                        <div class="flexBloc">
                            <img loading="lazy"  src="assets/consulting/9.png" alt="Consulting">
                            <div class="title">Cybersecurity </div>
                        </div>

                        <div class="desc">Protecting systems, networks, and programs from digital attacks are our priorities.</div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="blocFildest">
                        <div class="flexBloc">
                            <img loading="lazy"  src="assets/consulting/10.png" alt="Consulting">
                            <div class="title"> Artificial Intelligence</div>
                        </div>

                        <div class="desc">  Implementing ia techniques to optimie both enterprise processes,
                            operations and improve its internal efficiency. </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="blocFildest">
                        <div class="flexBloc">
                            <img loading="lazy"  src="assets/consulting/11.png" alt="Consulting">
                            <div class="title"> Cloud computing </div>
                        </div>

                        <div class="desc">Cloud-based technologies increase business agility, lower costs,
                            and reduce IT complexity.</div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="blocFildest">
                        <div class="flexBloc">
                            <img loading="lazy"  src="assets/consulting/12.png" alt="Consulting">
                            <div class="title"> Blockchain</div>
                        </div>
                        <div class="desc">With its decentralized nature, Blockchain technology can lead
                            to new opportunities and benefit businesses through greater
                            transparency, enhanced security, and easier traceability.</div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="blocFildest">
                        <div class="flexBloc">
                            <img loading="lazy"  src="assets/consulting/13.png" alt="Consulting">
                            <div class="title">Cloud computing  </div>
                        </div>

                        <div class="desc"> connect devices to exchange data over the Internet. IOT enable
                            better safety, efficiency and decision making for businesses as
                            data is collected and analyzed. </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div> -->
