import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import { PqProductsComponent } from './modules/products/pq-products/pq-products.component';
import { CareersComponent } from './modules/service/data-science/data-science.component';
import { DataServiceComponent } from './modules/service/data-service/data-service.component';
import { ConsultingComponent } from './modules/service/consulting/consulting.component';
import { SoftwareDevelopmentComponent } from './modules/service/software-development/software-development.component';
import { InnovationComponent } from './modules/service/innovation/innovation.component';
import { AssetManagmentComponent } from './modules/industries/asset-managment/asset-managment.component';
import { BankingComponent } from './modules/industries/banking/banking.component';
import { CapitalMarketComponent } from './modules/industries/capital-market/capital-market.component';
import { ConsumerGoodsComponent } from './modules/industries/consumer-goods/consumer-goods.component';
import { IndustrialEquipmentComponent } from './modules/industries/industrial-equipment/industrial-equipment.component';
import { InsuranceComponent } from './modules/industries/insurance/insurance.component';
import { PaymentComponent } from './modules/industries/payment/payment.component';
import { PublicServiceComponent } from './modules/industries/public-service/public-service.component';
import { RegulatoryComponent } from './modules/industries/regulatory/regulatory.component';
import { SecurityServicesComponent } from './modules/industries/security-services/security-services.component';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { PrivacyStatementComponent } from './modules/privacy-statement/privacy-statement.component';
import { OpenBankingComponent } from './modules/payment/open-banking/open-banking.component';
import { FraudSolutionsComponent } from './modules/payment/fraud-solutions/fraud-solutions.component';
import { OnlineTestingSolutionsComponent } from './modules/payment/online-testing-solutions/online-testing-solutions.component';
import { LoyaltySolutionsComponent } from './modules/payment/loyalty-solutions/loyalty-solutions.component';
import { DataOneComponent } from './modules/products/data-one/data-one.component';
import { NewsletterComponent } from './modules/products/newsletter/newsletter.component';
import { NewsComponent } from './modules/products/news/news.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';


const routes: Routes = [
  // tslint:disable-next-line:max-line-length
  { path: '', loadChildren: () => import('./modules/peaqock-home/home-page.module').then(m => m.HomePageModule), data: {breadcrumb: 'data service', type: 'services'} },
  { path: 'services/data-service', component: DataServiceComponent  , data: {breadcrumb: 'data service', type: 'services'}},
  { path: 'careers', component: CareersComponent, data: {breadcrumb: '', type: ''}},
  // tslint:disable-next-line:max-line-length
  { path: 'services/software-development', component: SoftwareDevelopmentComponent , data: {breadcrumb: 'software development', type: 'services'}},
  { path: 'services/consulting', component: ConsultingComponent  , data: {breadcrumb: 'consulting', type: 'services'}},
  { path: 'services/Innovation', component: InnovationComponent  , data: {breadcrumb: 'Innovation', type: 'services'}},
  { path: 'industries/asset-managment', component: AssetManagmentComponent , data: {breadcrumb: 'asset-managment', type: 'industries'}},
  { path: 'products/:page', component: PqProductsComponent, data: {breadcrumb: '', type: ''}},
  { path: 'platform', component: DataOneComponent, data: {breadcrumb: '', type: ''}},
  { path: 'news/newsletter/:id', component: NewsletterComponent, data: {breadcrumb: '', type: ''}},
  { path: 'news', component: NewsComponent, data: {breadcrumb: '', type: ''}},
  { path: 'about-us', component: AboutUsComponent, data: {breadcrumb: '', type: ''}},
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: {breadcrumb: '', type: ''}},
  { path: 'industries/banking', component: BankingComponent  , data: {breadcrumb: 'banking', type: 'industries'}},
  { path: 'industries/capital-market', component: CapitalMarketComponent  , data: {breadcrumb: 'capital-market', type: 'industries'}},
  { path: 'industries/consumer-goods', component: ConsumerGoodsComponent  , data: {breadcrumb: 'consumer-goods', type: 'industries'}},
  // tslint:disable-next-line:max-line-length
  { path: 'industries/industrial-equipment', component: IndustrialEquipmentComponent  , data: {breadcrumb: 'industrial-equipment', type: 'industries'}},
  { path: 'industries/insurance', component: InsuranceComponent  , data: {breadcrumb: 'insurance', type: 'industries'}},
  { path: 'industries/payment', component: PaymentComponent  , data: {breadcrumb: 'payment', type: 'industries'}},
  { path: 'industries/public-service', component: PublicServiceComponent  , data: {breadcrumb: 'public-service', type: 'industries'}},
  { path: 'industries/regulatory', component: RegulatoryComponent  , data: {breadcrumb: 'regulatory', type: 'industries'}},
  // tslint:disable-next-line:max-line-length
  { path: 'industries/security-services', component: SecurityServicesComponent  , data: {breadcrumb: 'security services', type: 'industries'}},
  { path: 'payment/open-banking', component: OpenBankingComponent, data: {breadcrumb: '', type: ''}} ,
  { path: 'payment/fraud-solutions', component: FraudSolutionsComponent, data: {breadcrumb: '', type: ''}} ,
  { path: 'payment/testing-solutions', component: OnlineTestingSolutionsComponent, data: {breadcrumb: '', type: ''}} ,
  { path: 'payment/loyalty-solutions', component: LoyaltySolutionsComponent, data: {breadcrumb: '', type: ''}} ,
  { path: 'privacy', component: PrivacyStatementComponent},
  { path: 'get-demo', loadChildren: () => import('./modules/get-demo/get-demo.module').then(m => m.GetDemoModule) },
  { path: 'contact-us', loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: '**', redirectTo: '/', data: {breadcrumb: 'data service', type: 'services'}},


  /*   { path: 'products', component: PqProductsComponent} */
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  // onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes,  routerOptions
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
