<div class="mainBLoc">
    <div class="row">
        <div class="col-md-6">
            <div class="cenerBloc">
                <div class="blockDetail" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right">
                    <h2> Peaqock financial crime  prevention </h2>
                    <div class="separator"></div>
                    <div class="subTitle"> Our solution helps banks prevent their business being used to launder the proceeds of financial crime or associated with corrupt practices.</div>
                    <div class="descrp">    As clients are rapidly moving from traditional to digital banking, more channels are being added to keep the client experience consistent and positive. Thus, banks are facing difficulties in controlling all new access points exposing them to an increased risk of financial crime and damaged reputation. </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
                    <img loading="lazy"    src="assets/AML/1.png" alt="AML">
            </div>
        </div>
    </div>
</div>
<div class="can-be-used-background">
    <div class="row mb-4">
        <div class="col-md-12">
            <div class="blocText">
                <div class="desc">Our solution helps banks prevent their business being used to launder the proceeds of financial crime or associated with corrupt practices, through a range of intelligent and flexible modules that seamlessly embed into any core system to ensure the highest detection rates at the lowest costs</div>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>The World-Check Risk Intelligence data </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Watch-listScreening</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Due diligence & risk scoring</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Batch based Transaction and Behavioral Monitoring for suspected money laundering activities</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Suspicious Activity Prevention</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Alerts when current customer behavior differs from previous behavior or is indicative of money laundering, matching reference patterns.</div>
    </div>
</div>
