<div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" class="imgForData1">
  <img loading="lazy"  src="assets/joinus/manager/projectmanager.png" alt=""/>
</div>
<div class="weare">General Information</div>
<div class="weare-text">
     <strong>Salary grade/band:</strong> TBD
</div>
<!--<div class="weare-text">-->
<!--  <strong>Bonus:</strong> Quarterly bonus based on performance (which will be equivalent to 0 to 3 times salary per-->
<!--  annum)-->
<!--</div>-->
<!--<div class="weare-text">-->
<!--  <strong>Personal insurance:</strong> Yes (CNSS + Complementary insurance)-->
<!--</div>-->
<!--<div class="weare-text">-->
<!--  <strong>Organization/department:</strong> C-suite-->
<!--</div>-->
<div class="weare-text">
  <strong>Reports to:</strong> CEO
</div>
<div class="weare-text">
  <strong>Effective date:</strong> Immediate
</div>

<div class="weare">Position Summary</div>
<div class="weare-text">
  This position will plan and manage medium-to-large sized systems projects of moderate-to-high complexity,
  and ensure that project results contribute to positive business value and meet agreed on scope, cost,
  schedule and quality objectives.
</div>


<div class="mt-4 mb-4 row">
  <div class="col-md-4">
    <div class="imgForData respo-image"><img loading="lazy"  src="assets/joinus/3.png" alt=""/></div>
  </div>
  <div class="col-md-8 ">
    <div class="titleData">Primary Responsibilities</div>
    <div class="ssseparateurProduct"></div>
    <div class="sousTitleData">Job Responsibilities</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Lead project team through structured task estimation process.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Monitor and control projects, ensure efficient utilization of project resources.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Document meeting and coordinate status reports and approvals on deliverables.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Participate in preparing and executing the overall project plan.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Relate own tasks to others on project and to the project’s critical path.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Maintain open communications among project participants and affected parties.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Maintain strong focus on customer satisfaction by delivering predictable, repeatable project
results.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Perform analysis that reflects actual versus planned cost at the project level.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Assess impact of labor and cost variances.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Create and maintain baseline cost estimate at the project level.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Work through informal channels in order to obtain information.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Manage standards, procedures, and practices for recordKeeping of contractual agreements.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Incorporate risk mitigation strategy and contingency plan.</span>
    </div>
    <div class="sousTitleData sousTitleData2">Required Skills</div>

    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
<!--      <span> More than 7 years of overall experience and minimum of 2 years of IT experience. </span>-->
      <span> Between 2 and 4 years overall experience in IT </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Bachelor’s degree or equivalent experience. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Proven track record of successful project delivery on five to seven projects. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Experience with the project lifecycle—feasibility, initiation, planning, execution, and shutdown. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Formal training in project management methodology, project workshop facilitation, and project
estimation. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Ability to accomplish day-to-day work within these project management skills areas: project
communication management, project cost management. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Project scope management, project risk management, project time management, project quality
management, project integration management, project procurement management, and product
and vendor management. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Proficiency with project management tools—Microsoft Project, Project Server, etc </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Excellent written and verbal communications skills. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Ability to evaluate and recruit exceptional project management talent. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Experience working within both project-centric and project-matrix environments. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Ability to recognize exceptions to documented policy and procedures. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Monitor schedule deviations and assist in the development of alternative methods for corrective
action at the project level. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Working knowledge of quality assurance methods, tools, and techniques. </span>
    </div>
  </div>

</div>
<div class="row">
  <div class="col-md-6 mt-4 mb-4">
    <div class="titleData">Desired Skills</div>
    <div class="ssseparateurProduct"></div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>PMP certification.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Advanced university degree. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Experience working within an established Program Office environment. </span>
    </div>

  </div>

  <div class="col-md-6 mt-4 mb-4">
    <!--    <div class="imgForData qualif-image"><img loading="lazy"  src="assets/joinus/4.png" alt=""/></div>-->

    <div class="titleData">Primary Contacts</div>
    <div class="ssseparateurProduct"></div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Reports to CTO. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> IT team and staff. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Business unit executives. </span>
    </div>
  </div>
</div>

