<div class="mainBLoc">
    <div class="row">
        <div class="col-md-6">
            <div class="cenerBloc">
                <div class="blockDetail" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right">
                    <h2>DataOne</h2>
                    <div class="separator"></div>
                    <div class="descrp">Using a multitude of algorithms and predictive models to make better decisions and create more value for data through expertise in strategy, visualization and advanced analytics in an interactive way
                        our solution allows client to unlock the insights from the customer behavior data structured and unstructured by enriching it with third-party data; analyzing data; and visualizing results using wizard-led data integration, point-and-click analytics functions, and drag-and-drop visualizations, the client can combine, integrate and analyze all of his data at once to generate the insights needed to drive customer acquisition and loyalty.
                        </div>

                  <div class="descrp">
                    DataOne is a marketplace that helps banks safeguard against financial crime and corruption by providing a range of intelligent and adaptable modules that can be seamlessly integrated into any core system. These modules are designed to detect suspicious activity and prevent the illicit use of funds, while minimizing costs and maximizing detection rates. By using our solution, banks can have the peace of mind that their business is not being used for illegal activities or associated with corrupt practices.
                  </div>
                </div>
            </div>

        </div>
        <div class="col-md-6">
            <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
                <img loading="lazy"  class="" id="gif-2"
                onmouseover="document.getElementById('gif-2').src='assets/GIF_capabilities/DataOne.gif'"
                data-mode="video"  src="assets/GIF_capabilities/DataOne.gif" alt="Dynamic  ashboard">
            </div>
        </div>
    </div>
</div>

<div class="can-be-used-background">
    <div class="row mb-4">
        <div class="col-md-12">
            <div class="blocText">
            <h2  >Unlocking Insights and Driving Growth :  An Interactive Platform for Advanced Analytics and Data Visualization
            </h2>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-4 itemUse"><img loading="lazy"  src="assets/dataone/1.png" alt=""/> <br>Intuitive Interface</div>
        <div class="col-md-4 itemUse"><img loading="lazy"  src="assets/dataone/2.png" alt=""/> <br>Data Processing</div>
        <div class="col-md-4 itemUse"><img loading="lazy"  src="assets/dataone/3.png" alt=""/> <br>Predective models and Analytics (Risk Analytics, Decision Management, Text Analytics, Statistical Analysis, Social Media Analytics)</div>
        <div class="col-md-4 itemUse"><img loading="lazy"  src="assets/dataone/4.png" alt=""/> <br>Reporting Features (Real-Time Reporting, Dashboards, Location-Based Insights, Easy to Share)</div>
        <div class="col-md-4 itemUse"><img loading="lazy"  src="assets/dataone/5.png" alt=""/> <br>Scalable Analytics</div>
    </div>
</div>
<div class="benifits-background">
   <div class="row">
    <div class="col-md-6">
        <div class="d-flex-column " data-aos-once="true" data-aos-duration="1200" data-aos="fade-left">
            <h2 class="Benefits-title">Benefits</h2>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">Ability to focus on strategic projects</div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">Focus on projects that require 100% accuracy</div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">Ability to achieve mature modeling goals</div>
                </div>
           </div>
        </div>
    </div>
    <div class="col-md-6">
        <img loading="lazy" data-aos-once="true"  data-aos-duration="1200" data-aos="fade-up" src="assets/Interfaces_Capabilities/DataOne.png"
        alt="Dynamic ashboard" style="height: 360px;">
    </div>
   </div>
</div>
