<div class="">
    <div class="headerBlock">
        <H1 data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"><span>Loyalty solutions</span> </H1>
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="separator"></div>
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="descr"> Automation of loyalty and discounts programs management </div>
        <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" src="assets/loyalty/11.png"
            alt="Loyalty solutions">
    </div>
    <div class="bloc1">
        <div class="item1">
            <div class="">
                <div class="desc">Because it costs up to 5x as much to attract new customers, companies should do more
                    efforts to get existing customers to come back</div>
                <h3>
                    To raise this challenge, we offer a <span>powerful loyalty</span>  and <span>rewards program</span>  to enhance customer loyalty
                    and
                    engagement, provide a more
                   <span>personalized customer experience</span>  and <span>develop innovative new propositions.</span>
                </h3>
            </div>
        </div>
        <div class="item2">
            <img loading="lazy"  src="assets/loyalty/m.png" alt=""/>
        </div>
        <div class="item2Mobile">
            <img loading="lazy"  src="assets/loyalty/bg_mobile_1.png" alt=""/>
        </div>
    </div>

    <div class="bloc2">
        <div class="">
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <h2>Benefits</h2>
                    <div class="sepr"></div>

                </div>
                <div class="col-md-4"></div>
                <div class="col-md-12"><div class="desc">Our solution can be seamlessly and easily integrated with any application. </div>
            </div>
            </div>
            <div class="row">
                <div class="col-md-6 blocItem">
                    <div class="hImg mb-2"><img loading="lazy"  src="assets/loyalty/1.png" alt=""/></div>
                    <div class="title">Ability to incentivize specific purchase behaviors
                        so customers take the action youwant them to take </div>
                </div>
                <div class="col-md-6 blocItem">
                    <div class="hImg mb-2"><img loading="lazy"  src="assets/loyalty/3.png" alt=""/></div>
                    <div class="title">Encourages your customers to spend more,
                        bring their friends, and return often.

                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
