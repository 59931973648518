<div class="main">
    <div style="padding: 30PX 5%;">
         <div class="desc mb-3"> Microservices are a proven and popular way of  <span>increasing the speed and reducing the cost at which innovation is delivered to the business. </span>     </div>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-3 centerElement">
                <div class="blocItem">
                    <div class="title1">
                        <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>
                        Key Features
                    </div>
                    <div class="desc1">Decoupling <br>
                        Componentization <br>
                        Decentralized governance<br>
                        Agility <br>


                    </div>
                </div>
            </div>
            <div class="col-md-3 centerElement">
                <div class="blocItem clr">
                    <div class="title1">
                        <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>
                        Benefits
                    </div>
                    <div class="desc1">Manageability <br>
                        Shortened development and deployment cycles<br>
                        Low overhead and hyper-scalability <br>
                        Resiliency and elasticity <br>
                        High reusability
                    </div>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>


    <div class="bloc2">
        <div class="title">Microservice Process </div>

        <img loading="lazy"  src="assets/block_chain/Microservice.png" alt=""/>
    </div>
</div>
