<article class="row column">
    <div class="column">
        <section class="section overview row">
            <div class="column">
                <div class="section__midline-heading">
                    <h3 class="showDesktopTitle">Privacy Policy</h3>

                    <h3 class="showMobileTitle">Privacy Policy</h3>
                </div>
            </div>
            <div class="medium-10 large-8 column overview--centered medium-centered">

                <div class="rte__heading">
                    <p style="text-align: left">
                        This privacy policy applies to the ISLAMIC APP app (hereby referred to as "Application") for
                        mobile devices that was created by PEAQOCK (hereby referred to as "Service Provider") as a Free
                        service. This service is intended for use "AS IS".
                    </p>
                </div>
            </div>
        </section>
        <section class="accordion--list medium-10 large-8 column overview--centered medium-centered">
            <ul class="accordion">

                <li [ngClass]="clicked ? 'accordion-item is-active' : 'accordion-item'">
                    <a (click)="clicked = !clicked" class="accordion-title">Information Collection and Use</a>
                    <div class="accordion-content">
                        <div class="rte">
                            <p style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  margin: 0 0 24px;
                ">
                                <strong>The Application collects information when you download and use it. This
                                    information may include information such as:</strong>&nbsp;
                            </p>
                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left">
                                    Your device's Internet Protocol address (e.g. IP address)
                                </p>
                            </div>
                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left">
                                    The pages of the Application that you visit, the time and date of your visit, the
                                    time spent on those pages
                                </p>
                            </div>
                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left">
                                    The time spent on the Application
                                </p>
                            </div>
                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left">
                                    The operating system you use on your mobile device
                                </p>
                            </div>
                            <!-- <p style="text-align: left">
                The Application does not gather precise information about the location of your mobile device.
              </p>-->
                            <hr />

                            <p style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  margin: 0 0 24px;
                ">
                                <strong>
                                    The Application collects your device's location, which helps the Service Provider
                                    determine your approximate geographical location and make use of in below ways:

                                </strong>&nbsp;
                            </p>

                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left"> Geolocation Services: The Service Provider utilizes
                                    location data to provide features such as personalized content, relevant
                                    recommendations, and location-based services.</p>
                            </div>
                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left"> Analytics and Improvements: Aggregated and anonymized
                                    location data helps the Service Provider to analyze user behavior, identify trends,
                                    and improve the overall performance and functionality of the Application.</p>
                            </div>
                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left"> Third-Party Services: Periodically, the Service Provider
                                    may transmit anonymized location data to external services. These services assist
                                    them in enhancing the Application and optimizing their offerings.</p>
                            </div>

                            <p style="margin-top: 0in;
                margin-right: 26.05pt;
                margin-bottom: 0.0001pt;
                text-align: left;
                margin: 0 0 24px;">
                                <strong> Service Provider may use the information you provided to contact you from time
                                    to time to provide you with important information, required notices and marketing
                                    promotions.</strong>&nbsp;
                            </p>
                            <hr>
                            <p style="margin-top: 0in;
                margin-right: 26.05pt;
                margin-bottom: 0.0001pt;
                text-align: left;
                margin: 0 0 24px;">
                                <strong>For a better experience, while using the Application, the Service Provider may
                                    require you to provide us with certain personally identifiable information,
                                    including but not limited to PhoneNumber,FirstName,LastName. The information that
                                    the Service Provider request will be retained by them and used as described in this
                                    privacy policy.</strong>&nbsp;
                            </p>
                        </div>
                    </div>
                </li>

                <li [ngClass]="clicked2 ? 'accordion-item is-active' : 'accordion-item'">
                    <a (click)="clicked2 = !clicked2" class="accordion-title">Third Party Access</a>
                    <div class="accordion-content">
                        <div class="rte">
                            <p style="text-align: left">
                                <strong>Only aggregated, anonymized data is periodically transmitted to external
                                    services to aid the Service Provider in improving the Application and their service.
                                    The Service Provider may share your information with third parties in the ways that
                                    are described in this privacy statement.</strong>&nbsp;
                            </p>
                            <hr />
                            <p style="text-align: left">
                                <strong>
                                    Please note that the Application utilizes third-party services that have their own
                                    Privacy Policy about handling data. Below are the links to the Privacy Policy of the
                                    third-party service providers used by the Application:
                                </strong>&nbsp;
                            </p>
                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left">
                                    Google Play Services<b> <a href=" https://www.google.com/policies/privacy/"
                                            target="_blank"> (https://www.google.com/policies/privacy/)</a></b>
                                </p>
                            </div>
                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left">
                                    Facebook<b>
                                        <a href="https://www.facebook.com/about/privacy/update/printable"
                                            target="_blank">
                                            (https://www.facebook.com/about/privacy/update/printable)</a>
                                    </b>
                                </p>
                            </div>
                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left">
                                    Expo <b>
                                        <a href="https://expo.io/privacy" target="_blank"> (https://expo.io/privacy)</a>
                                    </b>
                                </p>
                            </div>
                            <hr>
                            <p style="text-align: left">
                                <strong>
                                    The Service Provider may disclose User Provided and Automatically Collected
                                    Information:
                                </strong>&nbsp;
                            </p>
                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left">
                                    as required by law, such as to comply with a subpoena, or similar legal process;
                                </p>
                            </div>
                            <div class="slides">
                                <span>+</span>

                                <p style="text-align: left">
                                    when they believe in good faith that disclosure is necessary to protect their
                                    rights, protect your safety or the safety of others, investigate fraud, or respond
                                    to a government request;
                                </p>
                            </div>
                            <div class="slides">
                                <span>+</span>
                                <p style="text-align: left">
                                    with their trusted services providers who work on their behalf, do not have an
                                    independent use of the information we disclose to them, and have agreed to adhere to
                                    the rules set forth in this privacy statement.
                                </p>
                            </div>
                        </div>
                    </div>
                </li>

                <li [ngClass]="clicked3 ? 'accordion-item is-active' : 'accordion-item'">
                    <a (click)="clicked3 = !clicked3" class="accordion-title">Opt-Out Rights</a>
                    <div class="accordion-content">
                        <div class="rte">
                            <p style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  margin: 0 0 24px;
                ">

                                You can stop all collection of information by the Application easily by uninstalling it.
                                You may use the standard uninstall processes as may be available as part of your mobile
                                device or via the mobile application marketplace or network.
                            </p>
                        </div>
                    </div>
                </li>

                <li [ngClass]="clicked4 ? 'accordion-item is-active' : 'accordion-item'">
                    <a (click)="clicked4 = !clicked4" class="accordion-title">Data Retention Policy</a>
                    <div class="accordion-content">
                        <div class="rte">
                            <p style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  margin: 0 0 24px;
                ">

                                The Service Provider will retain User Provided data for as long as you use the
                                Application and for a reasonable time thereafter. If you'd like them to delete User
                                Provided Data that you have provided via the Application, please contact them at
                                <a href="mailto:info@Peaqock.com">info@Peaqock.com</a> and they will respond in a
                                reasonable time.
                            </p>
                        </div>
                    </div>
                </li>
                <li [ngClass]="clicked5 ? 'accordion-item is-active' : 'accordion-item'">
                    <a (click)="clicked5 = !clicked5" class="accordion-title">Children</a>
                    <div class="accordion-content">
                        <div class="rte">
                            <p style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  margin: 0 0 24px;
                ">
                                The Service Provider does not use the Application to knowingly solicit data from or
                                market to children under the age of 13.
                            </p>
                            <p style="
              margin-top: 0in;
              margin-right: 26.05pt;
              margin-bottom: 0.0001pt;
              text-align: left;
              margin: 0 0 24px;
            ">
                                The Application does not address anyone under the age of 13\. The Service Provider does
                                not knowingly collect personally identifiable information from children under 13 years
                                of age. In the case the Service Provider discover that a child under 13 has provided
                                personal information, the Service Provider will immediately delete this from their
                                servers. If you are a parent or guardian and you are aware that your child has provided
                                us with personal information, please contact the Service Provider<a
                                    href="mailto:info@Peaqock.com">(info@peaqock.com)</a> so that they will be able to
                                take the necessary actions.
                            </p>
                        </div>
                    </div>
                </li>

                <li [ngClass]="clicked6 ? 'accordion-item is-active' : 'accordion-item'">
                    <a (click)="clicked6 = !clicked6" class="accordion-title">Security</a>
                    <div class="accordion-content">
                        <div class="rte">
                            <p style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  margin: 0 0 24px;
                ">

                                The Service Provider is concerned about safeguarding the confidentiality of your
                                information. The Service Provider provides physical, electronic, and procedural
                                safeguards to protect information the Service Provider processes and maintains.
                            </p>
                        </div>
                    </div>
                </li>

                <li [ngClass]="clicked7 ? 'accordion-item is-active' : 'accordion-item'">
                    <a (click)="clicked7 = !clicked7" class="accordion-title">Changes</a>
                    <div class="accordion-content">
                        <div class="rte">
                            <p style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  margin: 0 0 24px;
                ">

                                This Privacy Policy may be updated from time to time for any reason. The Service
                                Provider will notify you of any changes to the Privacy Policy by updating this page with
                                the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any
                                changes, as continued use is deemed approval of all changes.
                            </p>

                            <p style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  margin: 0 0 24px;
                ">
                                This privacy policy is effective as of 2024-06-24
                            </p>
                        </div>
                    </div>
                </li>

                <li [ngClass]="clicked8 ? 'accordion-item is-active' : 'accordion-item'">
                    <a (click)="clicked8 = !clicked8" class="accordion-title">Your Consent</a>
                    <div class="accordion-content">
                        <div class="rte">
                            <p style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  margin: 0 0 24px;
                ">
                                By using the Application, you are consenting to the processing of your information as
                                set forth in this Privacy Policy now and as amended by us.
                            </p>
                        </div>
                    </div>
                </li>

                <li [ngClass]="clicked9 ? 'accordion-item is-active' : 'accordion-item'">
                    <a (click)="clicked9 = !clicked9" class="accordion-title">Contact Us</a>
                    <div class="accordion-content">
                        <div class="rte">
                            <p style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  margin: 0 0 24px;
                ">
                                If you have any questions regarding privacy while using the Application, or have
                                questions about the practices, please contact the Service Provider via email at <a
                                    href="mailto:info@Peaqock.com">info@Peaqock.com</a>.
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
        </section>
    </div>
</article>