<div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" class="imgForData1">
  <img loading="lazy"  src="assets/joinus/manager/projectmanager.png" alt=""/>
</div>

<!--<div class="weare">You are</div>-->
<!--<div class="weare-text">-->
<!--  A person with goals and ambitions who is looking to grow both personally and professionally.-->
<!--  You will be responsible for developing, testing, and debugging responsive web and mobile applications.-->
<!--  The role will require you to translate user and business needs into functional backend product. You will be closely-->
<!--  involved with users and clients to gain first-hand exposure to their feedback.-->
<!--</div>-->

<div class="mt-4 mb-4 row">
  <div class="col-md-4">
    <div class="imgForData respo-image"><img loading="lazy"  src="assets/joinus/3.png" alt=""/></div>
  </div>
  <div class="col-md-8 centerElemnt">
    <div class="titleData">Responsibilities</div>
    <div class="ssseparateurProduct"></div>
    <div class="sousTitleData">Technical Skills</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Designing, implementing and maintaining CI/CD pipeline to automate, build, test and deploy processes.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Setting up and managing automated deployment pipelines.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Configuring and managing development, test and production environments.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Monitoring and troubleshooting production systems.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Managing and scaling infrastructure. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Collaborating with developers and other teams to improve software delivery processes. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Automating tasks such as software testing, deployment and monitoring. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Implementing security measures to protect systems and data. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Staying up to date with the latest technologies and industry trends related to DevOps. </span>
    </div>
    <div class="sousTitleData sousTitleData2">Soft Skills</div>

    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Teachable </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Dedication </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Diligence </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Curious about technology trends </span>
    </div>
  </div>

</div>
<div class="row">
  <div class="col-md-6 mt-4 mb-4">
    <div class="titleData">Primary Contacts</div>
    <div class="ssseparateurProduct"></div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Reports to tech lead  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> IT team and staff  </span>
    </div>


    <div class="titleData mt-4">Job Requirements</div>
    <div class="ssseparateurProduct"></div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Bac+2, bachelor’s or master’s degrees  </span>
    </div>
  </div>

  <div class="col-md-6 mt-4 mb-4">
    <div class="imgForData qualif-image"><img loading="lazy"  src="assets/joinus/4.png" alt=""/></div>
  </div>
</div>
