/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loading.component";
import * as i4 from "../../commons/services/loading.service";
var styles_LoadingComponent = [i0.styles];
var RenderType_LoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingComponent, data: {} });
export { RenderType_LoadingComponent as RenderType_LoadingComponent };
function View_LoadingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "app-splash-screen"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { opacity: 0, transition: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "app-splash-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "peaqock1"], ["class", "img"], ["loading", "lazy"], ["src", "assets/loading/peaqock1.gif"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.opacityChange, _co.splashTransition); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSplash; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading", [], null, null, null, View_LoadingComponent_0, RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingComponent, [i4.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingComponentNgFactory = i1.ɵccf("app-loading", i3.LoadingComponent, View_LoadingComponent_Host_0, {}, {}, []);
export { LoadingComponentNgFactory as LoadingComponentNgFactory };
