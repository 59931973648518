import { BlogsService } from './blogs.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';
import { News } from '../news';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {

  Articles : News[] = []
  listNews: News[] = [
    {
      id:'1',
      title :"Harnessing AI in Back Office Automation for Financial Institutions",
      date: '20/02/2024',
      image: '/assets/images/ArticleAi.png',
      description: "Hey there, fellow finance aficionados! Today, we're diving deep into the heart of back office operations and exploring the transformative power of AI."
    },
    {
      id:'2',
      title :"Navigating the Evolution: How Web Technologies Revolutionized Finance",
      date: '27/02/2024',
      image: '/assets/images/Yasser Blog.png',
      description: "In the world of finance, the big changes caused by technology have been truly game-changing. Remember the days when dealing with money meant lots of paper, long lines, and waiting forever? Then the internet came along, with all its new tech, and completely changed the game for finance. Now, things are way easier to access, more secure, and built to really help the user."
    },
    {
      id:'3',
      title :"Empowering Innovation: The Role of Leadership in Fintech's Evolution.",
      date: '05/03/2024',
      image: '/assets/images/Art_toubali.png',
      description: "Navigating the Fintech world feels a lot like exploring uncharted territory. It's thrilling, packed with discoveries, and yes, it comes with its share of unknowns. What makes the journey not just successful but truly remarkable isn't solely the tech we create; it's about the people at the helm. Today, let's shine a spotlight on our leaders."
    },
    {
      id:'4',
      title :"Smart Finance, Smart Decisions Exploring Generative AI.",
      date: '19/03/2024',
      image: 'assets/images/Amlo.png',
      description: "Generative AI isn't just another buzzword, it's a creative powerhouse revolutionizing the way businesses think and operate. Imagine having an intelligent assistant at your disposal, capable of analyzing vast amounts of data and generating tailor-made solutions and ideas unique to your company. This capability is not just about finding answers, it's about unlocking new opportunities and staying ahead of the curve in a dynamic marketplace."
    },
  ]
  selectedCard!: any
  constructor(private loadingService:LoadingService, private router: ActivatedRoute, private blogsService: BlogsService,
               private route: Router) {
   }
   blogId!: any;
  ngOnInit() {
    this.router.paramMap.subscribe(params => {
      this.blogId = +params.get('id');
      this.selectedCard = this.blogsService.getBlogById(this.blogId);
  });
    console.log('blogId: ', this.blogId);
    setTimeout(() => {
      this.loadingService.stop();
   }, 3000);
   this.Articles.push(
  {
    id:'1',
    title:"Harnessing AI in Back Office Automation for Financial Institutions",
    date: "20 February 2024",
    image: "/assets/images/ArticleAi.png",
    description: `&nbsp Hey there, fellow finance aficionados! Today, we're diving deep into the heart of back office operations and exploring the transformative power of AI.<br><br>
    &nbsp Before we kick off, let's take a moment to understand who we're writing for. You, dear reader, are the driving force behind our blog. Your thirst for knowledge, your quest for innovation!
    It's what fuels us to deliver the best content possible.<br> <br>
    &nbsp So, why AI in back office operations? It's simple. In today's fast-paced financial landscape, efficiency is king. And what better way to boost efficiency than by harnessing the untapped potential of artificial intelligence?
    <br> <br>
    &nbsp "Unveiling the Future: AI Revolutionizes Back Office Operations" – catchy, right? It's a tantalizing glimpse into the world of tomorrow, where AI isn't just a buzzword! It's a game-changer.
     Let's break it down. We'll start by exploring the current state of back office operations, diving into the challenges and pain points that financial institutions face. Then, we'll introduce our hero “AI” and showcase its incredible potential to revolutionize the status quo. Finally, we'll wrap things up with a look towards the future and the endless possibilities that await.
     <br> <br>
     &nbsp Let's face it! The traditional back office grind isn't exactly glamorous. Endless paperwork, manual data entry, and the occasional coffee spill, it's enough to make anyone's head spin. But fear not, because the era of AI is here to save the day!
     Picture this: a bustling back office, filled with the hum of activity and the click-clack of keyboards. It's a world of spreadsheets, documents, and endless paperwork! A world ripe for disruption. Enter AI, stage left. With its lightning-fast algorithms and unparalleled processing power, AI is the secret weapon that financial institutions have been waiting for.
     <br> <br>
     &nbsp But AI isn't just about making things faster – it's about making them better. By automating routine tasks, minimizing errors, and freeing up valuable human capital, AI isn't just revolutionizing back office operations! It's redefining the way we do business.
     By automating tedious tasks like data entry, reconciliation, and transaction processing, we're not just saving time! We're supercharging productivity, minimizing errors, and boosting the bottom line. Say goodbye to costly mistakes and hello to a more streamlined, future-proof operation.
     <br> <br>
     &nbsp As we gaze into the crystal ball of finance, one thing is clear: the future is bright, and AI is leading the charge. From predictive analytics to personalized customer experiences, the possibilities are endless.
     <br> <br>
     &nbsp Ready to join the AI revolution? Whether you're a seasoned industry veteran or a curious newcomer, there's never been a better time to explore the world of AI-powered back office operations. So buckle up, dear reader, and get ready for a journey into the future.
     <br> <br>
     &nbsp But our journey doesn't end here. We want to hear from you!  Yes, you! Share your thoughts, insights, and experiences in the comments below. Together, let's spark a conversation that ignites innovation and drives change.
     <br> <br>
     &nbsp And don't forget to spread the word! Share this blog with your friends, colleagues, and fellow finance enthusiasts. Together, let's shine a spotlight on the incredible potential of AI in back office operations.
     <br> <br>
     &nbsp So there you have it, folks! A glimpse into the future of finance, powered by the magic of AI. Welcome to the revolution.
     <br> <br>
     &nbsp &nbsp &nbsp  <span class= "name-span"><b> Yasmine Aouassar</b></span>
    `
  },
  {
    id:'2',
    title:"Navigating the Evolution: How Web Technologies Revolutionized Finance",
    date: "27 February 2024",
    image: "/assets/images/Yasser Blog.png",
    description: `&nbsp In the world of finance, the big changes caused by technology have been truly game-changing. Remember the days when dealing with money meant lots of paper, long lines, and waiting forever? Then the internet came along, with all its new tech, and completely changed the game for finance. Now, things are way easier to access, more secure, and built to really help the user.<br><br>
    &nbsp Before the Digital Era: Finance When Things Were More Hands-On, back before everything went digital, dealing with money was a lot more hands-on and slow. It meant a lot of paperwork, going to the bank for every little thing, and not being able to get updates quickly. Everything depended on actual physical documents, which made keeping things safe a big worry. This made financial services feel like they were only for a few people who could navigate through all that.<br> <br>
    &nbsp So, why AI in back office operations? It's simple. In today's fast-paced financial landscape, efficiency is king. And what better way to boost efficiency than by harnessing the untapped potential of artificial intelligence?
    <br> <br>
    &nbsp Digital Payments Revolution: Embracing Change The emergence of the COVID-19 pandemic catalyzed a significant pivot towards digital transactions. Mobile money, boasting over 1.2 billion registered accounts worldwide, emerged as a pivotal innovation. Secure digital wallets, including Apple Pay, Alipay, Paytm, and Mobikwik, revolutionized convenience, whereas P2P and B2B platforms such as PayPal and Venmo introduced alternatives to traditional banking methods. Cryptocurrencies like Bitcoin ventured into uncharted territories, navigating through the unpredictability of this new frontier.<br> <br>
     &nbsp Let's face it! The traditional back office grind isn't exactly glamorous. Endless paperwork, manual data entry, and the occasional coffee spill, it's enough to make anyone's head spin. But fear not, because the era of AI is here to save the day!
     Picture this: a bustling back office, filled with the hum of activity and the click-clack of keyboards. It's a world of spreadsheets, documents, and endless paperwork! A world ripe for disruption. Enter AI, stage left. With its lightning-fast algorithms and unparalleled processing power, AI is the secret weapon that financial institutions have been waiting for.
     <br> <br>
     &nbsp But Tech Wave in Payments and Transfers The pandemic heightened the global demand for digital solutions. Mobile money, digital wallets, and peer-to-peer transactions transformed everyday financial activities. The digital currency realm, highlighted by the presence of cryptocurrencies such as Bitcoin, offered an alternative to conventional fiat currencies. Yet, the inherent volatility of cryptocurrencies introduced a layer of risk.<br> <br>
     &nbsp As we gaze into the crystal ball of finance, one thing is clear: the future is bright, and AI is leading the charge. From predictive analytics to personalized customer experiences, the possibilities are endless.
     <br> <br>
     &nbsp Savings and Investments in the Digital Era The widespread adoption of smartphones facilitated the rise of mobile banking, micro-savings, and micro-investing apps. Platforms such as Acorns and Qapital introduced novel approaches, while online brokerages like Fidelity and Charles Schwab made participating in the securities market more accessible. Mobile investment applications, including Robinhood and Webull, streamlined stock trading, ushering in opportunities for high-stakes, crowd-sourced investing.
     <br> <br>
     &nbsp Digital Revolution in Financial Services Digital innovators heralded a new dawn, redefining finance with advancements in digital banking, robo-advisors, and insurtech. Cryptocurrencies and blockchain technology posed challenges to the traditional financial ecosystem, democratizing access to investment opportunities. Nonetheless, the rise of digital finance also brought forth concerns regarding data privacy and the looming threat of cybersecurity breaches.
     <br> <br>
     &nbsp Internet's Impact on Finance The internet has fundamentally transformed our financial dealings, obviating the need for physical bank branches and enabling frictionless transactions. Online banking eliminated conventional barriers, promoting financial inclusivity. Fintech startups challenged the status quo of traditional banking with cutting-edge solutions, establishing the internet as the cornerstone of contemporary finance.
     <br> <br>
     &nbsp Peaqock Financials and the Continuing Evolution Reflecting on this transformative journey, entities like Peaqock Financials are at the vanguard of ongoing technological evolution. The continuous advancement of web technologies, combined with Peaqock's innovative offerings, propels the finance sector towards a future filled with efficiency, enhanced security, and a flexible financial environment for today's world. Stay abreast as we delve into how web technologies, including solutions from Peaqock Financials, persist in sculpting the future landscape of finance, promising a horizon of efficiency, security, and adaptability for the modern era.
     <br> <br>
     &nbsp &nbsp &nbsp  <span class= "name-span"><b> Yassir Nadif </b></span>
    `
  },
  {
    id:'3',
    title:"Empowering Innovation: The Role of Leadership in Fintech's Evolution.",
    date: "05 March 2024",
    image: "/assets/images/Art_toubali.png",
    description: `&nbsp Navigating the Fintech world feels a lot like exploring uncharted territory. It's thrilling, packed with discoveries, and yes, it comes with its share of unknowns. What makes the journey not just successful but truly remarkable isn't solely the tech we create; it's about the people at the helm. Today, let's shine a spotlight on our leaders..<br><br>
    &nbsp Imagine being at the forefront of a Fintech team. It's not just about number crunching or tech expertise. It's about envisioning what lies beyond the horizon and daring to tread paths others haven't mapped out yet. Our leaders are those intrepid captains who spot potential icebergs from miles away and know precisely when to pivot.<br> <br>
    &nbsp These visionaries don't just react, they're proactive strategists, always several steps ahead. They're dedicated to lifelong learning, continuously adapting, and prepping the team for the next big thing. In the swift-paced realm of Fintech, stagnation is akin to regression.
    <br> <br>
    &nbsp Collaboration is our mantra. In the vast cosmos of Fintech, solitary journeys seldom lead to groundbreaking destinations. Our leaders' teamwork, constantly seeking to forge alliances. By pooling ideas, facing challenges head-on, and celebrating successes together, we not only fortify our own foundations but also propel the entire industry forward.
    <br> <br>
     &nbsp Furthermore, the spirit of innovation that propels us forward is deeply rooted in community. We believe that the future of Fintech is not just shaped by the technologies we develop but by the conversations we have, the partnerships we build, and the shared vision we nurture. This community-centric approach extends beyond our immediate network, reaching out to industry leaders, regulatory bodies, and, most importantly, our users. By engaging in an ongoing dialogue, we ensure that our innovations remain relevant, impactful, and aligned with the evolving needs of the society we serve.
     <br> <br>
     &nbsp But here's the clincher – it's all about enhancing your life, our esteemed customer. Our leaders listen—truly listen—to your needs, transforming insights into tangible solutions. We're not just in the business of developing efficient systems we're here to make your daily financial interactions more manageable, secure, and enjoyable.
     <br> <br>
     &nbsp At Peaqock, this ethos is what drives us. Our leadership team is a dynamic collective of individuals, all united by a passion to effect change. From safeguarding your assets with our state-of-the-art fraud detection system to simplifying your financial journey with our personal management tools, and ensuring that insurers leverage cutting-edge AI for better coverage, our innovations are designed with a singular purpose—to serve you.
     <br> <br>
     &nbsp As we look to the horizon, our excitement is palpable. With a leadership team as committed to technological excellence as they are making a positive impact, the future of Fintech shines bright. We're not just keeping pace with trends, we're at the vanguard, crafting the future.
     <br> <br>
     &nbsp Here's to the leaders who make it all possible. Their vision, determination, and unwavering commitment ensure that in the world of Fintech, we're not merely surviving—we're thriving. And guess what? This is only the beginning.
     <br> <br>
     &nbsp &nbsp &nbsp  <span class= "name-span"><b> Salah Eddine Toubali </b></span>
    `
  },
  {
    id:'4',
    title:"Smart Finance, Smart Decisions Exploring Generative AI",
    date: "19 March 2024",
    image: "/assets/images/Amlo.png",
    description: `&nbsp Generative AI isn't just another buzzword, it's a creative powerhouse revolutionizing the way businesses think and operate. Imagine having an intelligent assistant at your disposal, capable of analyzing vast amounts of data and generating tailor-made solutions and ideas unique to your company. This capability is not just about finding answers, it's about unlocking new opportunities and staying ahead of the curve in a dynamic marketplace.<br><br>
    &nbsp The potential for transformation brought by Generative AI technologies like ChatGPT (OpenAI) and generative language models extends across various industries, enabling unprecedented advances in automation and personalization. In finance, a noticeable shift is occurring as companies increasingly adopt AI models for specific financial tasks. So, with that in mind, How exactly can Generative AI reshape the finance sector?<br> <br>
    &nbsp One major benefit of Generative AI lies in its ability to efficiently process diverse data from various sources such as databases, files, documents, CRM tools, or any other systems. It can swiftly analyze and extract vital information from financial documents like reports and statements, tailoring outputs to user needs. This not only enhances accuracy but also saves valuable time and resources in navigating through extensive datasets.
    <br> <br>
    &nbsp Another advantage lies in virtual AI Assistants, which provide real-time analyses and intelligent insights, facilitating efficient decision-making. This conversational approach makes interacting with data and receiving insights as easy as having a conversation, vastly improving the user experience by automating the responses and optimizing manual processes.
    <br> <br>
     &nbsp Furthermore, Generative AI is transforming how businesses and investors make decisions, by turning complex data into clear visuals. Imagine a financial advisory firm converting complex market data into intuitive visual representations, enabling investors to make decisions with ease. – this kind of insight can significantly improve marketing strategies and boost sales.
     <br> <br>
     &nbsp Lastly, Regarding security and risk management, Generative AI can significantly enhance fraud detection by creating synthetic examples to train algorithms. This process enables the algorithms to effectively flag suspicious content, leading to more efficient prevention of fraudulent activities.
     <br> <br>
     &nbsp In conclusion, by embracing Generative AI, Financial institutions can boost efficiency, accuracy, and customer satisfaction, ultimately gaining a competitive edge in the market.
     <br> <br>
     &nbsp Contact us today to explore how our team can unlock the full potential of Generative AI for your business objectives. Let's discuss how we can help you achieve your goals through AI-powered solutions.
     <br> <br>
     &nbsp &nbsp &nbsp  <span class= "name-span"><b> Elmehdi Amlou </b></span>
    `
  },
  )
  console.log('this.Articles: ', this.Articles);

  }

  onNavigation(cardId: any){
    // this.router.navigate(['newsletter/',cardId])
  }

 navigationContact(){
  this.route.navigate(['/contact-us'])
 }

}
