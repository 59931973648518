import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';
import { SharedService } from 'src/app/commons/services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-data-service',
  templateUrl: './data-service.component.html',
  styleUrls: ['./data-service.component.css']
})
export class DataServiceComponent implements OnInit, OnDestroy {
  private routeDataSubscription: Subscription;

  constructor(
    private loadingService: LoadingService,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.loadingService.stop();
    }, 5000);

    this.routeDataSubscription = this.activatedRoute.data.subscribe(data => {
      this.sharedService.nextdataRoute(data);
    });
  }

  ngOnDestroy() {
    if (this.routeDataSubscription) {
      this.routeDataSubscription.unsubscribe();
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // console.log(scrollOffset);

    if (scrollOffset >= 1000) {
      document.querySelectorAll('.brdLeft').forEach((c) => {
        c.classList.add('brdActiveE');
      });
    } else {
      document.querySelectorAll('.brdLeft').forEach((c) => {
        c.classList.remove('brdActiveE');
      });
    }
  }
}
