import { Routes, ExtraOptions } from '@angular/router';
import { PqProductsComponent } from './modules/products/pq-products/pq-products.component';
import { CareersComponent } from './modules/service/data-science/data-science.component';
import { DataServiceComponent } from './modules/service/data-service/data-service.component';
import { ConsultingComponent } from './modules/service/consulting/consulting.component';
import { SoftwareDevelopmentComponent } from './modules/service/software-development/software-development.component';
import { InnovationComponent } from './modules/service/innovation/innovation.component';
import { AssetManagmentComponent } from './modules/industries/asset-managment/asset-managment.component';
import { BankingComponent } from './modules/industries/banking/banking.component';
import { CapitalMarketComponent } from './modules/industries/capital-market/capital-market.component';
import { ConsumerGoodsComponent } from './modules/industries/consumer-goods/consumer-goods.component';
import { IndustrialEquipmentComponent } from './modules/industries/industrial-equipment/industrial-equipment.component';
import { InsuranceComponent } from './modules/industries/insurance/insurance.component';
import { PaymentComponent } from './modules/industries/payment/payment.component';
import { PublicServiceComponent } from './modules/industries/public-service/public-service.component';
import { RegulatoryComponent } from './modules/industries/regulatory/regulatory.component';
import { SecurityServicesComponent } from './modules/industries/security-services/security-services.component';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { PrivacyStatementComponent } from './modules/privacy-statement/privacy-statement.component';
import { OpenBankingComponent } from './modules/payment/open-banking/open-banking.component';
import { FraudSolutionsComponent } from './modules/payment/fraud-solutions/fraud-solutions.component';
import { OnlineTestingSolutionsComponent } from './modules/payment/online-testing-solutions/online-testing-solutions.component';
import { LoyaltySolutionsComponent } from './modules/payment/loyalty-solutions/loyalty-solutions.component';
import { DataOneComponent } from './modules/products/data-one/data-one.component';
import { NewsletterComponent } from './modules/products/newsletter/newsletter.component';
import { NewsComponent } from './modules/products/news/news.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
const ɵ0 = () => import("./modules/peaqock-home/home-page.module.ngfactory").then(m => m.HomePageModuleNgFactory), ɵ1 = { breadcrumb: 'data service', type: 'services' }, ɵ2 = { breadcrumb: 'data service', type: 'services' }, ɵ3 = { breadcrumb: '', type: '' }, ɵ4 = { breadcrumb: 'software development', type: 'services' }, ɵ5 = { breadcrumb: 'consulting', type: 'services' }, ɵ6 = { breadcrumb: 'Innovation', type: 'services' }, ɵ7 = { breadcrumb: 'asset-managment', type: 'industries' }, ɵ8 = { breadcrumb: '', type: '' }, ɵ9 = { breadcrumb: '', type: '' }, ɵ10 = { breadcrumb: '', type: '' }, ɵ11 = { breadcrumb: '', type: '' }, ɵ12 = { breadcrumb: '', type: '' }, ɵ13 = { breadcrumb: '', type: '' }, ɵ14 = { breadcrumb: 'banking', type: 'industries' }, ɵ15 = { breadcrumb: 'capital-market', type: 'industries' }, ɵ16 = { breadcrumb: 'consumer-goods', type: 'industries' }, ɵ17 = { breadcrumb: 'industrial-equipment', type: 'industries' }, ɵ18 = { breadcrumb: 'insurance', type: 'industries' }, ɵ19 = { breadcrumb: 'payment', type: 'industries' }, ɵ20 = { breadcrumb: 'public-service', type: 'industries' }, ɵ21 = { breadcrumb: 'regulatory', type: 'industries' }, ɵ22 = { breadcrumb: 'security services', type: 'industries' }, ɵ23 = { breadcrumb: '', type: '' }, ɵ24 = { breadcrumb: '', type: '' }, ɵ25 = { breadcrumb: '', type: '' }, ɵ26 = { breadcrumb: '', type: '' }, ɵ27 = () => import("./modules/get-demo/get-demo.module.ngfactory").then(m => m.GetDemoModuleNgFactory), ɵ28 = () => import("./modules/contact-us/contact-us.module.ngfactory").then(m => m.ContactUsModuleNgFactory), ɵ29 = { breadcrumb: 'data service', type: 'services' };
const routes = [
    // tslint:disable-next-line:max-line-length
    { path: '', loadChildren: ɵ0, data: ɵ1 },
    { path: 'services/data-service', component: DataServiceComponent, data: ɵ2 },
    { path: 'careers', component: CareersComponent, data: ɵ3 },
    // tslint:disable-next-line:max-line-length
    { path: 'services/software-development', component: SoftwareDevelopmentComponent, data: ɵ4 },
    { path: 'services/consulting', component: ConsultingComponent, data: ɵ5 },
    { path: 'services/Innovation', component: InnovationComponent, data: ɵ6 },
    { path: 'industries/asset-managment', component: AssetManagmentComponent, data: ɵ7 },
    { path: 'products/:page', component: PqProductsComponent, data: ɵ8 },
    { path: 'platform', component: DataOneComponent, data: ɵ9 },
    { path: 'news/newsletter/:id', component: NewsletterComponent, data: ɵ10 },
    { path: 'news', component: NewsComponent, data: ɵ11 },
    { path: 'about-us', component: AboutUsComponent, data: ɵ12 },
    { path: 'privacy-policy', component: PrivacyPolicyComponent, data: ɵ13 },
    { path: 'industries/banking', component: BankingComponent, data: ɵ14 },
    { path: 'industries/capital-market', component: CapitalMarketComponent, data: ɵ15 },
    { path: 'industries/consumer-goods', component: ConsumerGoodsComponent, data: ɵ16 },
    // tslint:disable-next-line:max-line-length
    { path: 'industries/industrial-equipment', component: IndustrialEquipmentComponent, data: ɵ17 },
    { path: 'industries/insurance', component: InsuranceComponent, data: ɵ18 },
    { path: 'industries/payment', component: PaymentComponent, data: ɵ19 },
    { path: 'industries/public-service', component: PublicServiceComponent, data: ɵ20 },
    { path: 'industries/regulatory', component: RegulatoryComponent, data: ɵ21 },
    // tslint:disable-next-line:max-line-length
    { path: 'industries/security-services', component: SecurityServicesComponent, data: ɵ22 },
    { path: 'payment/open-banking', component: OpenBankingComponent, data: ɵ23 },
    { path: 'payment/fraud-solutions', component: FraudSolutionsComponent, data: ɵ24 },
    { path: 'payment/testing-solutions', component: OnlineTestingSolutionsComponent, data: ɵ25 },
    { path: 'payment/loyalty-solutions', component: LoyaltySolutionsComponent, data: ɵ26 },
    { path: 'privacy', component: PrivacyStatementComponent },
    { path: 'get-demo', loadChildren: ɵ27 },
    { path: 'contact-us', loadChildren: ɵ28 },
    { path: '**', redirectTo: '/', data: ɵ29 },
];
const routerOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
};
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29 };
