<div class="">
    <div class="headerBlock">
        <H1 data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"> <span>Innovation</span> </H1>
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="separator"></div>
        <!-- <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="descr">Open innovation unlocks digital opportunities.</div> -->
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="descr">Product technologies are rapidly evolving, providing opportunities for new revenue streams for organizations. When R&D focuses on the technology of yesterday, organizations miss the revenue opportunities available. Peaqock can help you prioritize and explore technical and business opportunities.</div>
        <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" src="assets/innovation/1.png"
            alt="Consulting">
    </div>

    <div class="containerData">
        <div class="row">
            <div class="col-md-6">
                <div class="centerItem">
                    <div class="blocTxt">
                        <h2>Innovation is becoming an increasingly critical capability</h2>
                        <p>Product technologies are rapidly evolving in our market, innovation is becoming an
                            increasingly critical capability, this is why we devote more resources and attention to R&D.
                        </p>
                        <p>We explore technical & business opportunities, evaluate feasibility and evaluate technologies
                            to shape future objectives and strategies.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="zoomIn">
                    <div class="blocImg bg1">
                        <span> Research & Development</span>
                     </div>
                </div>

            </div>
        </div>
        <div class="row reversMobile">
            <div class="col-md-6">
                <div class="zoomIn">
                    <div class="blocImg bg2">
                        <span>Business Strategies</span>
                     </div>
                </div>

            </div>
            <div class="col-md-6">
                <div class="centerItem ">
                    <div class="blocTxt">
                        <h2>We are exploring business opportunities ahead of schedule in order to make educated
                            decisions and to define our project backlog </h2>
                        <p>Our objective is to identify the prevailing challenges or the need of the industry, markets
                            or societies and to focus on research and sustainable development to produce the best
                            possible solution in the form of products or services to fill the gap.</p>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="centerItem">
                    <div class="blocTxt">
                        <h2>We strive to create new and improved products that will eliver the great value to users and
                            customers. </h2>
                        <p>We produce intellectual property strategies and draw on our unique global expertise to ensure
                            that the best possible strategies emerge</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="zoomIn">
                    <div class="blocImg bg3">
                        <span>Intellectual Property</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dataNavigate mt-4 mb-4">
        <div class="row">
            <div class="col-md-3">
                <div class="title">Uses Cases</div>
            </div>
            <div class="col-md-9">
                <div class="navigate">
                    <div (click)="showAdvisro()" class="itemProject " [ngClass]="{'activeItem': isAdvisro}">
                        <i nz-icon nzType="arrow-right" nzTheme="outline"></i>  P-Advisro
                    </div>
                    <div (click)="showSmartReport()" class="itemProject " [ngClass]="{'activeItem': isSmartReport}">
                        <i nz-icon nzType="arrow-right" nzTheme="outline"></i> Smart Report
                    </div>
                    <div (click)="showRegTech()" class="itemProject " [ngClass]="{'activeItem': isRegTech}">
                        <i nz-icon nzType="arrow-right" nzTheme="outline"></i> RegTech&SupTech
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mainBlock">
        <div *ngIf="isAdvisro" class="" @fadeAnimation>
            <app-padvisro></app-padvisro>
        </div>
        <div *ngIf="isSmartReport" class="" @fadeAnimation>
            <app-smart-report></app-smart-report>
        </div>
        <div *ngIf="isRegTech" class="" @fadeAnimation>
            <app-reg-tech></app-reg-tech>
        </div>
    </div>
</div>
