import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fraud',
  templateUrl: './fraud.component.html',
  styleUrls: ['./fraud.component.css']
})
export class FraudComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
