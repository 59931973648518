<nav class="navbar navbar-expand-lg fixed-top navbar-light"
[ngClass]="className" (mouseenter)="isHovered()" (mouseleave)="isNotHovered()">
  <div class="nav-logo">
    <a class="navbar-brand brand-head" [routerLink]="['/']">
      <img loading="lazy"  class="pq_logo" src="{{logo}}" alt="pq_logo"/>
      <span  [ngClass]="textColor">Unlocking Business Value</span>
    </a>
    <nz-divider nzType="vertical"></nz-divider>
  </div>
  <div *ngIf="innerWidth2<923"  class="collapse navbar-collapse the-blur"  id="navbarSupportedContent"   data-toggle="collapse"  data-target=".navbar-collapse.show" >  </div>


  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse " id="navbarSupportedContent2">
    <!-- <div class="itemHeaderPq "> -->
      <div class="navbar-nav blockHeader ml-auto">
        <!-- <div class="itemNavBar  nav-item  menu-area"  data-toggle="collapse"  data-target=".navbar-collapse.show" [routerLink]="['/']"  routerLinkActive="itemNavBarActives" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="textColor">
          <div class="subMenu"> </div>
          <span  id="mega-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Home</span>

        </div> -->

        <div *ngIf="false" class="itemNavBar2 nav-item  menu-area" [class]="textColor" [ngClass]="{'itemNavBarActive': isActive('/services')}"  >
          <div class="subMenu">{{txtService}} </div>
          <span id="mega-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >Data  Services
             <!-- <i class="go-down" nz-icon nzType="down"></i> -->
          </span>
          <ul class="megaMenu  dropdown-menu mega-area service-menu" aria-labelledby="mega-3" style="width: 30%;left: 20%;">
            <div class="row row-services">
              <!-- <div class="col-md-3 services-img">
                <div class="text-image ">
                  <div class="service">Services</div>
                  <div class="service discover">Discover Our Services</div>
               </div>
               <nz-divider [nzOrientation]="'right'" nzType="vertical"></nz-divider>
                <img loading="lazy"  src="/assets/headerfooter/bg_services_menu.png">
              </div> -->
              <div class="col-md-12">
                <div class="row row-service-container">
                  <div class="col-md-12 services-container" >
                    <div class="services-container-child"  [routerLink]="['/services/data-service']">
                      <h5 style="margin-top:24px"  data-toggle="collapse"  data-target=".navbar-collapse.show"  > Data & analytics  </h5>
                      <!-- <div class="text-fix" >Discover your data, make better decisions and work more efficiently to achieve
                           sustainable advantage in the market place.</div> -->
                      <!-- <div class="separator"></div> -->
                      <i class="right-arrow" style="margin-top:24px" nz-icon nzType="arrow-right" nzTheme="outline"></i>
                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>

                  </div>
                  <div class="col-md-12 services-container">
                    <div class="services-container-child"  [routerLink]="['/services/software-development']">
                      <h5 style="border-right: none;"  data-toggle="collapse"  data-target=".navbar-collapse.show"> Software development </h5>
                      <!-- <div style="border-right: none;" class="text-fix">We ensure the delivery of powerful software solutions to enhanc</div> -->
                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>

                  </div>
                </div>
                <div class="row row-service-container">
                  <!-- <div class="col-md-12 services-container">
                    <div class="services-container-child"  [routerLink]="['/services/consulting']">
                      <h5 data-toggle="collapse"  data-target=".navbar-collapse.show" data-target=".navbar-collapse.show"> Consulting </h5>
                       <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>

                  </div>
                   -->
                  <div class="col-md-12 services-container">
                    <div class="services-container-child"  [routerLink]="['/services/Innovation']">
                      <h5  data-toggle="collapse" data-target=".navbar-collapse.show" style="margin-bottom:24px">Innovation  </h5>
                      <!-- <div style="border-right: none;" class="text-fix">Open innovation unlocks digital opportunities.</div> -->
                      <!-- <nz-divider nzType="horizontal"></nz-divider> -->
                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
        <div *ngIf="hiddenin" class="itemNavBar2 nav-item  menu-area"  [class]="textColor" [ngClass]="{'itemNavBarActive': isActive('/industries')}">
          <div class="subMenu">{{txtIndustries}} </div>
          <span id="mega-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Industries
           </span>
          <ul class="megaMenu dropdown-menu mega-area service-menu" aria-labelledby="mega-3">
            <div class="row row-services">

              <div class="col-lg-12">
                <div class="row row-service-container">
                  <div class="col-lg-6 industries-container">
                    <div class="services-container-child"  [routerLink]="['/industries/public-service']" >
                      <h5 style="margin-top:24px"  data-toggle="collapse"  data-target=".navbar-collapse.show"> Public services </h5>

                      <i style="margin-top:24px" class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>
                  </div>
                  <div class="col-lg-6 industries-container">
                    <div class="services-container-child"  [routerLink]="['/industries/regulatory']" >
                      <h5 style="margin-top:24px"  data-toggle="collapse"  data-target=".navbar-collapse.show"> Regulatory</h5>

                      <i style="margin-top:24px" class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>
                  </div>
                  <div class="col-lg-6 industries-container ">
                    <div class="services-container-child"  [routerLink]="['/industries/insurance']">
                      <h5  data-toggle="collapse"  data-target=".navbar-collapse.show"   style="border-right: none;"> Insurance </h5>

                     <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>
                  </div>
                  <div class="col-lg-6  industries-container">
                    <div class="services-container-child" [routerLink]="['/industries/banking']">
                      <h5  data-toggle="collapse"  data-target=".navbar-collapse.show"  > Banking </h5>

                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>

                  </div>
                  <div class="col-lg-6  industries-container">

                    <div class="services-container-child"  [routerLink]="['/industries/asset-managment']">
                      <h5  data-toggle="collapse"  data-target=".navbar-collapse.show" > Asset management </h5>

                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>
                  </div>

                  <div class="col-lg-6 industries-container">
                    <div class="services-container-child"  [routerLink]="['/industries/capital-market']" >
                      <h5   data-toggle="collapse"  data-target=".navbar-collapse.show" style="border-right: none;" > Capital market </h5>

                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                     </div>
                     <nz-divider nzType="horizontal"></nz-divider>
                  </div>
                  <div class="col-lg-6 industries-container">
                    <div class="services-container-child"  [routerLink]="['/industries/security-services']" >
                      <h5 data-toggle="collapse"  data-target=".navbar-collapse.show" >Security services</h5>

                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>
                  </div>
                  <div class="col-lg-6 industries-container">
                    <div class="services-container-child"  [routerLink]="['/industries/payment']">
                      <h5  data-toggle="collapse"  data-target=".navbar-collapse.show" > Payment </h5>

                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>
                  </div>
                  <div class="col-lg-6 industries-container">
                    <div class="services-container-child"  [routerLink]="['/industries/consumer-goods']">
                      <h5  data-toggle="collapse"  data-target=".navbar-collapse.show" style="margin-bottom:24px" style="border-right: none;">Consumer goods</h5>

                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
                    </div>
                  </div>

                  <div class="col-lg-6  industries-container">
                    <div class="services-container-child"  [routerLink]="['/industries/industrial-equipment']">
                      <h5  data-toggle="collapse" data-target=".navbar-collapse.show"  style="margin-bottom:24px" > Industrial equipment </h5>
                       <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </ul>

        </div>

        <!-- platform (dataone) -->
        <div   [routerLink]="['/platform']" data-toggle="collapse"  data-target=".navbar-collapse.show" class="itemNavBar  nav-item  menu-area" routerLinkActive="itemNavBarActive" [ngClass]="textColor">
          <div class="subMenu"> </div>
          <span  >Platform</span>
        </div>

<!-- payment service  -->

 <!-- <div  class="itemNavBar2 nav-item  menu-area"  [class]="textColor" [ngClass]="{'itemNavBarActive': isActive('/payment')}">
   <span id="mega-6" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

    Capabilities
   </span>
  <ul class="megaMenu dropdown-menu mega-area service-menu " aria-labelledby="mega-6">
    <div class="row row-services ctmMenu">

      <div class="col-md-12">
        <div class="row row-service-container">
          <div class="col-md-12 services-container" >
            <div class="services-container-child"  [routerLink]="['/payment/open-banking']">
              <h5 style="margin-top:24px"  data-toggle="collapse"  data-target=".navbar-collapse.show"  >Open banking  </h5>

              <i class="right-arrow" style="margin-top:24px" nz-icon nzType="arrow-right" nzTheme="outline"></i>
            </div>
            <nz-divider nzType="horizontal"></nz-divider>

          </div>
          <div class="col-md-12 services-container">
            <div class="services-container-child"  [routerLink]="['/payment/fraud-solutions']">
              <h5 style="border-right: none;"  data-toggle="collapse"  data-target=".navbar-collapse.show"> Fraud solutions </h5>
               <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
            </div>
            <nz-divider nzType="horizontal"></nz-divider>

          </div>
        </div>
        <div class="row row-service-container">

          <div class="col-md-12 services-container">
            <div class="services-container-child"  [routerLink]="['/products/dynamicDashboard']">
              <h5 data-toggle="collapse"  data-target=".navbar-collapse.show" data-target=".navbar-collapse.show"  style="margin-bottom:24px"> Other capabilities </h5>
               <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
            </div>


          </div>


        </div>
      </div>
    </div>
  </ul>
</div>  -->

<!-- end payment service  -->

<!--         <div class="itemNavBar2 nav-item  menu-area"  >
          <span id="mega-8" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Industries
            <i class="go-down" nz-icon nzType="down"></i>
          </span>
          <ul class="megaMenu dropdown-menu mega-area service-menu" aria-labelledby="mega-8">
            <div class="row row-services">
              <div class="col-md-4 services-img">
                <div class="text-image">
                  <div class="industry">Industries</div>
                 <div class="discover">Discover Our Capabilities</div>
               </div>
                <img loading="lazy"  src="/assets/headerfooter/bg.png">

              </div>

              <div class="col-md-8" style="margin: auto;">
                <div class="row row-industries-container">
                  <div  class="col-md-3">
                    <h5 [routerLink]="['/industries/public-service']"> 	Public services </h5>
                    </div>
                    <div  class="col-md-3">
                      <h5 [routerLink]="['/industries/regulatory']"> Regulatory</h5>
                    </div>
                    <div  class="col-md-3">
                      <h5 [routerLink]="['/industries/insurance']"> Insurance</h5>
                    </div>
                    <div  class="col-md-3">
                      <h5 [routerLink]="['/industries/banking']">  Banking</h5>
                    </div>
                  </div>
                  <div class="row row-industries-container">
                    <div  class="col-md-4">
                      <h5 [routerLink]="['/industries/asset-managment']"> 	Asset management </h5>
                    </div>
                    <div  class="col-md-4">
                      <h5 [routerLink]="['/industries/capital-market']"> Capital market</h5>
                    </div>
                    <div  class="col-md-4">
                      <h5 [routerLink]="['/industries/security-services']"> Security services</h5>
                    </div>
                  </div>

                  <div class="row row-industries-container">
                    <div  class="col-md-4">
                      <h5 [routerLink]="['/industries/payment']"> 	Payment </h5>
                    </div>
                    <div  class="col-md-4">
                      <h5 [routerLink]="['/industries/consumer-goods']"> Consumer goods</h5>
                    </div>
                    <div  class="col-md-4">
                      <h5 [routerLink]="['/industries/industrial-equipment']"> Industrial equipment</h5>
                    </div>
                  </div>

                </div>

            </div>
          </ul>
        <ul class="megaMenu dropdown-menu mega-area" aria-labelledby="mega-8">
            <div class="row">
              <div class="col-md-3 mb-3">
                <h5> 	Public services </h5>
              </div>
              <div class="col-md-3 mb-3">
                <h5> Regulatory</h5>
              </div>
              <div class="col-md-3 mb-3">
                <h5> Insurance</h5>
              </div>
              <div class="col-md-3 mb-3">
                <h5>  Banking</h5>
              </div>
              <div class="col-md-3 mb-3">
                <h5 [routerLink]="['/industries/asset-managment']" > Asset management</h5>
              </div>

              <div class="col-md-3 mb-3">
                <h5 [routerLink]="['/industries/asset-managment']"> Asset management</h5>
              </div>
              <div class="col-md-3 mb-3">
                <h5> Capital market</h5>
              </div>
              <div class="col-md-3 mb-3">
                <h5> Security services</h5>
              </div>
              <div class="col-md-3 mb-3">
                <h5> Payment</h5>
              </div>
              <div class="col-md-3 mb-3">
                <h5>  Consumer goods</h5>
              </div>
              <div class="col-md-3 mb-3">
                <h5> Industrial equipment</h5>
              </div>
            </div>
          </ul>
        </div> -->


        <!-- <div class="itemNavBar nav-item  menu-area" [routerLink]="['/data-science']">
          <span id="mega-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Innovation</span>
          <ul class="megaMenu dropdown-menu mega-area" aria-labelledby="mega-4">
            <div class="row">
              <div class="col-md-6">
                <h5> Innovation </h5>
              </div>

            </div>
          </ul>
        </div> -->
        <!-- <div class="itemNavBar nav-item  menu-area">
          <span id="mega-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Abous Us</span>
          <ul class="megaMenu dropdown-menu mega-area" aria-labelledby="mega-5">
            <div class="row">
              <div class="col-md-4">
                <h5> Abous Us </h5>
              </div>
              <div class="col-md-4">
                <h5> nav.secteur </h5>
                <a>nav.chiffres_cles </a>
              </div>
              <div class="col-md-4">
                <h5>Les OPCVMs </h5>
              </div>
            </div>
          </ul>
        </div> -->

        <div  [routerLink]="['/products/dynamicDashboard']"  data-toggle="collapse"  data-target=".navbar-collapse.show" class="itemNavBar  nav-item  menu-area" routerLinkActive="itemNavBarActive" [ngClass]="textColor">
          <div class="subMenu"> </div>
          <span  >Solutions</span>
        </div>
        <!-- <div  [routerLink]="['/services/Innovation']"  data-toggle="collapse"  data-target=".navbar-collapse.show" class="itemNavBar  nav-item  menu-area" routerLinkActive="itemNavBarActive" [ngClass]="textColor">
          <div class="subMenu"> </div>
          <span  >Innovation</span>
        </div> -->
<!--        <div [routerLink]="['/careers']"  data-toggle="collapse"  data-target=".navbar-collapse.show" class="itemNavBar  nav-item  menu-area" routerLinkActive="itemNavBarActive" [ngClass]="textColor">-->
<!--          <div class="subMenu"> </div>-->
<!--          <span  >Careers</span>-->
<!--        </div>-->
<!--        <div [routerLink]="['/about-us']"  data-toggle="collapse"  data-target=".navbar-collapse.show" class="itemNavBar  nav-item  menu-area" [ngClass]="textColor"  routerLinkActive="itemNavBarActive">-->
<!--          <div class="subMenu"> </div>-->
<!--          <span>About Us</span>-->
<!--        </div>-->




        <div  class="itemNavBar2 nav-item  menu-area"  [class]="textColor" [ngClass]="{'itemNavBarActive': isActive('/about-us')}">
          <div class="subMenu">{{txtIndustries}} </div>
          <span id="mega-4" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="About Us" tabindex="0">
    About Us
</span>
          <ul class="megaMenu megaMenu2 dropdown-menu mega-area service-menu" aria-labelledby="mega-4">
            <div class="row row-services">

              <div class="col-lg-5 blocImg">
                <img loading="lazy"  src="assets/headerfooter/bg_services_menu.webp" alt="bg_services_menu"/>
              </div>

              <div class="col-lg-7">
                <div class="row row-service-container">
                  <div class="col-lg-12 industries-container">
                    <div class="services-container-child"  (click)="navigateTo('who-we-are')" >
                      <h5 style="margin-top:24px"  data-toggle="collapse"  data-target=".navbar-collapse.show"> Who we are
                      </h5>

                      <i style="margin-top:24px" class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>
                  </div>
                  <div class="col-lg-12 industries-container">
                    <div class="services-container-child" (click)="navigateTo('trust')" >
                      <h5  data-toggle="collapse"  data-target=".navbar-collapse.show"> They trust us</h5>

                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>
                  </div>
                  <div class="col-lg-12 industries-container">
                    <div class="services-container-child"  (click)="navigateTo('partners')">
                      <h5  data-toggle="collapse"  data-target=".navbar-collapse.show"   style="border-right: none;"> Our Partners</h5>

                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>
                  </div>
                  <div class="col-lg-12 industries-container">
                    <div class="services-container-child" [routerLink]="['/careers']">
                      <h5  data-toggle="collapse"  data-target=".navbar-collapse.show"  > Careers </h5>

                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>

                  </div>
                  <div class="col-lg-12 industries-container">

                    <div class="services-container-child" (click)="navigateTo()" >
                      <h5  data-toggle="collapse"  data-target=".navbar-collapse.show" > News & Media-room  </h5>

                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>
                  </div>
                  <div class="col-lg-12 industries-container">

                    <div class="services-container-child" [routerLink]="['/news']" >
                      <h5  data-toggle="collapse"  data-target=".navbar-collapse.show" > Blogs  </h5>

                      <i class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                    <nz-divider nzType="horizontal"></nz-divider>
                  </div>
                  <!-- <div   [routerLink]="['/news']" data-toggle="collapse"  data-target=".navbar-collapse.show" class="itemNavBar  nav-item  menu-area" routerLinkActive="itemNavBarActive" [ngClass]="textColor">
                    <div class="subMenu"> </div>
                    <span  >Blogs</span>
                  </div> -->
                  <div class="col-lg-12 industries-container">
                    <div class="services-container-child"  [routerLink]="['/contact-us']" >
                      <h5 style="margin-bottom: 24px"  data-toggle="collapse"  data-target=".navbar-collapse.show"  > Contact Us</h5>

                      <i style="margin-bottom: 24px" class="right-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>

                    </div>
                   </div>
                </div>
              </div>


            </div>
          </ul>

        </div>



        <div  data-toggle="collapse"  data-target=".navbar-collapse.show" class="itemNavBar  nav-item  menu-area" [ngClass]="textColor"  >
          <div class="subMenu"> </div>
          <button routerLink="/get-demo" class="btngetDemo">Get a demo</button>
        </div>
        <div class="translateButton" data-toggle="collapse"  data-target=".navbar-collapse.show">
<!--          <div id="convethisTranslate2351351"  [ngClass]="translate">-->
            <!-- <div style="position: relative; display: inline-block; height: 36px; vertical-align: middle; float: left; direction: ltr;">
              <div id="conveythis-wrapper" class="conveythis-no-translate skiptranslate notranslate" style="display: block; padding: 0px; margin: 0px; text-align: left; z-index: 2147483647; direction: ltr; position: absolute; top: 0px;">
                <div class="conveythis-widget-main" style="cursor: pointer; background-color: rgb(255, 255, 255); white-space: nowrap; border: 1px solid rgb(255, 255, 255); padding: 0px; margin: 0px; overflow: hidden; width: 68px; border-radius: 12px;">
                  <div class="conveythis-widget-current-language-wrapper">
                    <div class="conveythis-widget-language" style="width: 100%; height: 36px; border: unset; background-color: unset;">
                      <div style="height: 36px; width: 24px; background-size: contain; background-image: url(&quot;//cdn.conveythis.com/images/flags/v3/round/Dw0.png&quot;); display: inline-block; background-position: 50% 50%; background-repeat: no-repeat; background-color: transparent; margin-left: 10px;"></div>
                      <div class="conveythis-language-arrow" style="padding: 0px; margin: 0px 0px 0px 10px; display: inline-block; height: 36px; width: 10px; background-color: rgb(0, 0, 0); -webkit-mask: url(&quot;//cdn.conveythis.com/images/right-thin-chevron.svg&quot;) 50% 50% / contain no-repeat;"></div>
                    </div>
                  </div>
                  <div class="conveythis-widget-languages" style="display: none;">
                    <div class="conveythis-widget-language" style="width: 100%; height: 36px; border-top: 1px solid rgb(255, 255, 255);"><div style="height: 36px; width: 24px; background-size: contain; background-image: url(&quot;//cdn.conveythis.com/images/flags/v3/round/M2e.png&quot;); display: inline-block; background-position: 50% 50%; background-repeat: no-repeat; background-color: transparent; margin-left: 10px;"></div></div>
                    <div class="conveythis-widget-language" style="width: 100%; height: 36px; border-top: 1px solid rgb(255, 255, 255);"><div style="height: 36px; width: 24px; background-size: contain; background-image: url(&quot;//cdn.conveythis.com/images/flags/v3/round/E77.png&quot;); display: inline-block; background-position: 50% 50%; background-repeat: no-repeat; background-color: transparent; margin-left: 10px;"></div></div>
                    <div class="conveythis-widget-language" style="width: 100%; height: 36px; border-top: 1px solid rgb(255, 255, 255);"><div style="height: 36px; width: 24px; background-size: contain; background-image: url(&quot;//cdn.conveythis.com/images/flags/v3/round/0Rq.png&quot;); display: inline-block; background-position: 50% 50%; background-repeat: no-repeat; background-color: transparent; margin-left: 10px;"></div></div>
                    <div style="white-space: normal; text-align: center; font-size: 12px; border-top: 1px solid rgb(255, 255, 255);"><span style="color: #8e8e8e;">Powered by </span><a href="https://www.conveythis.com/?utm_source=conveythis_drop_down_btn" alt="conveythis.com" style="color: #489fd2;">ConveyThis</a></div>
                  </div>
                </div>
              </div>
            </div> -->
<!--          </div>-->
        </div>


      </div>
      <div class="lng">
        <!-- <a class="felxCenterBetween" >
          <img loading="lazy"  class="flagImg" src="https://restcountries.eu/data/gbr.svg" alt=""/>  <!-<i nz-icon nzType="down"></i>->
        </a> -->
   <!--      <nz-dropdown-menu #menulng="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item><img loading="lazy"  class="flagImg" src="https://restcountries.eu/data/umi.svg" alt=""/> </li>
          </ul>
        </nz-dropdown-menu> -->
      </div>
    <!-- </div> -->

  </div>
</nav>
