<div class="row block1">
    <div class="col-md-5">
        <h2> RegTech & SupTech </h2>
        <div class="desc">
            Peaqock has created a solution available to regulators, whether in the insurance or banking sector, to ensure financial stability. Our solution offers two main modules:
        </div>
        <div class="desc detailDsc">
            <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
           <p> Macro-prudential supervision, a module which allows to analyze systemic risks based on methods and functionality for the realization of dynamic risk maps as well as stress sensitivity tests. </p>
        </div>
        <div class="desc detailDsc">
            <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
            <p>Micro-prudential supervision, which offers tools for the treatment and analysis of risks as well as for a diagnosis of Basel III ratios with various functionalities. This solution also includes a forecasting sub-module dedicated to anticipating liquidity, credit, solvency risks, etc.</p>
        </div>
    </div>
    <div class="col-md-7">
        <div class="blocImgsq">
            <img loading="lazy"  src="assets/innovation/Regtech.png" alt=""/>
        </div>
    </div>
</div>

<div class="bloc2">
<div class="blockFeatures">
    <div class="tile">Features</div>
    <div class="sep"></div>

    <div class="row">
        <div class="col-md-3">
            <div class="blockDetail">
                <div class="blocImg"><img loading="lazy"  src="assets/consulting/data-exchange.png" alt=""/></div>
                <div class="blocTxt">
                    <div class="title">Data Exchange</div>
                    <!-- <div class="descrp">The P-Advisor automates and optimizes different investment strategies based on client preferences and artificial intelligence algorithms.</div> -->
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="blockDetail">
                <div class="blocImg"><img loading="lazy"  src="assets/consulting/reportDesign.png" alt=""/></div>
                <div class="blocTxt">
                    <div class="title">Report Design</div>
                    <!-- <div class="descrp">The P-Advisor automates and optimizes different investment strategies based on client preferences and artificial intelligence algorithms.</div> -->
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="blockDetail">
                <div class="blocImg"><img loading="lazy"  src="assets/consulting/risk.png" alt=""/></div>
                <div class="blocTxt">
                    <div class="title">Risk Analysis</div>
                    <!-- <div class="descrp">The P-Advisor automates and optimizes different investment strategies based on client preferences and artificial intelligence algorithms.</div> -->
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="blockDetail">
                <div class="blocImg"><img loading="lazy"  src="assets/consulting/ReportAnalytics.png" alt=""/></div>
                <div class="blocTxt">
                    <div class="title">Report Analytics</div>
                    <!-- <div class="descrp">The P-Advisor automates and optimizes different investment strategies based on client preferences and artificial intelligence algorithms.</div> -->
                </div>
            </div>
        </div>
    </div>

</div>


<div class="bloc3 mt-3">
    <div class="tile">Process</div>
    <div class="sep"></div>
    <div  data-aos-once="true" data-aos-duration="1000" data-aos="fade-up" class="blocImgs">
        <img loading="lazy"  src="assets/innovation/ProcessRegTech.png" alt=""/>
    </div>
</div>
</div>
