<div class="">
    <div class="headerBlock">
        <H1 data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"><span>Fraud solutions</span> </H1>
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="separator"></div>
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="descr">  Reducing fraud and bringing more people into a financial inclusive environment is our aim</div>
        <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" src="assets/fraud_asset/1.png" alt="Fraud solutions">
    </div>

    <div class="bloc1">
        <div class="row h-100 ">
            <div class="col-md-1"></div>

            <div class="col-md-6 h-100 ">
                <div class="centerElemnt">
                    <div class="">
                        <div class="title mb-5">Reducing fraud and bringing more people into a financial inclusive environment is our aim</div>
                        <div class="desc">We help financial institution to improve operation efficiency and to protect their clients from malicious fraudsters.
                            Our solution uses the blacklists data and machine learning technology in fraud detection. It runs some thorough validations on an order transaction to effectively detect a malicious fraud.
                            </div>
                    </div>
                </div>


            </div>
        </div>
    </div>

    <div class="bloc2">
        <div class="">
            <h2>Benefits</h2>
            <div class="sepr"></div>
            <div class="desc">We help financial institution to improve operation efficiency and to protect their clients
                from malicious fraudsters.</div>
        </div>


            <div class="row mt-lg-5">
                <div class="col-md-5">
                    <div class="blocNavigate">
                        <div class="itemBloc ">
                          <div class="hFixed "> <img loading="lazy"  src="assets/fraud_asset/Benifit1.png" alt=""/></div>
                          <div class="hFixed activeB"> <img loading="lazy"  src="assets/fraud_asset/Benifit1_W.png" alt=""/></div>
                            <div class="txt">Reduce chargeback or fraud losses</div>
                        </div>
                        <div class="itemBloc">
                          <div class="hFixed"><img loading="lazy"  src="assets/fraud_asset/Benifit2.png" alt=""/></div>
                          <div class="hFixed activeB"> <img loading="lazy"  src="assets/fraud_asset/Benifit2_W.png" alt=""/></div>

                          <div class="txt">Easily investigate all fraud details with a simple intuitive interface</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <img loading="lazy"  class="imgClass" src="assets/fraud_asset/MacbookMockup.png" alt=""/>
                </div>
            </div>
    </div>

    <div class="bloc3">
        <div class="mb-5">
            <h2>Capabilities</h2>
            <div class="sepr"></div>
            <div class="desc"> <span>Peaqock payment</span>  services go beyond open banking compliance and are suitable for
                direct or indirect monetization in an <span>API marketplace model.</span>  </div>
        </div>


        <div class="navigateProduct">
            <div class="d-flex-column">
            <div class="felxBetween" data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" >
                <div class="blocmAuto">
                    <div class=" card slow-transition"  [routerLink]="['/products','fraud']">
                        <div class="card-body">
                            <div class="cardImg">
                                <img loading="lazy"  src="assets/fraud_asset/fraud detection.png" alt=""/>
                                <div class="card-title bgPosition bg-dataone1">Fraud Detection</div>
                            </div>
                            <div class="mini-text"><span>Banking</span></div>
                            <div class="label">Our solutions are enabling you and
                                your customers to manage cash</div>
                            <p class="discription"> Our solutions are enabling you and your customers to manage cash, process payments, exchange information and transfer funds cost-effectively, securely and reliably within and across national boundaries.</p>
                            <div class="readmore"><span>Read More</span> <i nz-icon nzType="plus-circle" nzTheme="fill"></i></div>
                        </div>
                   </div>
                </div>

                <div class="blocmAuto">
                    <div class=" card slow-transition"   [routerLink]="['/products','aml']">
                        <div class="card-body">
                            <div class="cardImg">
                                <img loading="lazy"  src="assets/fraud_asset/aml.png" alt=""/>
                                <div class="card-title bgPosition bg-regtech1">AML</div>
                            </div>
                            <div class="mini-text"><span>Banking</span></div>
                            <div class="label">Peaqock AML is a solution to prevent money laundering </div>
                            <p class="discription">Our solution offers highly personalized ‘concierge banking’ services (money management, budgeting tools and savings goals) it would also give customers insight into how best to optimize their finances. </p>
                            <div class="readmore"><span>Read More</span> <i nz-icon nzType="plus-circle" nzTheme="fill"></i></div>
                        </div>
                   </div>
                </div>

            </div>
         </div>
        </div>
    </div>
</div>
