import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DataServiceComponent } from './modules/service/data-service/data-service.component';
import { PqProductsComponent } from './modules/products/pq-products/pq-products.component';
import { CareersComponent } from './modules/service/data-science/data-science.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SoftwareDevelopmentComponent } from './modules/service/software-development/software-development.component';
import { ConsultingComponent } from './modules/service/consulting/consulting.component';
import { DynamicDashboardComponent } from './modules/products/dynamic-dashboard/dynamic-dashboard.component';
import { InnovationComponent } from './modules/service/innovation/innovation.component';
import { PadvisroComponent } from './modules/service/innovation/padvisro/padvisro.component';
import { AssetManagmentComponent } from './modules/industries/asset-managment/asset-managment.component';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { SmartReportComponent } from './modules/service/innovation/smart-report/smart-report.component';
import { RegTechComponent } from './modules/service/innovation/reg-tech/reg-tech.component';
import { FundsComponent } from './modules/products/funds/funds.component';
import { InvestComponent } from './modules/products/invest/invest.component';
import { RegtechComponent } from './modules/products/regtech/regtech.component';
import { BlockChainComponent } from './modules/products/block-chain/block-chain.component';
import { DataOneComponent } from './modules/products/data-one/data-one.component';
import { PublicServiceComponent } from './modules/industries/public-service/public-service.component';
import { RegulatoryComponent } from './modules/industries/regulatory/regulatory.component';
import { InsuranceComponent } from './modules/industries/insurance/insurance.component';
import { BankingComponent } from './modules/industries/banking/banking.component';
import { CapitalMarketComponent } from './modules/industries/capital-market/capital-market.component';
import { SecurityServicesComponent } from './modules/industries/security-services/security-services.component';
import { PaymentComponent } from './modules/industries/payment/payment.component';
import { ConsumerGoodsComponent } from './modules/industries/consumer-goods/consumer-goods.component';
import { IndustrialEquipmentComponent } from './modules/industries/industrial-equipment/industrial-equipment.component';
import { FrontEndComponent } from './modules/service/data-science/jobs/front-end/front-end.component';
import { ProjectManagerComponent } from './modules/service/data-science/jobs/manager/project-manager.component';
import { DesignerComponent } from './modules/service/data-science/jobs/designer/designer.component';
import { FrontTraineeComponent } from './modules/service/data-science/jobs/front-trainee/front-trainee.component';
import { BackTraineeComponent } from './modules/service/data-science/jobs/back-trainee/back-trainee.component';
import { ScienceDataComponent } from './modules/service/data-science/jobs/datascience/datascience.component';
import { PrivacyStatementComponent } from './modules/privacy-statement/privacy-statement.component';
import { CookieLawModule } from 'angular2-cookie-law';
import { CookieComponent } from './modules/cookie/cookie.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingService } from './commons/services/loading.service';
import { BannerComponent } from './modules/peaqock-home/banner/banner.component';
import { SharedService } from './commons/services/shared.service';
import { PfmComponent } from './modules/products/pfm/pfm.component';
import { OpenBankingComponent } from './modules/payment/open-banking/open-banking.component';
import { FraudSolutionsComponent } from './modules/payment/fraud-solutions/fraud-solutions.component';
import { OnlineTestingSolutionsComponent } from './modules/payment/online-testing-solutions/online-testing-solutions.component';
import { LoyaltySolutionsComponent } from './modules/payment/loyalty-solutions/loyalty-solutions.component';
import { AmlComponent } from './modules/products/aml/aml.component';
import { FraudComponent } from './modules/products/fraud/fraud.component';
import { ApiPaymentComponent } from './modules/products/api-payment/api-payment.component';
import { MicroservicesComponent } from './modules/service/software-development/microservices/microservices.component';
import { BlocChainComponent } from './modules/service/software-development/bloc-chain/bloc-chain.component';
import { DevopsComponent } from './modules/service/data-science/jobs/devops/devops.component';
import { DotnetComponent } from './modules/service/data-science/jobs/dotnet/dotnet.component';
import { FrontendInterneComponent } from './modules/service/data-science/jobs/frontend-interne/frontend-interne.component';
import { FrontendEngineerComponent } from './modules/service/data-science/jobs/frontend-engineer/frontend-engineer.component';
import { QaTesterComponent } from './modules/service/data-science/jobs/qa-tester/qa-tester.component';
import { NewsletterComponent } from './modules/products/newsletter/newsletter.component';
import { NewsComponent } from './modules/products/news/news.component';
import { CardComponent } from './components/card/card.component';
import { QChatbotComponent } from './modules/products/q-chatbot/q-chatbot.component';
import lottie from 'lottie-web';
import { SalesManagerComponent } from './modules/service/data-science/jobs/sales-manager/sales-manager.component';
import {HomePageModule} from './modules/peaqock-home/home-page.module';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DataServiceComponent,
    PqProductsComponent,
    CareersComponent,
    FrontEndComponent,
    ProjectManagerComponent,
    DesignerComponent,
    FrontTraineeComponent,
    BackTraineeComponent,
    ScienceDataComponent,
    SoftwareDevelopmentComponent,
    ConsultingComponent,
    DynamicDashboardComponent,
    InnovationComponent,
    PadvisroComponent,
    AssetManagmentComponent,
    AboutUsComponent,
    SmartReportComponent,
    RegTechComponent,
    FundsComponent,
    InvestComponent,
    RegtechComponent,
    BlockChainComponent,
    DataOneComponent,
    PublicServiceComponent,
    RegulatoryComponent,
    InsuranceComponent,
    BankingComponent,
    CapitalMarketComponent,
    SecurityServicesComponent,
    PaymentComponent,
    ConsumerGoodsComponent,
    IndustrialEquipmentComponent,
    PrivacyStatementComponent,
    CookieComponent,
    LoadingComponent,
    PfmComponent,
    OpenBankingComponent,
    FraudSolutionsComponent,
    OnlineTestingSolutionsComponent,
    LoyaltySolutionsComponent,
    AmlComponent,
    FraudComponent,
    ApiPaymentComponent,
    MicroservicesComponent,
    BlocChainComponent,
    DevopsComponent,
    DotnetComponent,
    FrontendInterneComponent,
    FrontendEngineerComponent,
    QaTesterComponent,
    NewsletterComponent,
    NewsComponent,
    CardComponent,
    QChatbotComponent,
    SalesManagerComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    CookieLawModule,

  ],
  providers: [SharedService, LoadingService, {provide: NZ_I18N, useValue: en_US}],
  bootstrap: [AppComponent]
})
export class AppModule { }
