import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';
import { SharedService } from 'src/app/commons/services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

@Component({
  selector: 'app-software-development',
  templateUrl: './software-development.component.html',
  styleUrls: ['./software-development.component.css'],
  animations: [fadeAnimation]
})
export class SoftwareDevelopmentComponent implements OnInit {
  public isMicroservices=false;
  public isBlocChain=false;
  constructor(private loadingService:LoadingService,private sharedService :SharedService, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.data.subscribe(data => {
   this.sharedService.nextdataRoute(data);
  }) }


  disableAll(){
    this.isMicroservices=false;
    this.isBlocChain=false;
   }
  showBlocChain(){
    this.disableAll();
    this.isBlocChain=true;
  }
  showMicroservices(){
    this.disableAll();
    this.isMicroservices=true;
  }

  ngOnInit() {
    this.showBlocChain();
    setTimeout(() => {
      this.loadingService.stop();
   }, 5000);
  }

}
