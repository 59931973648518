<div class="mainBLoc">
    <div class="row">
        <div class="col-md-6">
            <div class="cenerBloc">
                <div class="blockDetail" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right">
                    <h2></h2>
                    <div class="separator"></div>
                    <div class="descrp"> </div>
                </div>
            </div>

        </div>
        <div class="col-md-6">
            <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
                <img loading="lazy"  class="" id="gif-2"
                onmouseover="document.getElementById('gif-2').src='assets/GIF_capabilities/Blockchain.gif'"
                data-mode="video"  src="assets/GIF_capabilities/Blockchain.gif" alt="Dynamic  ashboard">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <div class="blocText">
                It can <br/> be used to
            </div>
        </div>
        <div class="col-md-3">
            <div class="blocData">
                <div class="cdrImg"><img loading="lazy"  src="assets/home/L1.png" alt=""/></div>

                 <p></p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="blocData">
                <div class="cdrImg"> <img loading="lazy"  src="assets/home/223.png" alt=""/></div>

                 <p></p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="blocData">
                <div class="cdrImg"><img loading="lazy"  src="assets/home/369.png" alt=""/></div>

                 <p></p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="blocData">
                <div class="cdrImg"> <img loading="lazy"  src="assets/home/3651.png" alt=""/></div>

                 <p></p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="blocData">
                <div class="cdrImg"><img loading="lazy"  src="assets/home/361.png" alt=""/></div>

                 <p></p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="blocData">
                <div class="cdrImg"><img loading="lazy"  src="assets/home/71.png" alt=""/></div>

                 <p></p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="blocData">
                <div class="cdrImg"> <img loading="lazy"  src="assets/home/36.png" alt=""/> </div>
                 <p></p>
            </div>
        </div>
    </div>

    <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-up" class="blocImg">
        <img loading="lazy"  src="assets/home/64.png" alt="Dynamic ashboard">
    </div>
</div>
