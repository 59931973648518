<div class="">
    <div class="headerBlock">
        <H1 data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"><span>Data</span> & <span>Analytics</span> </H1>
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="separator"></div>
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="descr">
            We help our clients deliver
            lasting change and make colossally successful transformations</div>
        <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" src="assets/data_analytics/587.png"
            alt="Consulting">
    </div>

    <div class="main">

    </div>
    <div  data-aos-once="true" data-aos-duration="1200" data-aos="fade-up" class="">
        <div class="blockDetail2" data-aos-once="true" data-aos-duration="1200" data-aos="fade-up">
              <h2 class="mb-4">Use Analytics tools to unlock the true value of Data</h2>
              <div class="desc mb-4">Organizations of all sizes generate plenty of data, but they can only unlock their value if they build analytic tools with the help of data scientists and software developers.  </div>
              <div class="desc mb-4">To get actionable insights from data,<span>Peaqock</span>  works with data scientists to improve the way they mine and manipulate data with the help of analytical approaches, which are then used to build software solutions. Some of this software takes advantage of cutting-edge technologies such as machine learning that enable predictive analytics and help companies to become more proactive in delivering services to their end-users </div>
                  <div class="blcImg">
                      <img loading="lazy"  src="assets/data_analytics/Layer0.png" alt=""/>
                  </div>
          </div>
    </div>
    <div  data-aos-once="true" data-aos-duration="1200" data-aos="fade-up"  class="mainConetnt">
        <div class="blockTitleSerive">
            <div class="title"><span>Discover our Services</span></div>
            <div class="descService"><span>Peaqock</span> offers solutions to manage your data and make smarter business
                decisions.</div>
            <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-2"><div class="separateur"></div></div>
                <div class="col-md-5"></div>
            </div>

        </div>

        <div class="displayMobile">
            <div class="row">

                <div class="col-md-12">
                    <div class="blockServiceItem">
                        <div class="vectorItemService  "><img loading="lazy"  src="assets/images/1.png" alt=""/></div>
                        <div class="titleSevice"> <span>Data</span> Collection</div>
                        <div class="descItemService">Good data collection requires a clear process to ensure that the data you collect is clean, consistent, and reliable. Peaqock masters the process of gathering quantitative and qualitative information on specific variables with the aim of evaluating outcomes or gleaning actionable insights.</div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="blockServiceItem">
                        <div class="vectorItemService  "><img loading="lazy"  src="assets/images/2.png" alt=""/></div>
                        <div class="titleSevice"> <span>Data </span>Analytics</div>
                        <div class="descItemService"> Our team applies an algorithmic or mechanical process to derive insights, including running through several data sets to look for meaningful correlations between them, to allow organizations and companies to make better decisions and also verify and disprove existing theories or models.</div>
                     </div>
                </div>
                <div class="col-md-12">
                    <div class="blockServiceItem">
                        <div class="vectorItemService  "><img loading="lazy"  src="assets/images/3.png" alt=""/></div>
                        <div class="titleSevice"> <span>Big </span>Data</div>
                        <div class="descItemService">Gartner defines “Big Data” as data that contains greater variety arriving in increasing volumes and with ever-higher velocity. Put simply, big data is larger and more complex data sets, especially from new data sources. These data sets are so voluminous that traditional data processing software just can’t manage them. But these massive volumes of data can be used to address business problems you wouldn’t have been able to tackle before. Peaqock offers solutions to help in managing Big data.</div>
                     </div>
                </div>
                <div class="col-md-12">
                    <div class="blockServiceItem">
                        <div class="vectorItemService  "><img loading="lazy"  src="assets/images/4.png" alt=""/></div>
                        <div class="titleSevice">Data <span>Preparation</span></div>
                       <div class="descItemService">Also called data wrangling, it is a key part of Data Science. Most of the time data you have can’t be used straight away for your analysis. Peaqock manipulate and adapt data in order to be aggregated with other sources for the analysis.</div>
                   </div>
                </div>
                <div class="col-md-12">
                    <div class="blockServiceItem">
                        <div class="vectorItemService "><img loading="lazy"  src="assets/images/5.png" alt=""/></div>
                        <div class="titleSevice">Data <span> Visualization</span></div>
                       <div class="descItemService">The process of learning begins with observations of data, such as direct experience or instruction in order to look for patterns in data and make better future decisions based on the examples that we provide. The primary aim of Machine Learning is to allow the              computers to learn automatically without human intervention or assistance and adjust actions accordingly.</div>
                   </div>
                </div>
                <div class="col-md-12">
                    <div class="blockServiceItem">
                        <div class="vectorItemService  "><img loading="lazy"  src="assets/images/6.png" alt=""/></div>
                        <div class="titleSevice">Machine <span> Learning</span></div>
                         <div class="descItemService">Refers to the representation of data or information in a graph, chart, or other visual format. It communicates relations between data and images. It enables decision makers to see analytics presented visually, so they can grasp difficult concepts or identify new patterns. With interactive visualization, you can take the concept a step further by using technology to interactively change what data to see</div>
                    </div>
                </div>



            </div>
        </div>
        <div class="displayNoMobile">
            <div class="row">
                <div class="col-md-5">
                    <div class="blockServiceItem">
                        <div class="titleSevice"> <span>Data</span> Collection</div>
                        <div class="descItemService">Good data collection requires a clear process to ensure that the data you collect is clean, consistent, and reliable. Peaqock masters the process of gathering quantitative and qualitative information on specific variables with the aim of evaluating outcomes or gleaning actionable insights.</div>
                    </div>
                    <!--  -->
                    <div class="blockServiceItem contentCenter">
                        <div class="vectorItemService  "><img loading="lazy"  src="assets/images/2.png" alt=""/></div>
                      </div>
                    <!--  -->
                    <div class="blockServiceItem">
                        <div class="titleSevice"> <span>Data </span>Analytics</div>
                        <div class="descItemService"> Our team applies an algorithmic or mechanical process to derive insights, including running through several data sets to look for meaningful correlations between them, to allow organizations and companies to make better decisions and also verify and disprove existing theories or models.</div>
                     </div>
                     <!--  -->
                     <div class="blockServiceItem contentCenter">
                        <div class="vectorItemService  "><img loading="lazy"  src="assets/images/4.png" alt=""/></div>
                      </div>
                    <!--  -->
                     <div class="blockServiceItem">
                        <div class="titleSevice"> <span>Big </span>Data</div>
                        <div class="descItemService">Gartner defines “Big Data” as data that contains greater variety arriving in increasing volumes and with ever-higher velocity. Put simply, big data is larger and more complex data sets, especially from new data sources. These data sets are so voluminous that traditional data processing software just can’t manage them. But these massive volumes of data can be used to address business problems you wouldn’t have been able to tackle before. Peaqock offers solutions to help in managing Big data.</div>
                     </div>
                    <!--  -->
                    <div class="blockServiceItem contentCenter">
                        <div class="vectorItemService  "><img loading="lazy"  src="assets/images/6.png" alt=""/></div>
                      </div>
                </div>
                <!-- <div class="col-md-1"></div> -->

                <div  data-aos-once="true" data-aos="fade-up"  data-aos-anchor-placement="top-bottom" class="col-md-2 hidenBlock">
                    <div style="Z-INDEX: -2;" class="brdFrst1">
                        <div  data-aos-duration="1500" data-aos="fade-up"  class="itemCenter"></div>
                        <div class="point"></div>
                    </div>
                    <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-right" style="top: 0;Z-INDEX: -3;" class="brdLeft">
                        <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up"  class="itemCenter"></div>
                        <div class="point"></div>
                    </div>
                    <div  data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" style="Z-INDEX: -4;"  class="brdRight" >
                        <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up"  class="itemCenter"></div>
                        <div class="point"></div>
                    </div>
                    <div   data-aos-once="true" data-aos-duration="1200" data-aos="fade-right"  style="top: -8px;Z-INDEX: -5;" class="brdLeft">
                        <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up"  class="itemCenter"></div>
                        <div class="point"></div>
                    </div>
                    <div   data-aos-once="true" data-aos-duration="1200" data-aos="fade-left"  style="top: -12px;Z-INDEX: -6;" class="brdRight">
                        <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up"  class="itemCenter"></div>
                        <div class="point"></div>
                    </div>
                    <div   data-aos-once="true" data-aos-duration="1200" data-aos="fade-right"  style="top: -16px;Z-INDEX: -7;" class="brdLeft">
                        <div   data-aos-once="true" data-aos-duration="1500" data-aos="fade-up"  class="itemCenter"></div>
                        <div class="point"></div>
                    </div>
                    <div class="brdLast"  data-aos-duration="1200" data-aos="fade-up"  style="Z-INDEX: -8;">
                        <div class="itemCenter"></div>
                    </div>

                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="col-md-5">
                    <div class="blockServiceItem contentCenter">
                        <div class="vectorItemService  "><img loading="lazy"  src="assets/images/1.png" alt=""/></div>
                      </div>
                     <div class="blockServiceItem">
                         <div class="titleSevice">Data <span>Preparation</span></div>
                        <div class="descItemService">Also called data wrangling, it is a key part of Data Science. Most of the time data you have can’t be used straight away for your analysis. Peaqock manipulate and adapt data in order to be aggregated with other sources for the analysis.</div>
                    </div>
                     <!--  -->
                     <div class="blockServiceItem contentCenter">
                        <div class="vectorItemService  "><img loading="lazy"  src="assets/images/3.png" alt=""/></div>
                      </div>
                      <!--  -->
                     <div class="blockServiceItem">
                         <div class="titleSevice">Data <span> Visualization</span></div>
                        <div class="descItemService">The process of learning begins with observations of data, such as direct experience or instruction in order to look for patterns in data and make better future decisions based on the examples that we provide. The primary aim of Machine Learning is to allow the              computers to learn automatically without human intervention or assistance and adjust actions accordingly.</div>
                    </div>
                     <!--  -->
                     <div class="blockServiceItem contentCenter">
                        <div class="vectorItemService  "><img loading="lazy"  src="assets/images/5.png" alt=""/></div>
                      </div>
                      <!--  -->
                     <div class="blockServiceItem">
                        <div class="titleSevice">Machine <span> Learning</span></div>
                         <div class="descItemService">Refers to the representation of data or information in a graph, chart, or other visual format. It communicates relations between data and images. It enables decision makers to see analytics presented visually, so they can grasp difficult concepts or identify new patterns. With interactive visualization, you can take the concept a step further by using technology to interactively change what data to see</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="blockTitleSerive">
            <div class="title"><span>Discover our Features</span></div>
            <div class="descService"><span>Peaqock</span> offers solutions to manage your data and make smarter business decisions.</div>
            <div class="separateur"></div>
        </div>

        <div class="margin-bottom mb-5">
            <div class="row">
                <div class="col-md-4">
                    <div class="blockFeatures slow-transition">
                        <div class="titleFeatures">Data Collection</div>
                        <div class="separateurFeatures"></div>
                        <div class="descFeatures"></div>
                        <div class="listFeatures">
                            <ul>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i> Collection from Various Online Sources</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Web Scraping</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Telephone Interviewing</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Questionnaire Surveys</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Video or Audio Behavioral Assessments</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Mail & Web Surveys</li>
                            </ul>
                        </div>
                        <!-- <div class="btnRedMore">Read more&nbsp;&nbsp; &gt;</div> -->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="blockFeatures slow-transition">
                        <div class="titleFeatures">Data Preparation</div>
                        <div class="separateurFeatures"></div>
                        <div class="descFeatures"></div>
                        <div class="listFeatures">
                            <ul>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Intelligent Ingest</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>One-Click Profile</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Explore & Combine</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Clean & Shape</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Automate & Share</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Collaborate & Govern</li>
                            </ul>
                        </div>
                        <!-- <div class="btnRedMore">Read more&nbsp;&nbsp; &gt;</div> -->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="blockFeatures slow-transition">
                        <div class="titleFeatures">Data Analytics</div>
                        <div class="separateurFeatures"></div>
                        <div class="descFeatures"></div>
                        <div class="listFeatures">
                            <ul>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Operational data analysis</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Sales data analysis</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Marketing data analysis</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Financial data analysis</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Performance data analysis</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Customer data analysis</li>
                            </ul>
                        </div>
                        <!-- <div class="btnRedMore">Read more&nbsp;&nbsp; &gt;</div> -->
                    </div>
                </div>
            </div>
            <div class="sepr"></div>
            <div class="row">
                <div class="col-md-4">
                    <div class="blockFeatures slow-transition">
                        <div class="titleFeatures">Machine learning</div>
                        <div class="separateurFeatures"></div>
                        <div class="descFeatures"></div>
                        <div class="listFeatures">
                            <ul>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Data Modelling </li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Algorithm Design</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Modeling Modeling</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Online Learning</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Implementing a machine learning</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Monitoring Model</li>
                            </ul>
                        </div>
                        <!-- <div class="btnRedMore">Read more&nbsp;&nbsp; &gt;</div> -->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="blockFeatures slow-transition">
                        <div class="titleFeatures">Big Data</div>
                        <div class="separateurFeatures"></div>
                        <div class="descFeatures"></div>
                        <div class="listFeatures">
                            <ul>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Distributed Storage </li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Database Management</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Data Management</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Big Data Processing</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Data Streaming</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Big Data Security</li>
                            </ul>
                        </div>
                        <!-- <div class="btnRedMore">Read more&nbsp;&nbsp; &gt;</div> -->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="blockFeatures slow-transition">
                        <div class="titleFeatures">Data Visualization </div>
                        <div class="separateurFeatures"></div>
                        <div class="descFeatures"></div>
                        <div class="listFeatures">
                            <ul>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Reports Based On Business Role </li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Reduced Dependency On Spreadsheets</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Hasslefree Filtering Of Data</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Easy Interpretation Of Complex Data</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Self-Service Reporting</li>
                                <li><i nz-icon nzType="check" nzTheme="outline"></i>Dynamic Dashboard</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
