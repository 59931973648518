import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';

@Component({
  selector: 'app-data-one',
  templateUrl: './data-one.component.html',
  styleUrls: ['./data-one.component.css']
})
export class DataOneComponent implements OnInit {

  constructor(private loadingService:LoadingService) { }

  ngOnInit() {
    setTimeout(() => {
      this.loadingService.stop();
   }, 3000);
  }

}
