import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class SharedService {
    constructor() {
        this.dataRoute = new BehaviorSubject(null);
        this.sharedDataRoute = this.dataRoute.asObservable();
    }
    nextdataRoute(data) {
        this.dataRoute.next(data);
    }
}
SharedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedService_Factory() { return new SharedService(); }, token: SharedService, providedIn: "root" });
