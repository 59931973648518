<div class="mainBLoc">
    <div class="row">
        <div class="col-md-6">
            <div class="cenerBloc">
                <div class="blockDetail" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right">
                    <h2>Invest</h2>
                    <div class="separator"></div>
                    <div class="descrp">Our solution helps to streamline reporting between the headquarters of a group and its subsidiaries. Among the different components managed by this solution, we find: the management of administrative statements, the management of legal papers, cash management and Shareholding ... </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
                <img loading="lazy"  class="" id="gif-2"
                onmouseover="document.getElementById('gif-2').src='assets/GIF_capabilities/Invest.gif'"
                data-mode="video"  src="assets/GIF_capabilities/Invest.gif" alt="Dynamic  ashboard">
            </div>
        </div>
    </div>
</div>
<div class="can-be-used-background">
    <div class="row mb-4">
        <div class="col-md-12">
            <div class="blocText">
             </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Generation of reports</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Standardization of exchanged data </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Data consistency check </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Notifications</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Management of delivery time and frequencies</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Definition of a simple data processing workflow for all stakeholders </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Calculation of KPIs</div>
    </div>
</div>
<div class="benifits-background">


   <div class="row">

    <div class="col-md-6">
        <div class="d-flex-column " data-aos-duration="1200" data-aos="fade-left">
            <h2 class="Benefits-title">Benefits</h2>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">Tasks automation</div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">Traceability </div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">User Permissions for Sharing Reports</div>
                </div>
           </div>
        </div>
    </div>
    <div class="col-md-6">
        <img loading="lazy"  src="assets/home/64.png"  data-aos-duration="1200" data-aos="fade-up"
        alt="Dynamic ashboard" style="height: 360px;">
    </div>
   </div>
</div>
