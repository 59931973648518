import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
public opacityChange = 1;
public splashTransition;
public showSplash = true;
readonly ANIMATION_DURATION = 1;
  constructor(private loadingService: LoadingService) { }

  ngOnInit() {
   this.loadingService.subscribe(res => {
     if (res === false) {
      this.splashTransition = `opacity ${this.ANIMATION_DURATION}s`;
      this.opacityChange = 0;
      setTimeout(() => {
         this.showSplash = false;
      }, 1000);
     } else {
      this.hideSplashAnimation();
     }
 });

  }
  private hideSplashAnimation() {
    this.splashTransition = `opacity ${this.ANIMATION_DURATION}s`;
    this.opacityChange = 0;
    setTimeout(() => {
       this.showSplash = !this.showSplash;
    }, 1000);
 }
}
