import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';
import { News } from '../news';
import { Router } from '@angular/router';
import { BlogsService } from '../newsletter/blogs.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  listNews: News[]= []



  constructor(private loadingService:LoadingService, private router:Router, private blogsService: BlogsService) {

  }

  getBlogs (){
    this.listNews = this.blogsService.getAllBlogs()
    console.log(this.listNews, 'LISTFHFHFHFH');

  }

  ngOnInit() {
    this.getBlogs()
    setTimeout(() => {
      this.loadingService.stop();
   }, 3000);

  }

  onNavigation(cardId: string){
    this.router.navigate(['newsletter/', cardId])
    console.log('navigate: ', cardId);
  }



//   toggleDescription(news: any) {
//     news.showFullDesc = !news.showFullDesc;
// }

}
