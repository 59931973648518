<div data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" class="imgForData1">
    <img src="assets/joinus/manager/projectmanager.png" alt="">
</div>
<!-- <div class="weare">General Information</div>
  <div class="weare-text">
       <strong>Salary grade/band:</strong> TBD
  </div> -->
<!--<div class="weare-text">-->
<!--  <strong>Bonus:</strong> Quarterly bonus based on performance (which will be equivalent to 0 to 3 times salary per-->
<!--  annum)-->
<!--</div>-->
<!--<div class="weare-text">-->
<!--  <strong>Personal insurance:</strong> Yes (CNSS + Complementary insurance)-->
<!--</div>-->
<!--<div class="weare-text">-->
<!--  <strong>Organization/department:</strong> C-suite-->
<!--</div>-->
<!-- <div class="weare-text">
    <strong>Reports to:</strong> CEO
  </div>
  <div class="weare-text">
    <strong>Effective date:</strong> Immediate
  </div> -->

<div class="weare">Position Summary</div>
<div class="weare-text">
    The Sales Manager will play a pivotal role in driving the growth and success of our company.
    He/She will be responsible for driving sales and revenue growth in a rapidly evolving industry. The
    Sales Manager will report directly to the CEO and play a crucial role in expanding our customer base
    and ensuring the success of our innovative products and solutions.
</div>


<div class="mt-4 mb-4 row">
    <div class="col-md-4">
        <div class="imgForData respo-image"><img src="assets/joinus/3.png" alt=""></div>
    </div>
    <div class="col-md-8 ">
        <div class="titleData">Primary Responsibilities</div>
        <div class="ssseparateurProduct"></div>
        <div class="sousTitleData">Job Responsibilities</div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Collaborate with the CEO to define and execute the company&#39;s sales strategy
                and goals.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Identify target markets and customer segments for our solutions.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Create and implement sales plans and campaigns to achieve revenue
                targets.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Build and maintain relationships with potential clients, cultivating leads into
                opportunities.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Develop and manage a pipeline of prospects and qualified leads.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Actively engage in sales prospecting and outreach, including cold calling, email
                campaigns, and networking.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Develop a deep understanding of our products.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Effectively communicate the value proposition of our solutions to potential clients.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Provide product feedback and insights based on client interactions to help refine our
                offerings.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Deliver engaging sales presentations to potential clients,showcasing the benefits of
                our solutions.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Lead negotiations and close deals, ensuring the best terms for both the company and
                the client.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Prepare and present proposals, contracts, and pricing agreements.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Maintain accurate sales records and track progress towards revenue targets.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Use data and analytics to evaluate sales performance and identify areas for
                improvement.</span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Provide regular reports and updates to the CEO on sales activities and results.</span>
        </div>
        <div class="sousTitleData sousTitleData2">Required Skills</div>

        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <!--      <span> More than 7 years of overall experience and minimum of 2 years of IT experience. </span>-->
            <span> Bachelor’s degree in marketing, business, or a related field </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>2-5 years of experience in sales or business development role, preferably in the same industry
                as the organization. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Knowledge of sales channels and tools, including CRM, SPM, Sales analytics and forecasting,
                or others… </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Ability to work in a fast-paced, dynamic environment, and manage multiple priorities
                simultaneously.
            </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Proven track record of meeting or exceeding sales targets. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Strong communication and interpersonal skills. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Self-motivated and results-driven with the ability to work independently. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Knowledge of industry products and services is a plus. </span>
        </div>
        <!-- <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Excellent written and verbal communications skills. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Ability to evaluate and recruit exceptional project management talent. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Experience working within both project-centric and project-matrix environments. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Ability to recognize exceptions to documented policy and procedures. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Monitor schedule deviations and assist in the development of alternative methods for corrective
                action at the project level. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Working knowledge of quality assurance methods, tools, and techniques. </span>
        </div> -->
    </div>

</div>
<div class="row">
    <div class="col-md-6 mt-4 mb-4">
        <div class="titleData">Working Conditions</div>
        <div class="ssseparateurProduct"></div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Hybrid: Monday and Friday are remote days. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Domestic and international travel required. </span>
        </div>
        <!-- <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Experience working within an established Program Office environment. </span>
        </div> -->

    </div>

    <div class="col-md-6 mt-4 mb-4">
        <!--    <div class="imgForData qualif-image"><img src="assets/joinus/4.png" alt=""></div>-->

        <div class="titleData">Primary Contacts</div>
        <div class="ssseparateurProduct"></div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Reports to CTO. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span> Marketing officer. </span>
        </div>
        <div class="respon-text">
            <img src="/assets/joinus/right.png">
            <span>Product manager. </span>
        </div>
    </div>
</div>