import * as i0 from "@angular/core";
export class AnimationsService {
    constructor() {
        AnimationsService.routeAnimationType = 'NONE';
    }
    static isRouteAnimationsType(type) {
        return AnimationsService.routeAnimationType === type;
    }
    updateRouteAnimationType(pageAnimations, elementsAnimations) {
        AnimationsService.routeAnimationType =
            pageAnimations && elementsAnimations
                ? 'ALL'
                : pageAnimations
                    ? 'PAGE'
                    : elementsAnimations
                        ? 'ELEMENTS'
                        : 'NONE';
    }
}
AnimationsService.routeAnimationType = 'NONE';
AnimationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnimationsService_Factory() { return new AnimationsService(); }, token: AnimationsService, providedIn: "root" });
