<div class="headerBlock">
    <H1 data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"> <span>Consulting</span> </H1>
    <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="separator"></div>
    <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="descr">We help our client identify and implement the change needed to enable their organization to achieve its goal</div>
    <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" src="assets/consulting/01.png"
        alt="Consulting">
</div>
<div style="width: 100%;" class="row">
    <!-- <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
        <img loading="lazy"  class="" src="assets/consulting/02.png" alt="consulting">
    </div> -->
    <!-- <div class="col-md-1"></div> -->
    <div class="col-md-12">
        <div class="blockDetail" data-aos-once="true" data-aos-duration="1200" data-aos="fade-up">
            <h2>We propose tailored strategies that fit with our client’s business and culture. </h2>
            <!-- <div class="separator"></div> -->
            <div class="descrp">Our consultants work side-by-side with senior management to identify solutions and
                drives value by implementing strategic improvements involving change management and business
                transformation. </div>
        </div>
    </div>

</div>

<div class="blockDigital">
    <h2>Digital transformation</h2>
    <div class="separator"></div>
    <div style="width: 100%;" class="row">
        <div class="col-md-6 noMobile">
            <div class="imgBlockD">
                <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" src="assets/consulting/03.png"
                    alt="Digital transformation">
            </div>
        </div>
        <div class="col-md-6">
            <div class="blockDetailDigital">
                <h2>Our objective is to help you steer your transformation with the latest technology, design thinking and agility.  </h2>
                <div class="desc mb-3">As a direct result of the ongoing digital revolution, technology is continuously evolving and companies all over the world feel the urge to integrate or to develop digital processes in their global strategy. </div>
                <div class="desc">Positioned as a regional tech leader with project management skills, <span> Peaqock offers tailored consultancy services in Digital Transformation, adapted to the need of each client.</span>  </div>

            </div>
        </div>
    </div>

    <!-- <div class="main">
        <div class="detail">Positioned as a regional tech leader with project management skills, Peaqock offers tailored
            consultancy services in Digital Transformation, adapted to the need of each client. </div>
        <div style="width: 100%;" class="row">
            <div class="col-md-4 ">
                <div class="blocImgDetail bg1"> We study the existing processes </div>
            </div>
            <div class="col-md-4 ">
                <div class="blocImgDetail bg2"> We define your needs and goals</div>
            </div>
            <div class="col-md-4 ">
                <div class="blocImgDetail bg3">We define your needs and goals </div>
            </div>
            <div class="col-md-4 ">
                <div class="blocImgDetail bg4">We optimize your data with adapted processes </div>
            </div>
            <div class="col-md-4 ">
                <div class="blocImgDetail bg5">We enhance your profits </div>
            </div>
            <div class="col-md-4 ">
                <div class="blocImgDetail bg6">We support you with our skill, each step of your transformation </div>
            </div>
        </div>
    </div> -->
</div>


<div class="blockTechnology">
    <h2 class="mb-3 mt-3">Technology</h2>
    <div class="separator"></div>
    <!-- <div class="separator"></div> -->
    <div style="width: 100% " class="row">
        <div class="col-md-12">
            <div class="detail">
                <h2 class="blockDetailTechnology mb-3 mt-3">We help our client develop and implement latest trend technologies in an effective way to be more productive and profitable. </h2>
              <!--   <div class="separator"></div> -->
                <div class="desc">Our approach begin with defining your business goals and functional needs then we evaluate your existing infrastructure and identify its limit. Based on this first analysis, we define tailored IT strategy to enable you to drive profitability and efficiency from the               available processes. Finally, we build transformation roadmap that enable you to implement the latest and existing technologies.</div>
            </div>
        </div>

            <div   class="row">
                <!-- <div class="col-sm-4">
                    <div class="blocFildest">
                         <img loading="lazy"  src="assets/consulting/10.png" alt="Consulting">
                        <div class="title">Artificial Intelligence </div>
                        <div class="desc">which simulate human thought processes to improve the  responsiveness of the
                            production system.</div>
                    </div>
                </div> -->
                <div class="col-sm-4">
                    <div class="blocFildest">
                        <div class="flexBloc">
                            <img loading="lazy"  src="assets/consulting/11.png" alt="Consulting">
                            <div class="title">Analytics </div>
                        </div>

                        <div class="desc">uses a set of techniques to explore all        business data, for the purposes of obtaining insights about a business that can lead to improved decision-making.</div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="blocFildest">
                        <div class="flexBloc">
                            <img loading="lazy"  src="assets/consulting/13.png" alt="Consulting">
                            <div class="title"> Industrial Internet of Things  </div>
                        </div>

                        <div class="desc">connects industrial equipment with each other and with the corporate information system through sensors. </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="blocFildest">
                        <div class="flexBloc">
                            <img loading="lazy"  src="assets/consulting/12.png" alt="Consulting">
                            <div class="title"> Blockchain </div>
                        </div>

                        <div class="desc">makes it possible to store and transmit information in a completely transparent and secure manner </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="blocFildest">
                        <div class="flexBloc">
                            <img loading="lazy"  src="assets/consulting/05.png" alt="Consulting">
                            <div class="title"> Additive manufacturing </div>
                        </div>
                        <div class="desc">capable of producing subassemblies from powders.</div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="blocFildest">
                        <div class="flexBloc">
                            <img loading="lazy"  src="assets/consulting/04.png" alt="Consulting">
                            <div class="title"> Augmented reality  </div>
                        </div>

                        <div class="desc">which simulate human thought processes to improve the  responsiveness of the production system. </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="blocFildest">
                        <div class="flexBloc">
                            <img loading="lazy"  src="assets/consulting/06.png" alt="Consulting">
                            <div class="title">Virtual reality   </div>
                        </div>
                        <div class="desc">computer-generated simulation in which a person can interact within an artificial three-dimensional environment using electronic devices </div>
                    </div>
                </div>
            </div>

    </div>


</div>

<div class="enterpriseArchitecture">
    <div class="col-md-8">
        <h2>Enterprise Architecture </h2>
        <div class="separator"></div>
        <div class="title">Plan and build a forward-looking systems infrastructure</div>
        <div class="desc">We help our clients analyse, design and implement enterprise analysis to successfully execute on business strategies. We define a tailored strategy to help businesses structure IT projects and
            policies to achieve desired business result.</div>
    </div>

</div>



<div class="blockDigital main2">
    <h2>Change Management</h2>
    <div class="separator"></div>
    <div style="width: 100%;" class="row mt-5">
        <div class="col-md-6 ">
            <div class="imgBlockD">
                <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" src="assets/consulting/014.png"
                    alt="Change Management">
            </div>
        </div>
        <div class="col-md-6">
            <div class="blockDetailDigital">
                <h2>We help our client identifying and implementing the change needed to enable their organization to achieve its goal. </h2>
                <div class="desc">Change management is the most challenging and critical component of any organizational transformation. With our structured approach (preparing, managing and reinforcing change) to managing people we help our client successed all the type of transformation.  </div>
            </div>
        </div>
    </div>


</div>
