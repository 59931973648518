<div class="">
    <div class="headerBLock">
        <div class="headerBg">
            <div data-aos-once="true" data-aos-duration="1300" data-aos="fade-right" class="title">Capital market</div>
        </div>
        <div data-aos-once="true" data-aos-duration="1300" data-aos="fade-right" class="blockInfo">
            <div class="blockinfoTxt ">
                <div class="cstmWidth">
                    <div class="desp"></div>
                    <div class="separateur"></div>
                    <div class="titleDesc">We help Capital Market organizations deliver change that matters, driving transformations that have a significant, positive impact.</div>
                    <div class="desp">We build tailored solutions to enhance both the customer facing and operational side of the organization to create innovation and efficiencies.</div>
                </div>
            </div>
            <div  class="infoImg">
                <img loading="lazy"  data-aos-once="true" data-aos-duration="1300" data-aos="fade-left" src="assets/industries__vector/Capital market_1.png"  alt=""/>
            </div>
        </div>
    </div>

    <div class="blockSLid bgBloc">
        <div class="row">
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="col-md-4">
                <div class="blocImg"><img loading="lazy"  src="assets/industries/Capital market_.png" alt=""/></div>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                <div class="flexCenter">
                    <div class="blocData">
                        <div class="title">Our solution helps investor’s make the best choice of investment projects</div>
                        <div class="separateur"></div>
                        <div class="descp">Peaqock Advisor weigh personal preferences against unpredictable forces to automatically recommend a portfolio that fits investor’s specific needs. <br>
                            The Peaqock Advisor platform processes the inputs using proprietary algorithms and selects a tailored portfolio based on investor requirements. Our solution enable their users to readjust goals and risk tolerance which – in turn – triggers a portfolio rebalancing.
                            </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- <nz-carousel [nzAutoPlay]="false"   [nzEffect]="effect">
            <div nz-carousel-content >
                <div class="row">
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="col-md-4">
                        <div class="blocImg"><img loading="lazy"  src="assets/industries/layer1.png" alt=""/></div>
                    </div>
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                        <div class="flexCenter">
                            <div class="blocData">
                                <div class="title">Discover our Capabilities</div>
                                <div class="separateur"></div>
                                <div class="descp"><span>Our solution</span>  helps you <span>streamline reporting</span>  between the headquarters of a group and its subsidiaries. Among the different components managed by this solution, we find: the management of administrative statements, the management of legal papers, cash management and Shareholding ...</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
               <div nz-carousel-content >
                <div class="row">
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="col-md-4">
                        <div class="blocImg"><img loading="lazy"  src="assets/industries/layer1.png" alt=""/></div>
                    </div>
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                        <div class="flexCenter">
                            <div class="blocData">
                                <div class="title">Discover our Capabilities</div>
                                <div class="separateur"></div>
                                <div class="descp"><span>Our solution</span>  helps you <span>streamline reporting</span>  between the headquarters of a group and its subsidiaries. Among the different components managed by this solution, we find: the management of administrative statements, the management of legal papers, cash management and Shareholding ...</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </nz-carousel>
        -->
    </div>

    <div class="blocInfoDetail">
        <div class="title">Solution Features</div>
        <div class="row">
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left"  class="col-md-3">
                <div class="">
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/7.png" alt="Reports Generation">
                        <div class="txt"> Prospects about customer’s portfolios </div>
                    </div>
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/8.png" alt="Simple data processing  workflow for all stakeholders ">
                        <div class="txt">Independent and accurate market analysis</div>
                    </div>

                </div>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up"  class="col-md-6">
                <img loading="lazy"  class="centreIamge" src="assets/industries/layer2.png" alt=""/>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"  class="col-md-3">
                <div class="blocDetail1 rightTxt">
                    <img loading="lazy"  src="assets/industries/5.png" alt="Standardization of exchanged data">
                    <div class="txt">Powerful calculation engine and analytics tools for diagnosis</div>
                </div>
                <div class="blocDetail1 rightTxt">
                    <img loading="lazy"  src="assets/industries/3.png" alt="Management of delivery time and frequencie">
                    <div class="txt">Mobile access</div>
                </div>
            </div>
        </div>
    </div>
</div>
