import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';
import { SharedService } from 'src/app/commons/services/shared.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-consulting',
  templateUrl: './consulting.component.html',
  styleUrls: ['./consulting.component.css']
})
export class ConsultingComponent implements OnInit {

  constructor(private loadingService:  LoadingService,private sharedService :SharedService, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.data.subscribe(data => {
   this.sharedService.nextdataRoute(data);
  }) }

  ngOnInit() {
    setTimeout(() => {
      this.loadingService.stop();
   }, 5000);
  }

}
