<div class="">
    <div class="headerBLock">
        <div class="headerBg">
            <div data-aos-once="true" data-aos-duration="1300" data-aos="fade-right" class="title">Public services</div>
        </div>
        <div data-aos-once="true" data-aos-duration="1300" data-aos="fade-right" class="blockInfo">
            <div class="blockinfoTxt ">
                <div class="cstmWidth">
                    <div class="desp">We support public sector organizations in their end-to-end transformation.
                        Our offer is digital & analytics oriented and data-centric because we are convinced that the exploitation of data is the first level of evolution of the public sector to find new ways to impact citizen’s lives.
                        </div>
                    <div class="separateur"></div>
                    <div class="titleDesc">We build tailor-made solutions to help Public services face major social and economic challenges.</div>
                    <div class="desp">Digital, Technology and Data enables governments reform the way public services are delivered and align their services more closely with customer demand, this is leading to different patterns of use as customers become increasingly IT-literate and self-sufficient.</div>
                </div>
            </div>
            <div  class="infoImg">
                <img loading="lazy"  data-aos-once="true" data-aos-duration="1300" data-aos="fade-left" src="assets/industries__vector/Public services_ .png"  alt=""/>
            </div>
        </div>
    </div>

    <div class="blockSLid bgBloc">
        <div class="row">
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="col-md-4">
                <div class="blocImg"><img loading="lazy"  src="assets/industries/Public services_.png" alt=""/></div>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                <div class="flexCenter">
                    <div class="blocData">
                        <div class="title">We offer data-driven solutions to improve citizen’s lives </div>
                        <div class="separateur"></div>
                        <div class="descp">Our solution is completely configurable and user-friendly, it uncovers efficiencies and provides insights.
                            We designed our solution to ensure you realize your analytics goals. Powered by AI and machine learning for analytics and rich graphic library for a better report visualization.
                            </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- <nz-carousel [nzAutoPlay]="false"   [nzEffect]="effect">
            <div nz-carousel-content >
                <div class="row">
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="col-md-4">
                        <div class="blocImg"><img loading="lazy"  src="assets/industries/layer1.png" alt=""/></div>
                    </div>
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                        <div class="flexCenter">
                            <div class="blocData">
                                <div class="title">Discover our Capabilities</div>
                                <div class="separateur"></div>
                                <div class="descp"><span>Our solution</span>  helps you <span>streamline reporting</span>  between the headquarters of a group and its subsidiaries. Among the different components managed by this solution, we find: the management of administrative statements, the management of legal papers, cash management and Shareholding ...</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
               <div nz-carousel-content >
                <div class="row">
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="col-md-4">
                        <div class="blocImg"><img loading="lazy"  src="assets/industries/layer1.png" alt=""/></div>
                    </div>
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                        <div class="flexCenter">
                            <div class="blocData">
                                <div class="title">Discover our Capabilities</div>
                                <div class="separateur"></div>
                                <div class="descp"><span>Our solution</span>  helps you <span>streamline reporting</span>  between the headquarters of a group and its subsidiaries. Among the different components managed by this solution, we find: the management of administrative statements, the management of legal papers, cash management and Shareholding ...</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </nz-carousel>
        -->
    </div>

    <div class="blocInfoDetail">
        <div class="title">Solution Features</div>
        <div class="row">
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left"  class="col-md-3">
                <div class="">
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/7.png" alt="Reports Generation">
                        <div class="txt"> 	Intuitive Interface  </div>
                    </div>
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/8.png" alt="Simple data processing  workflow for all stakeholders ">
                        <div class="txt">Data Processing </div>
                    </div>
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/6.png" alt="KPIs">
                        <div class="txt"> Predective models and Analytics (Risk Analytics, Decision Management, Text Analytics, Statistical Analysis, Social Media Analytics)</div>
                    </div>
                </div>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up"  class="col-md-6">
                <img loading="lazy"  class="centreIamge" src="assets/industries/layer2.png" alt=""/>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"  class="col-md-3">
                <div class="blocDetail1 rightTxt">
                    <img loading="lazy"  src="assets/industries/5.png" alt="Standardization of exchanged data">
                    <div class="txt">Reporting Features (Real-Time Reporting, Dashboards, Location-Based Insights, Easy to Share)</div>
                </div>
                <div class="blocDetail1 rightTxt">
                    <img loading="lazy"  src="assets/industries/3.png" alt="Management of delivery time and frequencie">
                    <div class="txt">Scalable Analytics</div>
                </div>

            </div>
        </div>
    </div>
</div>
