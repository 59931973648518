/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cookie.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./cookie.component";
var styles_CookieComponent = [i0.styles];
var RenderType_CookieComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CookieComponent, data: {} });
export { RenderType_CookieComponent as RenderType_CookieComponent };
function View_CookieComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "cookie"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "row"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "col-sm-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Peaqock uses cookies to improve functionality and performance of this site. More information can be found in our "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 1), (_l()(), i1.ɵted(-1, null, ["Privacy Policy"])), (_l()(), i1.ɵted(-1, null, [" . By continuing to browse this site, you consent to the use of cookies. "])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "flexItem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btnFrst"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptCookie() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["ACCEPT"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btnlst"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CLOSE"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 7, 0, "/privacy"); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).target; var currVal_1 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_CookieComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CookieComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showItem; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CookieComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cookie", [], null, null, null, View_CookieComponent_0, RenderType_CookieComponent)), i1.ɵdid(1, 114688, null, 0, i4.CookieComponent, [i3.Location, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CookieComponentNgFactory = i1.ɵccf("app-cookie", i4.CookieComponent, View_CookieComponent_Host_0, {}, {}, []);
export { CookieComponentNgFactory as CookieComponentNgFactory };
