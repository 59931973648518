import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front-end',
  templateUrl: './front-end.component.html',
  styleUrls: ['./front-end.component.css', '../back-trainee/back-trainee.component.css']
})
export class FrontEndComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
