<div class="mainBLoc">
    <div class="row">
        <div class="col-md-6">
            <div class="cenerBloc">
                <div class="blockDetail" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right">
                    <h2>Q-Chatbot </h2>
                    <div class="separator"></div>
                    <div class="descrp">Q-Chatbot is an intelligent chatbot in hybrid, multilingual and
                        multi-channel mode that adapts to various use cases, such as
                        banking, insurance and e-commerce. It automates customer service
                        interactions for companies in all sectors, enhancing the customer
                        experience and reducing costs.</div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
                <div #animation class="ani"></div>
            </div>
        </div>
    </div>
</div>


<div class="can-be-used-background">
    <div class="row mb-4">
        <div class="col-md-12">
            <div class="blocText">
                <div class="desc">
                   Q-Chatbot provides instant answers to customers' and prospects' questions, requests and complaints, in text or audio mode via the various channels.
                </div>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="col-md-3 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>
           <div class="text-texte">
            <span class="test-test">Continuous learning</span>
           <span class="text-valide">Q-Chatbot is constantly improving thanks to automatic learning.</span>
           </div>
        </div>
        <div class="col-md-3 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>
            <div class="text-texte">
                <span class="test-test">Measuring customer satisfaction </span>
               <span class="text-valide">
                    Q-Chatbot measures customer satisfaction by collecting feedback and analyzing interactions.
               </span>
               </div>
            </div>
        <div class="col-md-3 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>

             <div class="text-texte">
                <span class="test-test">Change management</span>
               <span class="text-valide">
                    Q-Chatbot provides business KPIs and customer experience monitoring via a Dashboard.
               </span>
               </div>
            </div>
        <div class="col-md-3 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>
            <div class="text-texte">
                <span class="test-test">Chatbot setup</span>
               <span class="text-valide">Q-Chatbot features an intuitive backoffice that gives companies
                the flexibility to enrich content, set parameters and track performance.</span>
               </div>
            </div>
    </div>
</div>
<div class="benifits-background">
   <div class="row">
    <div class="col-md-6">
        <div class="d-flex-column "  data-aos-once="true"  data-aos-duration="1200" data-aos="fade-left">
            <h2 class="Benefits-title">Benefits</h2>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img src="assets/Q-Chatbot/Service.svg"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="test-text">
                        <span class="list-text"> Improved customer experience</span>
                        <span class="sous-text">Q-Chatbot can improve the customer
                            experience by offering 24/7 customer service, responding quickly to
                            questions and requests, and personalizing interactions.</span>
                    </div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img src="assets/Q-Chatbot/Dollar coin.svg"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="test-text">
                        <span class="list-text"> Reduce costs</span>
                        <span class="sous-text">Q-Chatbot can reduce customer service costs by
                            automating interactions and reducing the number of calls and live
                            chats.</span>
                    </div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img src="assets/Q-Chatbot/Service-coeur.svg"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="test-text">
                        <span class="list-text"> Increase customer satisfaction</span>
                        <span class="sous-text">Q-Chatbot can increase customer
                            satisfaction by offering a more efficient and personalized customer
                            service.</span>
                    </div>
                </div>
               </div>
                <div class="list">
                    <div class="list-item">
                        <div class="icone">
                            <img src="assets/Q-Chatbot/Vector.svg"/>
                            <nz-divider nzType="verticale"></nz-divider>
                        </div>
                        <div class="test-text">
                            <span class="list-text"> Improved employee productivity</span>
                        <span class="sous-text">Q-Chatbot can improve employee
                            productivity by freeing them from repetitive customer service tasks.</span>
                        </div>
                    </div>
               </div>
        </div>
    </div>
    <div class="col-md-6">
        <img src="assets/Q-Chatbot/macbook-pro-16-inch-space-black-front@2x.svg" data-aos-once="true" data-aos-duration="1200" data-aos="fade-up"
        alt="chatbot" style="height: 360px;">
        <img class="phone" src="assets/Q-Chatbot/iphone-11-pro-mockup-1@2x.svg" data-aos-once="true" data-aos-duration="1200" data-aos="fade-up"
        alt="q-chatbot" style="height: 300px;">
    </div>
   </div>
</div>
