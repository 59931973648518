<div class="mainBLoc">
    <div class="row">
        <div class="col-md-6">
            <div class="cenerBloc">
                <div class="blockDetail" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right">
                    <h2>PFM</h2>
                    <div class="separator"></div>
                    <div class="descrp"> Personal financial management software helps users manage their money,
                        categorize transactions, add accounts from multiple institutions into a single view, visualize
                        spending trends, budgets and net worth.

                        Gaining an understanding of your financial situation in order to make the most of your assets in
                        day-to-day life and in planning for your future.</div>
                </div>
            </div>

        </div>
        <div class="col-md-6">
            <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
                    <img loading="lazy"    src="assets/pfm/1.png" alt="regtech">
            </div>
        </div>
    </div>
</div>
<div class="can-be-used-background">
    <div class="row">
        <div class="col-md-12">
            <div class="blocText">
            </div>
        </div>
    </div>
    <div class="tobe-used">
        <div class="row">
            <div class="col-md-4">
                <div class="centerElemnt">
                    <div class="itemUsed"> <i nz-icon nzType="check-circle" nzTheme="outline"></i> Optimize your
                        expenses </div>
                    <div class="itemUsed "> <i nz-icon nzType="check-circle" nzTheme="outline"></i>Get you in control
                          of your finances </div>
                </div>
            </div>
            <div class="col-md-4">
                    <img loading="lazy"  class="imClasss"  src="assets/pfm/2.png" alt="pfm">
            </div>
            <div class="col-md-4">
                <div class="centerElemnt">
                    <div class="itemUsed revers"> <i nz-icon nzType="check-circle" nzTheme="outline"></i> Manage
                        your savings</div>
                    <div class="itemUsed revers"> <i nz-icon nzType="check-circle" nzTheme="outline"></i> Seize
                        opportunities </div>
                </div>
            </div>
        </div>
    </div>
</div>
