import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';

@Component({
  selector: 'app-open-banking',
  templateUrl: './open-banking.component.html',
  styleUrls: ['./open-banking.component.css']
})
export class OpenBankingComponent implements OnInit {

  constructor(private loadingService: LoadingService) { }

  ngOnInit() {
    setTimeout(() => {
      this.loadingService.stop();
   }, 1000);
  }



}
