<div class="mainBLoc">
    <div class="row">
        <div class="col-md-6">
            <div class="cenerBloc">
                <div class="blockDetail" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right">
                    <h2>Peaqock Fraud & risk management solution</h2>
                    <div class="separator"></div>
                    <div class="subTitle">Our solution helps you to protect your customer’s assets and your brand.</div>
                    <div class="descrp">
                        Using technology-enabled techniques fraudulent attacks are
                        becoming more sophisticated and their consequences are becoming more severe. Banks need to be able to accurately identify fraud in
                        real-time, and before it occurs.

                        Our solution uses a combination of sophisticated AI-based
                        algorithms and expert business rules to block suspicious
                        transactions based on real-time behavioral analysis.

                        </div>
                </div>
            </div>

        </div>
        <div class="col-md-6">
            <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
                    <img loading="lazy"    src="assets/fraud/1.png" alt="fraud">
            </div>
        </div>
    </div>
</div>
<div class="can-be-used-background">
    <div class="row mb-4">
        <div class="col-md-12">
            <div class="blocText">
                <div class="desc">Our solution is real time and batch based Transaction and Behavioral Monitoring for fraudulent transactions and
                    precursors to fraud activity</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Reporting and dashboard </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Alert dispositioning </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Advanced analytics engine</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Entity link visualizer</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Business Scenarios/Rules</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> AI/ML Models</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Statistical Profiling, and Extended Lists, Reference Lists</div>

    </div>

</div>
