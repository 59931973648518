import {Component, HostListener, OnInit} from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';
import { SharedService } from 'src/app/commons/services/shared.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-careers',
  templateUrl: './data-science.component.html',
  styleUrls: ['./data-science.component.css']
})
export class CareersComponent implements OnInit {
  frontEnd:boolean = true;
  manager:boolean = false;
  designer: boolean = false
  frontTrainee: boolean = false;
  backTrainee: boolean = false;
  dataScienceTrainee = false;
  dataToShow = 'sm';
  constructor(private loadingService:LoadingService,private sharedService :SharedService, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.data.subscribe(data => {
   this.sharedService.nextdataRoute(data);
  }) }

  @HostListener('window:load', [])
  stopLoading() {
    this.loadingService.stop();
  }
  ngOnInit() {
   //  setTimeout(() => {
   //    this.loadingService.stop();
   // }, 5000);
  }

  frontEndCheck() {
    this.frontEnd = true;
    this.manager = false;
    this.designer = false
    this.frontTrainee = false;
    this.backTrainee = false;
    this.dataScienceTrainee = false;
  }

  managerCheck() {
    this.frontEnd = false;
    this.manager = true;
    this.designer = false
    this.frontTrainee = false;
    this.backTrainee = false;
    this.dataScienceTrainee = false;
  }

  designerCheck() {
    this.frontEnd = false;
    this.manager = false;
    this.designer = true
    this.frontTrainee = false;
    this.backTrainee = false;
    this.dataScienceTrainee = false;
  }

  frontTraineeCheck() {
    this.frontEnd = false;
    this.manager = false;
    this.designer = false
    this.frontTrainee = true;
    this.backTrainee = false
    this.dataScienceTrainee = false;
  }

  backTraineeCheck() {
    this.frontEnd = false;
    this.manager = false;
    this.designer = false
    this.frontTrainee = false;
    this.backTrainee = true
    this.dataScienceTrainee = false;
  }

  dataScienceCheck() {
    this.frontEnd = false;
    this.manager = false;
    this.designer = false
    this.frontTrainee = false;
    this.backTrainee = false
    this.dataScienceTrainee = true;
  }

}
