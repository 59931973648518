<div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" class="imgForData1">
  <img loading="lazy"  src="assets/joinus/manager/projectmanager.png" alt=""/>
</div>
<div class="weare">Position Summary</div>
<div class="weare-text">
  We are seeking a skilled Data Engineer with experience in Python, SQL, Flask, FAST API, relational and non-relational databases, Linux, Cloud infrastructure, and Docker. The ideal candidate will be responsible for designing, developing, and maintaining data infrastructure and architecture to support the organization's data needs.</div>




<div class="mt-4 mb-4 row">
  <div class="col-md-4">
    <div class="imgForData respo-image"><img loading="lazy"  src="assets/joinus/3.png" alt=""/></div>
  </div>
  <div class="col-md-8 ">
    <div class="titleData">Primary Responsibilities</div>
    <div class="ssseparateurProduct"></div>
    <div class="sousTitleData">Technical Skills</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Design, build and maintain data pipelines and infrastructure for both batch and real-time processing</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Develop ETL processes to move data from various sources to data storage systems</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Design and implement data models and schemas for structured and unstructured data</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Manage and maintain large-scale databases, both relational and non-relational</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Optimize and fine-tune database performance, including indexing, query optimization, and database tuning</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Develop and maintain APIs to expose data to other systems or applications</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Monitor and troubleshoot data infrastructure and processes to ensure high availability and reliability</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Collaborate with data analysts, data scientists, and other stakeholders to understand data needs and requirements</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Stay up-to-date with emerging technologies and trends in data engineering and provide recommendations for improving existing data infrastructure</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Web scraping (Selenium, Beautiful Soup…)</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Machine learning models deployment</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Data validation and data cleaning</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Advanced SQL queries (e.g., fraud detection, unusual operations…)</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Data Warehouse design</span>
    </div>

    <div class="sousTitleData sousTitleData2">Soft Skills</div>

    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <!--      <span> More than 7 years of overall experience and minimum of 2 years of IT experience. </span>-->
      <span> Excellent problem-solving and analytical skills. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Strong communication and collaboration skills. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Ability to work independently as well as part of a team. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Business acumen. </span>
    </div>
  </div>

</div>
<div class="row">
  <div class="col-md-6 mt-4 mb-4">
    <div class="titleData">Job Requirements</div>
    <div class="ssseparateurProduct"></div>
    <div class="sousTitleData sousTitleData2">Education</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Bachelor’s or master’s degree in Computer Science or a related field.  </span>
    </div>

    <div class="sousTitleData sousTitleData2">Experience</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>1 to 4 years of experience in data engineering.  </span>
    </div>



    <div class="sousTitleData sousTitleData2">Knowledge and Skills</div>

    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Experience with Linux, cloud infrastructure (AWS, Azure, GCP), and containerization technologies (Docker)</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Familiarity with data modeling and schema design for structured and unstructured data</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Experience with both both relational and non-relational databases, such as MySQL, PostgreSQL, MongoDB, and Cassandra </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Programming languages: Python, Flask, Fast API</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Experience with Docker and Linux as containerization technologies</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Knowledge of cloud based infrastructures</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Experience working with HDFS, Spark, Hbase, Hive, Kafka</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Intermediate English</span>
    </div>
  </div>

  <div class="col-md-6 mt-4 mb-4">
    <!--    <div class="imgForData qualif-image"><img loading="lazy"  src="assets/joinus/4.png" alt=""/></div>-->

    <div class="titleData">Primary Contacts</div>
    <div class="ssseparateurProduct"></div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Reports to CDO</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> IT team and staff. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>CEO. </span>
    </div>
  </div>

<!--  <div class="col-md-12 mt-4 mb-4">-->
<!--    <div class="weare-text">-->
<!--      We offer a fast-paced, collaborative work environment and the opportunity to work on challenging and meaningful projects. If you are a self-starter with a passion for technology and a desire to make a real impact, we would love to hear from you!-->
<!--    </div>-->
<!--  </div>-->
</div>

