<div class="mainBLoc">
    <div class="row">
        <div class="col-md-6">
            <div class="cenerBloc">
                <div class="blockDetail" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right">
                    <h2>Dynamic  Dashboard </h2>
                    <div class="separator"></div>
                    <div class="descrp">Built to be 360° configurable, our Dynamic Dashboard is able to process all types of data coming from various sources (Databases, Excels, PDFs, Images, Manuals…) in order to execute real-time analytics and visualize needed metrics. </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
                <img loading="lazy"  class="" id="gif-2"
                onmouseover="document.getElementById('gif-2').src='assets/GIF_capabilities/dynamicdashbord.gif'"
                data-mode="video"  src="assets/GIF_capabilities/dynamicdashbord.gif" alt="Dynamic  ashboard">
            </div>
        </div>
    </div>
</div>
<div class="can-be-used-background">
    <div class="row mb-4">
        <div class="col-md-12">
            <div class="blocText">
                <div class="desc">Our solution helps banks prevent their business being used to launder the proceeds of financial crime or associated with corrupt practices, through a range of intelligent and flexible modules that seamlessly embed into any core system to ensure the highest detection rates at the lowest costs</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Overviex KPIs &   Visualise graphic   repotings</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Management &   Monitoring </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Arrange several   dashboards with   several data areas</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Notifications</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Download dashboards in different formats (HTML, PDF, Image)</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br> Sharing features  and authorizations</div>
    </div>
</div>
<div class="benifits-background">
   <div class="row">
    <div class="col-md-6">
        <div class="d-flex-column "  data-aos-once="true" data-aos-duration="1200" data-aos="fade-left">
            <h2 class="Benefits-title">Benefits</h2>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">React faster on issues that need your attention</div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">Intuitive dashboards provide simple and efficient
                        access to relevant information</div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">Dashboards for management decisions and operational needs</div>
                </div>
           </div>
        </div>
    </div>
    <div class="col-md-6">
        <img loading="lazy"  src="assets/home/64.png"  data-aos-once="true" data-aos-duration="1200" data-aos="fade-up"
        alt="Dynamic ashboard" style="height: 360px;">
    </div>
   </div>
</div>
