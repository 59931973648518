<div class="mainBLoc">
    <div class="row">
        <div class="col-md-6">
            <div class="cenerBloc">
                <div class="blockDetail"  data-aos-once="true" data-aos-duration="1200" data-aos="fade-right">
                    <h2>Regtech</h2>
                    <div class="separator"></div>
                    <div class="descrp"> Our solution offers complete solutions to facilitate the sharing of information through the structuring of exchanges between different departments, entities and organizations as well as the creation of dynamic flows of information feedback.</div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div   data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
                <img loading="lazy"  class="" id="gif-2"
                onmouseover="document.getElementById('gif-2').src='assets/GIF_capabilities/regtech.gif'"
                data-mode="video"  src="assets/GIF_capabilities/regtech.gif" alt="regtech">
            </div>
        </div>
    </div>
</div>
<div class="can-be-used-background">
    <div class="row mb-4">
        <div class="col-md-12">
            <div class="blocText">
             </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Data collection</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Data cleaning</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Checking data consistency and completeness</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Data processing workflow management</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Time management</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Management of users / groups / authorizations</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Notifications</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Chat</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Comments</div>
    </div>
</div>
<div class="benifits-background">
   <div class="row">
    <div class="col-md-6">
        <div class="d-flex-column "  data-aos-once="true" data-aos-duration="1200" data-aos="fade-left">
            <h2 class="Benefits-title">Benefits</h2>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">Increase operational efficiencies</div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">Reduce administrative costs </div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">Decrease the risk of violations</div>
                </div>
           </div>
        </div>
    </div>
    <div class="col-md-6">
        <img loading="lazy"  src="assets/Interfaces_Capabilities/RegTechInterface.png"  data-aos-once="true"  data-aos-duration="1200" data-aos="fade-up"
        alt="Dynamic ashboard" style="width: 100%;">
    </div>
   </div>
</div>
