/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./newsletter.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../components/card/card.component.ngfactory";
import * as i4 from "../../../components/card/card.component";
import * as i5 from "@angular/router";
import * as i6 from "./newsletter.component";
import * as i7 from "../../../commons/services/loading.service";
import * as i8 from "./blogs.service";
var styles_NewsletterComponent = [i0.styles];
var RenderType_NewsletterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewsletterComponent, data: {} });
export { RenderType_NewsletterComponent as RenderType_NewsletterComponent };
function View_NewsletterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "descriprtion"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "images-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["alt", "image"], ["class", "image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "desc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "text-description"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "button-contacts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "color-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationContact() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Contact Us "])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "img", [["alt", "send"], ["class", "img-color"], ["src", "/assets/images/sendwhite.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.date); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.image, ""); _ck(_v, 9, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.description; _ck(_v, 13, 0, currVal_3); var currVal_4 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.description); _ck(_v, 14, 0, currVal_4); }); }
function View_NewsletterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsletterComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.id == _co.blogId); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NewsletterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsletterComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "related"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Related articles"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "relared-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-card", [], null, [[null, "navigateTo"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("navigateTo" === en)) {
        var pd_0 = (_co.onNavigation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CardComponent_0, i3.RenderType_CardComponent)), i1.ɵdid(9, 114688, null, 0, i4.CardComponent, [i5.Router], { listNews: [0, "listNews"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Articles; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.listNews; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_NewsletterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-newsletter", [], null, null, null, View_NewsletterComponent_0, RenderType_NewsletterComponent)), i1.ɵdid(1, 114688, null, 0, i6.NewsletterComponent, [i7.LoadingService, i5.ActivatedRoute, i8.BlogsService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsletterComponentNgFactory = i1.ɵccf("app-newsletter", i6.NewsletterComponent, View_NewsletterComponent_Host_0, {}, {}, []);
export { NewsletterComponentNgFactory as NewsletterComponentNgFactory };
