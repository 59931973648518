<div class="">
  <div class="headerProduct">
    <div class="titleProduct1">
      Bringing together knowledge and experience accumulated since our founding, we pursue research and development that generates next-generation value.
    </div>
    <div class="listProducts">
      <div (click)="setActiveComponent('Q-Chatbot')" class="itemProject" [ngClass]="{'activeItem': activeComponent === 'Q-Chatbot'}">
        <i nz-icon nzType="arrow-right" nzTheme="outline"></i> Q-Chatbot
      </div>
      <div (click)="setActiveComponent('fraud')" class="itemProject" [ngClass]="{'activeItem': activeComponent === 'fraud'}">
        <i nz-icon nzType="arrow-right" nzTheme="outline"></i> Fraud Detection
      </div>
      <div (click)="setActiveComponent('aml')" class="itemProject" [ngClass]="{'activeItem': activeComponent === 'aml'}">
        <i nz-icon nzType="arrow-right" nzTheme="outline"></i> AML
      </div>
      <div (click)="setActiveComponent('pfm')" class="itemProject" [ngClass]="{'activeItem': activeComponent === 'pfm'}">
        <i nz-icon nzType="arrow-right" nzTheme="outline"></i> PFM
      </div>
      <div (click)="setActiveComponent('dynamicDashboard')" class="itemProject" [ngClass]="{'activeItem': activeComponent === 'dynamicDashboard'}">
        <i nz-icon nzType="arrow-right" nzTheme="outline"></i> Dynamic Dashboard
      </div>
      <div (click)="setActiveComponent('regtech')" class="itemProject" [ngClass]="{'activeItem': activeComponent === 'regtech'}">
        <i nz-icon nzType="arrow-right" nzTheme="outline"></i> AI for insurers
      </div>
      <div (click)="setActiveComponent('invest')" class="itemProject" [ngClass]="{'activeItem': activeComponent === 'invest'}">
        <i nz-icon nzType="arrow-right" nzTheme="outline"></i> P-Invest
      </div>
      <div (click)="setActiveComponent('funds')" class="itemProject" [ngClass]="{'activeItem': activeComponent === 'funds'}">
        <i nz-icon nzType="arrow-right" nzTheme="outline"></i> Funds
      </div>
    </div>
  </div>
  <div class="mainContent">
    <div @fadeAnimation *ngIf="activeComponent === 'aml'" class="containerProject">
      <app-aml></app-aml>
    </div>
    <div @fadeAnimation *ngIf="activeComponent === 'api'" class="containerProject">
      <app-api-payment></app-api-payment>
    </div>
    <div @fadeAnimation *ngIf="activeComponent === 'Q-Chatbot'" class="containerProject">
      <app-q-chatbot></app-q-chatbot>
    </div>
    <div @fadeAnimation *ngIf="activeComponent === 'fraud'" class="containerProject">
      <app-fraud></app-fraud>
    </div>
    <div @fadeAnimation *ngIf="activeComponent === 'dynamicDashboard'" class="containerProject">
      <app-dynamic-dashboard></app-dynamic-dashboard>
    </div>
    <div @fadeAnimation *ngIf="activeComponent === 'pfm'" class="containerProject">
      <app-pfm></app-pfm>
    </div>
    <div @fadeAnimation *ngIf="activeComponent === 'dataone'" class="containerProject">
      <app-data-one></app-data-one>
    </div>
    <div @fadeAnimation *ngIf="activeComponent === 'blockChain'" class="containerProject">
      <app-block-chain></app-block-chain>
    </div>
    <div @fadeAnimation *ngIf="activeComponent === 'regtech'" class="containerProject">
      <app-regtech></app-regtech>
    </div>
    <div @fadeAnimation *ngIf="activeComponent === 'invest'" class="containerProject">
      <app-invest></app-invest>
    </div>
    <div @fadeAnimation *ngIf="activeComponent === 'funds'" class="containerProject">
      <app-funds></app-funds>
    </div>
  </div>
</div>
