<div class="">
    <div class="headerBLock">
        <div class="headerBg">
            <div data-aos-once="true" data-aos-duration="1300" data-aos="fade-right" class="title"> Asset Management
            </div>
        </div>
        <div data-aos-once="true" data-aos-duration="1300" data-aos="fade-right" class="blockInfo">
            <div class="blockinfoTxt ">
                <div class="cstmWidth">
                    <div class="desp">Digitalization offers opportunities to improve performance and customer
                        experience. Emerging technologies, such as artificial intelligence (AI) and the Internet of
                        Things (IoT) offer deeper data insights and greater speed and efficiency of services to meet
                        changing customer needs.</div>
                    <div class="separateur"></div>
                    <div class="titleDesc">We help our clients identify new opporunities</div>
                    <div class="desp">We help our clients identify new opporunities and develop viable innovations. We
                        offer an adaptive strategy in order to make a successful transformation.</div>
                </div>
            </div>
            <div  class="infoImg">
                <img loading="lazy"  data-aos-once="true" data-aos-duration="1300" data-aos="fade-left" src="assets/industries/VectorSmartObject.png"  alt="VectorSmartObject" />
            </div>
        </div>
    </div>

    <div class="blockSLid bgBloc">
        <div class="row">
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="col-md-4">
                <div class="blocImg"><img loading="lazy"  src="assets/industries/layer1.png" alt="layer1"/></div>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                <div class="flexCenter">
                    <div class="blocData">
                        <div class="title">Discover our Capabilities</div>
                        <div class="separateur"></div>
                        <div class="descp"><span>Our solution</span>  helps you <span>streamline reporting</span>  between the headquarters of a group and its subsidiaries. Among the different components managed by this solution, we find: the management of administrative statements, the management of legal papers, cash management and Shareholding ...</div>
                    </div>
                </div>

            </div>
        </div>
        <!-- <nz-carousel [nzAutoPlay]="false"   [nzEffect]="effect">
            <div nz-carousel-content >
                <div class="row">
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="col-md-4">
                        <div class="blocImg"><img loading="lazy"  src="assets/industries/layer1.png" alt=""/></div>
                    </div>
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                        <div class="flexCenter">
                            <div class="blocData">
                                <div class="title">Discover our Capabilities</div>
                                <div class="separateur"></div>
                                <div class="descp"><span>Our solution</span>  helps you <span>streamline reporting</span>  between the headquarters of a group and its subsidiaries. Among the different components managed by this solution, we find: the management of administrative statements, the management of legal papers, cash management and Shareholding ...</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
               <div nz-carousel-content >
                <div class="row">
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="col-md-4">
                        <div class="blocImg"><img loading="lazy"  src="assets/industries/layer1.png" alt=""/></div>
                    </div>
                    <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" class="col-md-8">
                        <div class="flexCenter">
                            <div class="blocData">
                                <div class="title">Discover our Capabilities</div>
                                <div class="separateur"></div>
                                <div class="descp"><span>Our solution</span>  helps you <span>streamline reporting</span>  between the headquarters of a group and its subsidiaries. Among the different components managed by this solution, we find: the management of administrative statements, the management of legal papers, cash management and Shareholding ...</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </nz-carousel>
        -->
    </div>

    <div class="blocInfoDetail">
        <div class="title">Solution Features</div>
        <div class="row">
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left"  class="col-md-3">
                <div class="">
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/7.png" alt="Reports Generation"/>
                        <div class="txt">Reports Generation </div>
                    </div>
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/8.png" alt="Simple data processing  workflow for all stakeholders ">
                        <div class="txt">Simple data processing  workflow for all stakeholders </div>
                    </div>
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/6.png" alt="KPIs">
                        <div class="txt">KPIs </div>
                    </div>
                    <div class="blocDetail1">
                        <img loading="lazy"  src="assets/industries/1.png" alt="Data consistency check">
                        <div class="txt">Data consistency check </div>
                    </div>
                </div>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up"  class="col-md-6">
                <img loading="lazy"  class="centreIamge" src="assets/industries/layer2.png" alt=""/>
            </div>
            <div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"  class="col-md-3">
                <div class="blocDetail1 rightTxt">
                    <img loading="lazy"  src="assets/industries/5.png" alt="Standardization of exchanged data">
                    <div class="txt">Standardization of exchanged data </div>
                </div>
                <div class="blocDetail1 rightTxt">
                    <img loading="lazy"  src="assets/industries/3.png" alt="Management of delivery time and frequencie">
                    <div class="txt"> Management of delivery time and frequencie</div>
                </div>
                <div class="blocDetail1 rightTxt">
                    <img loading="lazy"  src="assets/industries/4.png" alt="User management & Authorizations">
                    <div class="txt">User management & Authorizations </div>
                </div>
                <div class="blocDetail1 rightTxt">
                    <img loading="lazy"  src="assets/industries/2.png" alt="Notifications">
                    <div class="txt">Notifications</div>
                </div>
            </div>
        </div>
    </div>
</div>
