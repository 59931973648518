import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { routeAnimations } from './commons/animations/route.animations';
import { filter } from 'rxjs/operators';
import * as AOS from 'aos';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  title = 'peaqock financials';
  public className = '';

  constructor(private location: Location, private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.trackPageView(event.urlAfterRedirects);
      this.updateClassName();
    });
  }

  ngOnInit() {
    AOS.init({ mirror: true, once: false });
  }

  private trackPageView(url: string): void {
    gtag('event', 'page_view', { page_path: url });
  }

  private updateClassName(): void {
    const currentRoute = this.location.path();
    this.className = (currentRoute && currentRoute !== '/') ? 'marginBottom' : '';
  }
}
