import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front-trainee',
  templateUrl: './front-trainee.component.html',
  styleUrls: ['./front-trainee.component.css']
})
export class FrontTraineeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
