import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regtech',
  templateUrl: './regtech.component.html',
  styleUrls: ['./regtech.component.css']
})
export class RegtechComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
