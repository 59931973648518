import {Component, HostListener, OnInit} from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  forbes = true;
  labelfinanceinnovation = false;
  financialSector = false;
  rd = false;

  constructor(private loadingService: LoadingService) {
  }

  @HostListener('window:load', [])
  stopLoading() {
    this.loadingService.stop();
  }

  ngOnInit() {

  }

  showForbes() {
    this.forbes = true;
    this.labelfinanceinnovation = false;
    this.financialSector = false;
    this.rd = false;
  }

  showLabelfinanceinnovation() {
    this.forbes = false;
    this.labelfinanceinnovation = true;
    this.financialSector = false;
    this.rd = false;
  }

  showFinancialSector() {
    this.forbes = false;
    this.labelfinanceinnovation = false;
    this.financialSector = true;
    this.rd = false;
  }

  showrd() {
    this.forbes = false;
    this.labelfinanceinnovation = false;
    this.financialSector = false;
    this.rd = true;
  }

}
