import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/commons/services/loading.service';

@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.css']
})
export class PrivacyStatementComponent implements OnInit {

  clicked: boolean = false;
  clicked2:boolean = false;
  clicked3:boolean = false;
  clicked4:boolean = false;
  clicked5:boolean = false;
  clicked6:boolean = false;
  clicked7:boolean = false;
  clicked8:boolean = false;
  clicked9:boolean = false;
  constructor(private loadingService:LoadingService ) { }

  ngOnInit() {
    setTimeout(() => {
      this.loadingService.stop();
   }, 5000);
  }

}
