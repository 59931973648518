<div class="container">
  <ng-container *ngFor="let art of Articles">
    <ng-container *ngIf="art.id == blogId">

    <div class="title">
      {{art.title}}
     </div>
     <br>
     <div class="date">
       <span>{{art?.date}}</span>
     </div>
     <div class="descriprtion">
       <div class="images-width">
         <img class="image" src="{{art.image}}" alt="image" />
       </div>
       <br /><br />
       <div class="desc">
           
         <p class="text-description" [innerHTML]="art.description">
         {{art?.description}}
         </p>
       </div>
     </div>
     <div class="button-contacts">
       <button class="color-button"  (click)="navigationContact()">
         Contact Us <img class="img-color" src="/assets/images/sendwhite.svg" alt="send">
       </button>
     </div>
    </ng-container>
  </ng-container>
  
</div>

<div>
    <div class="related">
        <p>Related articles</p>
    </div>

    <div class="relared-card">
      <app-card [listNews]="listNews" (navigateTo)="onNavigation($event)"></app-card>
    </div>
</div>


