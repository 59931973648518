<div class="mainBLoc">
    <div class="row">
        <div class="col-md-6">
            <div class="cenerBloc">
                <div class="blockDetail" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right">
                    <h2>Payment API</h2>
                    <div class="separator"></div>
                    <div class="descrp"> <span>Peaqock payment services</span>  go beyond open banking compliance and are suitable for direct or indirect monetization in an API marketplace model.
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-6">
            <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
                <!-- <img loading="lazy"  class="" id="gif-2"
                    onmouseover="document.getElementById('gif-2').src='assets/GIF_capabilities/regtech.gif'"
                    data-mode="video" src="assets/GIF_capabilities/regtech.gif" alt="regtech"> -->
                    <img loading="lazy"    src="assets/API_MOBILE/1.png" alt="regtech">
            </div>
        </div>
    </div>


</div>
<div class="can-be-used-background">
    <div class="row">
        <div class="col-md-12">
            <div class="blocText">
<!--                <h2>It can be used to</h2>-->
<!--                <div class="separator_used"></div>-->
            </div>

        </div>
    </div>
    <div class="tobe-used">

        <div class="row">
            <div class="col-md-4">
                <div class="centerElemnt">
                    <div class="itemUsed"> <i nz-icon nzType="check-circle" nzTheme="outline"></i>Instant Payments </div>
                    <div class="itemUsed"> <i nz-icon nzType="check-circle" nzTheme="outline"></i>Vents Between Wallets</div>
                    <div class="itemUsed"> <i nz-icon nzType="check-circle" nzTheme="outline"></i> Bill Payments</div>
                    <div class="itemUsed"> <i nz-icon nzType="check-circle" nzTheme="outline"></i> Split bill</div>
                </div>

            </div>
            <div class="col-md-4">
                <!-- <img loading="lazy"  class="imClasss" id="gif-2"
                    onmouseover="document.getElementById('gif-2').src='assets/GIF_capabilities/regtech.gif'"
                    data-mode="video" src="assets/GIF_capabilities/regtech.gif" alt="regtech"> -->
                    <img loading="lazy"  class="imClasss"  src="assets/API_MOBILE/2.png" alt="pfm">
            </div>
            <div class="col-md-4">
                <div class="centerElemnt">
                    <div class="itemUsed revers"> <i nz-icon nzType="check-circle" nzTheme="outline"></i> <div class="">Merchant Payments via Contactless Technologies
                        <br> <span>(NFC or QR code Scanning)</span></div> </div>
                    <div class="itemUsed revers"> <i nz-icon nzType="check-circle" nzTheme="outline"></i>Security </div>
                    <div class="itemUsed revers"> <i nz-icon nzType="check-circle" nzTheme="outline"></i>Rewards, Coupons, Discounts</div>
                    <div class="itemUsed revers"> <i nz-icon nzType="check-circle" nzTheme="outline"></i>Analytical Dashboards and Diagrams</div>


                </div>

            </div>
        </div>

    </div>


</div>
