import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import lottie from 'lottie-web';

@Component({
  selector: 'app-q-chatbot',
  templateUrl: './q-chatbot.component.html',
  styleUrls: ['./q-chatbot.component.css']
})
export class QChatbotComponent implements OnInit {
  @ViewChild('animation', {static: true}) lottieContainer!: ElementRef;
  constructor() { }

  ngOnInit() {
    this.loadLottieAnimation();
  }
  private loadLottieAnimation() {
    lottie.loadAnimation({
      container: this.lottieContainer.nativeElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'assets/Q-Chatbot/Animation - 1716391680572.json',
      rendererSettings: {
        progressiveLoad: false,
      },
    });
  }
}
