<cookie-law position="top" name="ng4topCookieLaw" expiration="1">
  Peaqock uses cookies to improve functionality and performance of this site. More information can be found in our Privacy Policy.
   By continuing to browse this site, you consent to the use of cookies.
</cookie-law>
<!--
<cookie-law #cookieLaw
            name="ng4CookieLaw"
            (isSeen)="cookieLawSeen = $event"
            learnMore="/false"
            target="_blank"></cookie-law>  -->
<article class="row column">


  <div class="column">
    <div class="section-start">
      <header class="hero hero--hub large-12 column">
        <div class="hero__text-content">
          <div class="hero__top-wrap row">
            <div class="hero__title-wrap medium-8 large-6 column end">
              <div class="hero__title">
                <p class="hero__tag">About Peaqock</p>
                 <h1 class="showDesktopTitle">Privacy Policy</h1>
                 <h1 class="showMobileTitle hero__title">Privacy Policy</h1>
              </div>
              <div class="hero__description rte__heading">
                <p>
                  Peaqock knows that your privacy is important to you and takes
                  user privacy very seriously. We are committed to protecting
                  your personal data in accordance with this Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="hero__image">
          <img loading="lazy"  src="./assets/bg/bgPolicy.png" alt="bgPolicy"/>
        </div>
      </header>
    </div>

    <section
      class="section overview row"

    >
      <div class="column">
        <div class="section__midline-heading">
          <h3 class="showDesktopTitle">Privacy Policy</h3>

          <h3 class="showMobileTitle">Privacy Policy</h3>
        </div>
      </div>
      <div class="medium-10 large-8 column overview--centered medium-centered">

        <div

          class="rte__heading"
        >
          <!--  <p style="text-align: left"><i>Last updated on 25 May 2018</i></p> -->
          <p style="text-align: left">
            This Privacy Policy applies to the website provided by Peaqock, a
            company headquartered in Casablanca.
          </p>
          <p style="text-align: left">
            Peaqock knows that your privacy is important to you and takes user
            privacy very seriously. We are committed to protecting your personal
            data (i.e. any information you provide to us from which you can be
            identified) in accordance with this Privacy Policy.
          </p>

          <p style="text-align: left">
            Peaqock reserves the right, at its sole discretion, to alter and
            update this Privacy Policy from time to time. We therefore invite
            you to review the current version of the Privacy Policy each time
            you return to our Sites.
          </p>
        </div>
      </div>
    </section>
    <section
      class="accordion--list medium-10 large-8 column overview--centered medium-centered">
      <ul
        class="accordion"
          >

        <li
        [ngClass]="clicked ? 'accordion-item is-active' : 'accordion-item'"
        >

          <a
           (click)="clicked = !clicked"
            class="accordion-title"
            >Information we collect and how we use it</a>

   <!--        <div class="accordion-title">tt</div> -->
          <div
            class="accordion-content"

          >
            <div class="rte">
              <p
                style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                "
              >
                <strong
                  >Peaqock might collect personal data from you such as your
                  name, contact details, occupation, company or university
                  affiliation, industry, region/country, relationship to
                  Peaqock, reason for contacting Peaqock, topics you are
                  interested in and any other information you choose to share.
                  By browsing our Sites or sending us information, you
                  explicitly consent to Peaqock processing your personal data in
                  accordance with this Privacy Policy. Peaqock might use your
                  personal data to fulfil your request, send you marketing
                  communications or process your job application..</strong
                >&nbsp;
              </p>
              <hr />
              <p style="text-align: left">
                Peaqock collects personal data from you when you request
                information from Peaqock and/or supply, information through the
                Sites, such as when you fill out a subscription form or
                consulting expertise inquiry, opt in to receive emails from
                Peaqock, agree to participate in surveys or apply for a role at
                Peaqock.
              </p>
              <p style="text-align: left">
                Personal data we collect might include your name, e-mail
                address, title, occupation, company or university affiliation,
                industry, region/country, relationship to Bain, reason for
                contacting Bain, topics you are interested in, any other
                background information provided with an application and any
                other information contained in messages you send us.
              </p>
              <p style="text-align: left">
                By browsing our Sites and submitting this information to us, you
                explicitly agree to Bain using your personal data for the
                purposes and in the manner described in this Privacy Policy.
                Please do not send us any information you do not wish Bain to
                use.
              </p>
              <p style="text-align: left">
                Peaqock will use your personal data to fulfil your request,
                provide you with the information you requested and/or send you
                marketing communications. If you have applied for a job at
                Peaqock, your data will be processed for the purposes of
                evaluating and processing your application and candidacy for the
                position to which you have applied. We may also use your
                information to analyze and improve our recruiting and onboarding
                processes as well as to communicate with you about Peaqock
                events and to send you publications that we think may be of
                interest to you. More detail can be found in the Job Applicants
                section.
              </p>
              <p style="text-align: left">
                Peaqock may collect personal data and may disclose such
                information to third party service providers in aggregate for
                marketing and promotional purposes. However, in these
                situations, we do not disclose to these entities any information
                that could be used to personally identify you.
              </p>

              <p style="text-align: left">
                You do not have to provide us with any personal data.&nbsp;
                However, if you do not, we may be unable to handle your request
                or to provide you with the services you are requesting.
              </p>
              <p style="text-align: left">
                Peaqock will keep your personal data for as long as needed to
                handle your request or for as long as we have a relationship
                with you. If you have applied for a role with Bain, Bain will
                keep your personal data associated with your application for a
                period of up to five years.
              </p>
              <p style="text-align: left">
                Please note that Peaqock is not responsible for any information
                you may disclose publicly in any chat rooms, message boards, or
                similar web pages, including those hosted on or linked to our
                Sites. You should keep in mind that, whenever you publicly
                disclose information about yourself online (for example, via
                message boards or chat rooms), such information could be
                collected and used by people whom you do not know. In addition,
                certain message boards and similar user forums may display IP
                addresses along with the message poster's name and message.
                Peaqock bears no responsibility for any action or policies of
                any third parties who collect any information users may disclose
                on the message boards, chat areas or other user forums, if any,
                on the Sites.
              </p>
            </div>
          </div>
        </li>
        <li
        [ngClass]="clicked2 ? 'accordion-item is-active' : 'accordion-item'"
        >
          <a
          (click)="clicked2 = !clicked2"
            class="accordion-title"

            >Use of cookies on the Sites</a
          >
          <div
            class="accordion-content"
          >
            <div class="rte">
              <p style="text-align: left">
                <strong
                  >Our Sites may use cookies or other technology to obtain
                  certain types of information about you. You can disable or
                  delete cookies by going to the Help section of your browser
                  but certain functionalities of our Sites might not work
                  properly if you do so.</strong
                >
              </p>
              <hr />
              <p style="text-align: left">
                We may use cookies or other technology (“cookies”) to obtain
                certain types of information when you browse our Sites.
                "Cookies" are small text files that are stored by your browser
                on your computer's hard drive. Cookies can be placed on your
                computer directly by one of our Sites (first party cookies) or
                by another website (third party cookies).
              </p>
              <p style="text-align: left">
                We may use cookies to keep track of your visit to our Sites,
                speed up navigation and improve your experience of the site,
                (for instance by remembering some of your preferences or
                choices, the last pages you visited or searches you carried out
                or by keeping you logged in).
              </p>
              <p style="text-align: left">
                Our Sites may use cookies such as Google Analytics to carry out
                some analytics on and enhance our Sites. These cookies help us
                understand, for example, how you use our Sites, which pages are
                the most visited or where people visiting our Sites are located.
              </p>
              <p style="text-align: left">
                We may also use cookies to enable you to share content on
                networking or social media sites or for marketing and
                promotional purposes where you have subscribed to our marketing
                communications from Peaqock.
              </p>
              <p style="text-align: left">
                The "help" section of most browsers will tell you how to prevent
                your browser from accepting new cookies, how to have the browser
                notify you when you receive new cookies, and how to disable or
                delete cookies altogether.
              </p>
              <p style="text-align: left">
                If you choose to disable cookies, certain functionalities of our
                Sites might not work.
              </p>
            </div>
          </div>
        </li>
        <li  [ngClass]="clicked3 ? 'accordion-item is-active' : 'accordion-item'">
          <a
           (click)="clicked3 = !clicked3"
            class="accordion-title"

            >Who we share your personal data with
          </a>
          <div
            class="accordion-content"

          >
            <div class="rte">
              <p style="text-align: left">
                <strong
                  >We may share your personal data with other companies of the
                  Peaqock group as well as with other third parties that might be
                  located in a different country than your country of residence.
                  Peaqock will not share or sell your data to any third party for
                  their own use.</strong
                >
              </p>
              <hr />
              <p style="text-align: left">
                We may share your personal data with others, such as:
              </p>
              <ul style="text-align: left">
                <li>
                  other offices in the Peaqock group – for a complete list of our
                  offices, please refer to the
                  <a href="/about/offices/"
                    >Offices page of the bain.com website</a
                  >;
                </li>
                <li>
                  carefully selected third party service providers, such as
                  marketing service providers (if you have subscribed to our
                  marketing communications), IT systems providers, recruitment
                  service providers, background check providers, data cleansing
                  providers or event organizers, so they can provide services to
                  Peaqock;
                </li>
                <li>our advisors;</li>
                <li>
                  other alumni members if you have chosen to take part in the
                  Alumni network; and
                </li>
                <li>
                  law enforcement authorities or other government bodies when we
                  are required to do so.
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                Third parties, other than law enforcement authorities or
                government bodies, will only handle your personal data on behalf
                of Peaqock for the purposes described in this Policy. Peaqock will not
                sell or otherwise share your personal data with such third
                parties for their own use.
              </p>
              <p style="text-align: left">
                The companies of the Peaqock group and other third parties we share
                your information with may be located in other countries,
                including countries that do not offer the same protection to
                your personal data as the country in which you reside. Where
                that is the case, Peaqock ensures that your personal data is
                protected by using appropriate legal mechanisms. If you reside
                in the European Union, such mechanisms include using contracts
                or wording approved by the European Commission (called “<b
                  ><a href="#standard-contractual-clauses"
                    >Standard Contractual Clauses</a
                  ></b
                >”)<sup>[3]</sup> or, in the case of US-based companies, working
                with companies that are
                <a href="https://www.privacyshield.gov/welcome"
                  >Privacy Shield certified</a
                >.
              </p>
            </div>
          </div>
        </li>
        <li   [ngClass]="clicked4 ? 'accordion-item is-active' : 'accordion-item'">
          <a
          (click)="clicked4 = !clicked4"
            class="accordion-title"

            >Links to other websites</a
          >
          <div
            class="accordion-content"

          >
            <div class="rte">
              <p
                style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                "
              >
                <strong
                  >Our Sites may contain links to third party websites that are
                  governed by their own terms and privacy policies. Peaqock does
                  not bear any liability and responsibility for the content or
                  your use of these websites.</strong
                >
              </p>
              <hr />
              <p
                style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                "
              >
                The Sites may contain links to third party websites, including
                social network sites. Such websites are governed by their own
                terms of use and privacy policies, which you should review
                before browsing or submitting information to them. Peaqock is not
                responsible for the privacy practices of such websites. In
                addition, a link to a third party website does not mean that
                Peaqock endorses or accepts any responsibility for the content or
                the use of such web site. Peaqock shall not bear any liability and
                responsibility for such content or for your use of these
                websites.
              </p>
            </div>
          </div>
        </li>
        <li [ngClass]="clicked5 ? 'accordion-item is-active' : 'accordion-item'">
          <a
           (click)="clicked5 = !clicked5"
            class="accordion-title"

            >Security of your personal data</a
          >
          <div
            class="accordion-content"

          >
            <div class="rte">
              <p
                style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                "
              >
                <strong
                  >Peaqock has implemented appropriate technical and organizational
                  security measures in order to protect your personal data from
                  loss, misuse, alteration, or destruction.</strong
                >
              </p>
              <hr />
              <p
                style="
                  margin-top: 0in;
                  margin-right: 26.05pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                "
              >
              Peaqock has implemented appropriate technical and organizational
                security measures in order to protect your personal data from
                loss, misuse, alteration, or destruction. Such measures include
                &nbsp;<b
                  ><a
                    onmouseover="Anonymizing means transforming data in such a way that it is no longer identifiable, i.e. the data can no longer be linked to an individual, even if combined with other information.  Anonymous data is not considered “personal data”."
                    href="#Anonymizing"
                    >anonymizing</a
                  >&nbsp;</b
                ><sup>[4]</sup> or
                <b><a href="#pseudonymizing">pseudonymizing</a></b
                ><sup>[5]</sup> data where possible. Authorized Peaqock personnel
                and third parties mentioned above will only have access to your
                personal data to the extent they need it to do their job or
                provide their services. Despite these precautions, however, Peaqock
                cannot guarantee that unauthorized persons will not obtain
                access to your information.
              </p>
            </div>
          </div>
        </li>
        <li [ngClass]="clicked6 ? 'accordion-item is-active' : 'accordion-item'">
          <a
          (click)="clicked6 = !clicked6"
            class="accordion-title"

            >Your Rights</a
          >
          <div
            class="accordion-content"

          >
            <div class="rte">
              <p style="text-align: left">
                <strong
                  >Depending on where you reside you might have the right to
                  request access to, rectification or erasure of your personal
                  data and to object to or ask for the restriction of the
                  processing of your personal data. You might also have the
                  right to withdraw your consent and request that we stop
                  processing or delete your personal data.</strong
                >
              </p>
              <hr />
              <p style="text-align: left">
                Depending on where you reside, you may be entitled to certain
                rights regarding the personal data Peaqock holds about you. For
                instance, you may have the right to request access to your
                information, obtain a copy of it as well as information about
                how we use it. You have the right to request that we correct
                incorrect, inaccurate or out-of-date information about you. You
                may also have the right to request that we stop processing your
                information and/or withdraw your consent to such processing and
                to ask us to delete your information.
              </p>
              <p style="text-align: left">
                In the event that you have registered on our Alumni site or have
                applied for a consulting role at Peaqock and you no longer wish
                your data to be processed as described in this Policy, or if you
                wish to access, rectify, cancel or oppose such processing,
                please contact us at&nbsp;<a
                  href="mailto:info@Peaqock.com"
                  >info@Peaqock.com</a
                >

              </p>
              <p style="text-align: left">
                If you receive marketing communications from Peaqock, you can opt
                out of receiving future emails from Peaqock or unsubscribe from
                participating in our programs through the "preferences" page,
                which is accessible in every marketing email you receive from
                Peaqock.
              </p>
              <p style="text-align: left">
                If you wish to make a request about your personal data,or consultant
                application data, please contact
                us at
                <a href="mailto:info@Peaqock.com">info@Peaqock.com</a>.
                Please note that we will take reasonable steps to check your
                identity before handling your request.
              </p>
              <p style="text-align: left">
                If you think we are handling your information unlawfully, we
                encourage you to get in touch with us at
                <a href="mailto:info@Peaqock.com">info@Peaqock.com</a>.
                You may also have the right to lodge a complaint with your
                national supervisory authority.
              </p>
            </div>
          </div>
        </li>
        <li [ngClass]="clicked7 ? 'accordion-item is-active' : 'accordion-item'">
          <a
          (click)="clicked7 = !clicked7"
            class="accordion-title"

            >Job Applicants</a
          >
          <div
            class="accordion-content"

          >
            <div class="rte">
              <p style="text-align: left">
                <a id="applicants"></a>If you apply for a job at Peaqock, you will
                be asked to submit information to Peaqock such as your name,
                contact details, information about your education and work
                history and any other background information that might be
                relevant for your application or that you choose to share with
                Peaqock. If you do not provide this information to us, we might not
                be able to process your application.
              </p>
              <p style="text-align: left">
                Peaqock will use this information to assess your application and
                candidacy for the position to which you have applied. Without
                limiting the generality of the foregoing, this may include: (i)
                evaluating your skills and experience in relation to the
                qualification required for the job to which you have applied;
                and (ii) tracking feedback and interactions we have with you
                throughout the recruiting process. We may also use your
                information when analyzing our internal recruiting processes to
                determine (i) which recruit sources to focus on; (ii) how to
                improve integration and training programs for new hires with
                different educational backgrounds and work experience; and (iii)
                how to enhance our interview model to improve hiring quality.
                The information you provide may also be used to communicate with
                you about Peaqock events and to send you publications that we think
                may be of interest to you.
              </p>
              <p style="text-align: left">
                We might share your personal data with other companies in the
                Peaqock group as well as with third parties such as recruitment
                service providers, background check providers and IT system
                providers. These Peaqock companies and third parties might be
                located in a different country than your country of residence.
                In particular, if you apply for a consulting role, your data
                will be stored in a third party database hosted in the
                Netherlands.
              </p>
              <p style="text-align: left">
                We will keep your application data for up to five years. If you
                no longer wish for Peaqock to process your data or if you wish to
                exercise your rights, please contact
                <a href="mailto:info@Peaqock.com">info@Peaqock.com</a> if
                you applied for a consulting role and
                <a href="mailto:info@Peaqock.com">info@Peaqock.com</a>
                if you applied for a corporate role.
              </p>
            </div>
          </div>
        </li>
        <li [ngClass]="clicked8 ? 'accordion-item is-active' : 'accordion-item'">
          <a
          (click)="clicked8 = !clicked8"
            class="accordion-title"
            >Children</a
          >
          <div
            class="accordion-content"

          >
            <div class="rte">
              <p style="text-align: left">
                Our Sites are not directed at children 16 years of age or
                younger. Peaqock, therefore, will not intentionally collect
                information about anyone under the age of 16, and requests that
                no such information be submitted to us.
              </p>
            </div>
          </div>
        </li>
        <li [ngClass]="clicked9 ? 'accordion-item is-active' : 'accordion-item'">
          <a
           (click)="clicked9 = !clicked9"
            class="accordion-title"
            >Contact</a
          >
          <div
            class="accordion-content"
            data-tab-content=""
            role="tabpanel"
            aria-labelledby="8g294m-accordion-label"
            aria-hidden="true"
            id="8g294m-accordion"
          >
            <div class="rte">
              <p style="text-align: left">
                If you have any questions about this Privacy Policy or how Peaqock
                handles your personal data, please contact Peaqock Global Data
                Protection Officer:
              </p>
              <p style="margin: 0in 26.05pt 0.0001pt 28.35pt; text-align: left">
                <strong>Email:&nbsp;</strong>
                <a href="mailto: info@Peaqock.com">info@Peaqock.com</a>
              </p>
              <p style="margin: 0in 26.05pt 0.0001pt 28.35pt; text-align: left">
                <strong>Post:</strong>Imm le Zenith II, Lotissement Attaoufik, Route de Nouacer,
                Sidi Maarouf, Casablanca, 20640,Maroc
              </p>
            </div>
          </div>
        </li>
      </ul>
    </section>


  </div>
</article>
<!-- <div class="contact-form">
  <form>
      <input type="hidden" >
      <div class="large-6 column">
        <div class="contact-form__description">
                <p >We work with ambitious leaders who want to define the future, not hide from it. Together, we achieve extraordinary outcomes.</p>

        </div>
        <label class="input-field">
                <span class="input-field__label-text show-for-sr">Your email</span>

            <input class="input-field__input" type="email" name="Email" placeholder="Your email" required="" >
                <span class="form-error" id="cvx4ut-abide-error" role="alert">Please enter a valid email address</span>

        </label>
        <button class="btn" type="submit"> CONTACT US</button>
    </div>
    <div class="large-6 column">

  </div>


  </form>
</div> -->
