<div class="row block1">
    <div class="col-md-5">
        <h2> Smart Report  </h2>
        <div class="desc">
            A solution that automates and schedules reporting generation in an intuitive way, while respecting the reporting value chain. Smart Report is based on RPA and artificial intelligence technologies.
        </div>
    </div>
    <div class="col-md-7">
        <div class="blocImgsq">
            <img loading="lazy"  src="assets/innovation/Smart Report.png" alt=""/>
        </div>
    </div>
</div>

<div class="bloc2">
    <div class="blockFeatures">
        <div class="tile">Features</div>
            <div class="sep"></div>

            <div class="row">
                <div class="col-md-6">
                    <div class="blockDetail">

                    <div class="zoomIn">
                        <div class="blocImg"><img loading="lazy"  src="assets/consulting/10.png" alt=""/></div>
                    </div>
                        <div class="blocTxt">
                            <div class="title">Object Segmentation</div>
                            <div class="descrp">Object Segmentation (Text, Image, Chart...) makes it possible to transform a Photoshop Template into a Template exploitable in the solution.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="blockDetail">
                        <div class="blocImg"><img loading="lazy"  src="assets/consulting/13.png" alt=""/></div>
                        <div class="blocTxt">
                            <div class="title">Report Generation</div>
                            <div class="descrp">Smart Report is based on artificial intelligence algorithms to generate automatic reporting.</div>
                        </div>
                    </div>
                </div>
            </div>
    </div>



<div class="bloc3 mt-3">
    <div class="tile">Process</div>
    <div class="sep"></div>
    <div data-aos-once="true" data-aos-duration="1000" data-aos="fade-up" class="blocImgs">
        <img loading="lazy"  src="assets/innovation/ProcessSmartReporting.png" alt=""/>
    </div>
</div>
</div>
