import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {SharedService} from 'src/app/commons/services/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public hidden = false;
  route: any;
  innerWidth: any;
  bgTransparante = true;
  className = 'fixed-top ';
  textColor = '';
  logo = 'bg-white2';
  translate = 'transparentTranslate';

  // isHovered = false;
  isScrolled = false;
  scrollOffset;
  public innerWidth2;
  public dataUrl;
  public txtService = '';
  public txtIndustries = '';
  public hiddenin = false;

  constructor(location: Location, private router: Router, private activatedRoute: ActivatedRoute, private sharedService: SharedService) {
    // const data: Data = this.activatedRoute.snapshot.data;
    // console.log("data", data);

    this.activatedRoute.data.subscribe(data => {
      console.log('data', data);
    });
    router.events.subscribe((val) => {
      if (location.path() !== '') {
        this.route = location.path();
        this.className = '';
        this.textColor = '';
        this.logo = 'assets/logo/PEAQOCK.webp';

      } else {
        this.route = 'Home';
        this.className = 'fixed-top';
        this.textColor = 'text-white';
        this.logo = 'assets/logo/PEAQOCK_blanc.webp';

      }
      if (this.route === 'Home') {
        this.isScrolled = true;
        this.className = 'fixed-top bg-trans';
        this.textColor = 'text-white';
        this.logo = 'assets/logo/PEAQOCK_blanc.webp';
      }
      // console.log('roote',this.route);

    });
  }

  ngOnInit() {
    this.innerWidth2 = window.innerWidth;
    this.getSharedDataCountry();
    console.log('activeRoute', this.activatedRoute.snapshot.data.type);

    if (this.route === 'Home') {
      this.isScrolled = true;
      this.className = 'fixed-top bg-trans';
      this.textColor = 'text-white';
      this.logo = 'assets/logo/PEAQOCK_blanc.webp';
    }

  }

//   @HostListener('window:resize', ['$event'])

  getSharedDataCountry() {
    this.sharedService.sharedDataRoute.subscribe((resp: any) => {
      console.log(resp);
      if (resp && resp.type === 'industries') {
        this.txtIndustries = resp.breadcrumb;
        this.txtService = '';
      } else if (resp && resp.type === 'services') {
        this.txtService = resp.breadcrumb;
        this.txtIndustries = '';
      } else {
        this.txtService = '';
        this.txtIndustries = '';
      }
    });
  }

  isActive(instruction): boolean {
    return this.router.isActive(instruction, false);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth2 = window.innerWidth;

  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // console.log(this.scrollOffset);
    if (this.route === 'Home') {
      if (this.scrollOffset <= 570) {
        this.translate = 'transparentTranslate';
        if (innerWidth > 777) {
          this.isScrolled = true;
          this.className = 'fixed-top bg-trans';
          this.textColor = 'text-white';
          this.logo = 'assets/logo/PEAQOCK_blanc.webp';
        }

      } else {
        this.isScrolled = false;
        this.className = 'fixed-top bg-white2';
        this.textColor = 'text-dark';
        this.logo = 'assets/logo/PEAQOCK.webp';
        this.translate = 'whiteTranslate';

      }
    } else {
      this.isScrolled = false;
      this.className = 'fixed-top bg-white2';
      this.textColor = 'text-dark';
      this.logo = 'assets/logo/PEAQOCK.webp';


    }

  }


  isHovered() {
    // if(this.route=='Home'){
    if (this.scrollOffset <= 570) {
      this.className = 'fixed-top bg-white2';
      this.textColor = 'text-dark';
      this.logo = 'assets/logo/PEAQOCK.webp';
    }
    // }else{
    //   //this.isScrolled = false;
    //   this.className="fixed-top bg-white2"
    //   this.textColor=''
    //   this.logo="assets/logo/PEAQOCK.webp"
    // }

  }

  isNotHovered() {
    if (this.route === 'Home') {
      if (this.scrollOffset <= 570) {
        this.className = 'fixed-top bg-trans';
        this.textColor = 'text-white';
        this.logo = 'assets/logo/PEAQOCK_blanc.webp';
      }
    } else {
      // this.isScrolled = false;
      this.className = '';
      this.textColor = '';
      this.logo = 'assets/logo/PEAQOCK.webp';
    }


  }

  navigateTo(fragments?: string) {
    this.router.navigate(['/about-us'], { fragment: fragments });
  }
}
