<div class="">
    <div class="headerBlock">
        <H1 data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"><span>Online testing solutions</span> </H1>
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="separator"></div>
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="descr">Automation of loyalty and discounts programs management</div>
        <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" src="assets/online_test/1.png"
            alt="Online testing solutions">
    </div>

    <div class="bloc1">
        <div class="desc">Evaluating software components under various expected and unexpected conditions is imperative to guarantee deliver secure and reliable sytems.
        </div>
        <h2>Therefore, we offer payment providers a panoply of services to guarantee the quality of their applications and an ideal user experience using
            automated testing. </h2>
    </div>
    <div class="bloc2">
        <div class="">
               <div class="row">
           <div class="col-md-4"></div>
           <div class="col-md-4">
            <h2>Benefits</h2>
            <div class="sepr"></div>
           </div>
           <div class="col-md-4"></div>
       </div>
        <div class="row">
            <div class="col-md-4 blocItem">
               <div class="hImg mb-2"><img loading="lazy"  src="assets/online_test/7.png" alt=""/></div>
                <div class="title">Improve system
                    consistency and performance</div>
            </div>
            <div class="col-md-4 blocItem">
                <div class="hImg mb-2"><img loading="lazy"  src="assets/online_test/6.png" alt=""/></div>
                 <div class="title">Identification and subsequent
                    removal of the errors</div>
             </div>
             <div class="col-md-4 blocItem">
                <div class="hImg mb-2"><img loading="lazy"  src="assets/online_test/3.png" alt=""/></div>
                 <div class="title">Protects the reliability of
                    the software and makes it safe
                    to use in real-life scenarios</div>
             </div>
        </div>
        </div>

    </div>
</div>
