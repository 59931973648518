<div class="mainBLoc">
    <div class="row">
        <div class="col-md-6">
            <div class="cenerBloc">
                <div class="blockDetail" data-aos-once="true" data-aos-duration="1200" data-aos="fade-right">
                    <h2>Funds</h2>
                    <div class="separator"></div>
                    <div class="descrp">Our solution allows you to create rich and automatic reports with a sophisticated design from data from various sources. We adapt this data and give rise to a visualization as well as a sharing of the information released, in different formats.</div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div data-aos-once="true" data-aos-duration="1200" data-aos="fade-left" class=" imgBlock">
                <img loading="lazy"  class="" id="gif-2"
                onmouseover="document.getElementById('gif-2').src='assets/GIF_capabilities/funds.gif'"
                data-mode="video"  src="assets/GIF_capabilities/funds.gif" alt="Dynamic  ashboard">
            </div>
        </div>
    </div>
</div>
<div class="can-be-used-background">
    <div class="row mb-4">
        <div class="col-md-12">
            <div class="blocText">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>A library rich in graphs </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Drag and drop tools, the user can build graphics adapted to the needs of the profession, based on the best frameworks.</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Automation of Daily Processes </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Reporting display in several formats (PDF, HTML, Image…)</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Workflow management and data validation        </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>File management and report sharing</div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>All reporting models designed using Peaqock editor become responsive, by default, on mobile.        </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Creation of predictive reports        </div>
        <div class="col-md-4 itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Ability to propose future data representation
        </div>
    </div>
</div>
<div class="benifits-background">
   <div class="row">
    <div class="col-md-6">
        <div class="d-flex-column"  data-aos-once="true" data-aos-duration="1200" data-aos="fade-left">
            <h2 class="Benefits-title">Benefits</h2>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">+300 connected assets</div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">25% Reduction in service call Back Rates </div>
                </div>
           </div>
            <div class="list">
                <div class="list-item">
                    <div class="icone">
                        <img loading="lazy"  src="assets/icons/cube.png"/>
                        <nz-divider nzType="verticale"></nz-divider>
                    </div>
                    <div class="list-text">10% Reduction in contracts cancellations</div>
                </div>
           </div>
        </div>
    </div>
    <div class="col-md-6">
        <img loading="lazy"  src="assets/Interfaces_Capabilities/Funds.png"  data-aos-once="true"  data-aos-duration="1200" data-aos="fade-up"
        alt="Dynamic ashboard" style="height: 360px;">
    </div>
   </div>
</div>
