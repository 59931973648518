import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private dataRoute = new BehaviorSubject(null);

  sharedDataRoute = this.dataRoute.asObservable();
  constructor() { }
  nextdataRoute(data: any) {
    this.dataRoute.next(data)
  }
}
