<div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" class="imgForData1">
  <img loading="lazy"  src="assets/joinus/manager/projectmanager.png" alt=""/>
</div>
<!--<div class="weare">You are</div>-->
<!--<div class="weare-text">-->
<!--    A person with goals and ambitions who is looking to grow both personally and professionally.-->
<!--    You will be responsible for designing, developing, testing, and debugging responsive web and mobile applications.-->
<!--    The role will require you to translate user and business needs into functional frontend design.-->
<!--    You will be closely involved with users and clients to gain first-hand exposure to their feedback.-->

<!--</div>-->
<div class="mt-4 mb-4 row">
    <div class="col-md-4">
        <div class="imgForData respo-image"><img loading="lazy"  src="assets/joinus/3.png" alt=""/></div>
    </div>
    <div class="col-md-8 centerElemnt">
        <div class="titleData">Responsibilities</div>
        <div class="ssseparateurProduct"></div>

        <div class="respon-text">
            <img loading="lazy"  src="/assets/joinus/right.png">
            <span>Designing, developing and testing UI for mobile and web applications</span>
        </div>
        <div class="respon-text">
            <img loading="lazy"  src="/assets/joinus/right.png">
            <span>Build reusable code and libraries for future use</span>
        </div>
        <div class="respon-text">
            <img loading="lazy"  src="/assets/joinus/right.png">
            <span>Translate user and business needs into functional frontend code</span>
        </div>
    </div>

</div>
<!--  -->
<div class="mt-4 mb-4 row">
    <div class="col-md-8">
        <div class="titleData">Qualifications</div>
        <div class="ssseparateurProduct"></div>
        <div class="respon-text">
            <img loading="lazy"  src="/assets/joinus/right.png">
            <span>Current Undergrad students completing a BS/BA in Computer Science or Engineering</span>
        </div>
        <div class="respon-text">
            <img loading="lazy"  src="/assets/joinus/right.png">
            <span>Understanding of User Experience design and its importance</span>
        </div>
    </div>
    <div class="col-md-4">
        <div class="imgForData qualif-image"><img loading="lazy"  src="assets/joinus/4.png" alt=""/></div>
    </div>

</div>
