<div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" class="imgForData1">
  <img loading="lazy"  src="assets/joinus/manager/projectmanager.png" alt=""/>
</div>

<div class="mt-4 mb-4 row">
  <div class="col-md-4">
    <div class="imgForData respo-image"><img loading="lazy"  src="assets/joinus/3.png" alt=""/></div>
  </div>
  <div class="col-md-8 centerElemnt">
    <div class="titleData">Responsibilities</div>
    <div class="ssseparateurProduct"></div>
    <div class="sousTitleData">Technical Skills</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Knowledge of Angular and Typescript.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Suggest suitable software solutions.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Develop and execute unit tests.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Help in fixing bugs at different test cycles.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Develops and conducting functional tests. </span>
    </div>
    <div class="sousTitleData sousTitleData2">Soft Skills</div>

    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Teachable </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Dedication </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Diligence </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Curious about technology trends </span>
    </div>
  </div>

</div>
<div class="row">
  <div class="col-md-6 mt-4 mb-4">
    <div class="titleData">Primary Contacts</div>
    <div class="ssseparateurProduct"></div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Reports to tech lead  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> IT team and staff  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Project manager </span>
    </div>

  </div>

  <div class="col-md-6 mt-4 mb-4">
    <!--    <div class="imgForData qualif-image"><img loading="lazy"  src="assets/joinus/4.png" alt=""/></div>-->

    <div class="titleData">Job Requirements</div>
    <div class="ssseparateurProduct"></div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Bac+2, bachelor’s or master’s degrees </span>
    </div>
  </div>
</div>
