import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qa-tester',
  templateUrl: './qa-tester.component.html',
  styleUrls: ['./qa-tester.component.css', '../back-trainee/back-trainee.component.css']
})
export class QaTesterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
