

<div class="all" >
    <div class="parenttt" *ngFor="let news of listNews">
        <div class="image-fix">
            <img class="card-img-top" src="{{news.image}}" alt="Card image cap">
        </div>
   
    <div class="block">
        <h5 class="titles">{{news.title}}</h5>
        <p class="description">{{news.description.slice(0, 151)}}{{!news.showFullDesc ? ' ... ' : news.description.slice(151)}}</p>
        <button (click)="navigateTo(news.id)"  class="btn-read">READ MORE <img height="12px" src="/assets/images/send.svg" alt="send"></button>
    </div>

</div>
</div>
    