<div class="main">
    <div style="padding: 30PX 5%;">
        <div class="title mb-3">Digitizing Bank details verification document on blockchain        </div>
        <div class="desc mb-3"><span>P-BlokuSign</span> is a blockchain application that makes it easier than ever to digitally sign and send documents.         </div>
        <div class="row">
            <div class="col-md-3 centerElement">
                <div class="blocItem">
                    <div class="title1">
                        <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>
                        Key Features
                    </div>
                    <div class="desc1">Cannot be Corrupted, <br>
                        Decentralized Technology, <br>
                        Enhanced Security,<br> Consensus, <br>
                        Faster Settlement

                    </div>
                </div>
            </div>
            <div class="col-md-3 centerElement">
                <div class="blocItem clr">
                    <div class="title1">
                        <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>
                        Benefits
                    </div>
                    <div class="desc1">Less Failure, <br>
                        User Control, <br>
                        Zero Scams, <br>
                        Less Prone to Breakdown, <br>
                        Transparency
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <img loading="lazy"  class="imgBlc" src="assets/block_chain/aa.png" alt=""/>
            </div>
        </div>
    </div>


    <div class="bloc2">
        <div class="title">Blockchain Process </div>

        <img loading="lazy"  src="assets/block_chain/blockchain.png" alt=""/>
    </div>
</div>
