<div class="">
    <div class="headerBlock">
        <H1 data-aos-once="true" data-aos-duration="1500" data-aos="fade-right"><span>Open banking</span> </H1>
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="separator"></div>
        <div data-aos-once="true" data-aos-duration="1500" data-aos="fade-right" class="descr">  Seize with us the Open Banking Opportunities and benefits</div>
        <img loading="lazy"  data-aos-once="true" data-aos-duration="1500" data-aos="fade-left" src="assets/open_banking/OpenBanking.png" alt="Fraud solutions">
    </div>

    <div class="bloc1">
        <div class="centerElemnt">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-6">
                        <div class="title mb-5"> Seize with us the Open Banking Opportunities and benefits</div>
                        <div class="desc"> Driven by customer expectation, enabled by the emergence of new technologies and enhanced by the regulatory changes, open banking heralds a whole new era in the history of banking. <br>
                            Actually, Financial institutions will need to ensure that their clients data is protected, shared securely, quickly, and efficiently.<br>
                            Peaqock payment solutions provide you with the tools to meet all of these challenges and allow you to become more customer-centric.
                            </div>
            </div>
        </div>
    </div>
    </div>

    <div class="bloc2">
        <div class="">
            <h2>Benefits</h2>
            <div class="sepr"></div>
            <div class="desc">Peaqock payment solutions provide you with the tools to meet all of these challenges and allow
                you to become more customer-centric.</div>
        </div>
        <div class="displayInMobile">
            <div class="itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>   Serve adjacent consumer needs</div>
            <div class="itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>Protect consumers</div>
            <div class="itemUse"> <i nz-icon nzType="check-circle" nzTheme="outline"></i><br>   Make payments safer and more secure</div>
             <img loading="lazy"  class="imgMobile" src="assets/open_banking/mobile.png" alt=""/>

        </div>
         <div class="bloc2Img">
            <div class="imgMob"> <img loading="lazy"  src="assets/open_banking/mobile1.png" alt=""/> </div>
             <div class="brd brd1">
                <div class="itemUsed itm1"> <i nz-icon nzType="check-circle" nzTheme="outline"></i> Serve adjacent <br> consumer needs</div>
                <span></span>
                <div class="brd brd2">
                <div class="itemUsed itm2 tst"> <i nz-icon nzType="check-circle" nzTheme="outline"></i>Protect consumers</div>
                    <span></span>
                    <div class="brd brd3">
                <div class="itemUsed itm3"> <i nz-icon nzType="check-circle" nzTheme="outline"></i> Make payments safer <br> and more secure</div>
                        <span></span>
                    </div>
                </div>
            </div>
         </div>
    </div>

    <div class="bloc3">
        <div class="mb-5">
            <h2>Capabilities</h2>
            <div class="sepr"></div>
            <div class="desc"> <span>Peaqock payment</span>  services go beyond open banking compliance and are suitable for
                direct or indirect monetization in an <span>API marketplace model.</span>  </div>
        </div>

        <div class="navigateProduct">
            <div class="d-flex-column">
            <div class="felxBetween" data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" >
                <div class="blocmAuto">
                    <div class=" card slow-transition"  [routerLink]="['/products','api']">
                        <div class="card-body">
                           <div class="cardImg">
                               <img loading="lazy"  src="assets/open_banking/API Paymn.png" alt=""/>
                               <div class="card-title bgPosition bg-dataone1">Payment API</div>
                           </div>
                           <div class="mini-text"><span>Banking</span></div>
                           <div class="label"> Our solutions are enabling you and
                               your customers to manage cash</div>
                           <p class="discription"> Our solutions are enabling you and your customers to manage cash, process payments, exchange information and transfer funds cost-effectively, securely and reliably within and across national boundaries.</p>
                           <div class="readmore"><span>Read More</span> <i nz-icon nzType="plus-circle" nzTheme="fill"></i></div>
                       </div>
                   </div>
                </div>

                <div class="blocmAuto">
                    <div class=" card slow-transition"   [routerLink]="['/products','pfm']">
                        <div class="card-body">
                           <div class="cardImg">
                               <img loading="lazy"  src="assets/open_banking/pfm.png" alt=""/>
                               <div class="card-title bgPosition bg-funds1">PFM</div>
                           </div>
                           <div class="mini-text"><span>Banking</span></div>
                           <div class="label">Our solution offers highly personalized ‘concierge banking’ services </div>
                           <p class="discription"> Our solution offers highly personalized ‘concierge banking’ services (money management, budgeting tools and savings goals) it would also give customers insight into how best to optimize their finances.</p>
                           <div class="readmore"><span>Read More</span> <i nz-icon nzType="plus-circle" nzTheme="fill"></i></div>
                       </div>
                   </div>
                </div>

            </div>
         </div>
        </div>
    </div>
</div>
