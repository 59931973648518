<div  data-aos-once="true" data-aos-duration="1500" data-aos="fade-up" class="imgForData1">
  <img loading="lazy"  src="assets/joinus/manager/projectmanager.png" alt=""/>
</div>
<div class="weare">Position Summary</div>
<div class="weare-text">
  We are seeking a skilled frontend software engineer to join our dynamic team. The ideal candidate will have experience with Angular 5/6/7/8 … 13 framework, JavaScript, and TypeScript, as well as familiarity with NgRx and RxJs. The successful candidate will also have a strong understanding ES6+, TypeScript, HTML, and CSS/SASS Ng Zorro, and at least 2 years of professional experience in software engineering.
</div>




<div class="mt-4 mb-4 row">
  <div class="col-md-4">
    <div class="imgForData respo-image"><img loading="lazy"  src="assets/joinus/3.png" alt=""/></div>
  </div>
  <div class="col-md-8 ">
    <div class="titleData">Primary Responsibilities</div>
    <div class="ssseparateurProduct"></div>
    <div class="sousTitleData">Technical Skills</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Build web applications using the latest web technologies (Angular, CSS, JavaScript, TypeScript) based on user needs.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Investigate specific client-side areas like performance, cross-browser compatibility, animations and accessibility.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Practice disciplined software engineering (automated testing, code reviews, and writing readable code).</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Tune web applications to be high-performing, intuitive, and functional through experimentation and data analysis.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Building reusable components and frontend libraries for future use</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Translate designs and wireframes into high quality code</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Optimize components for maximum performance across a vast array of web capable devices and browsers.</span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>	Stay up to date with the latest developments of frontend technologies and best practices</span>
    </div>

    <div class="sousTitleData sousTitleData2">Soft Skills</div>

    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <!--      <span> More than 7 years of overall experience and minimum of 2 years of IT experience. </span>-->
      <span> Problem-solving. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Attention to details. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Communication, collaboration, and teamwork. </span>
    </div>
  </div>

</div>
<div class="row">
  <div class="col-md-6 mt-4 mb-4">
    <div class="titleData">Job Requirements</div>
    <div class="ssseparateurProduct"></div>
    <div class="sousTitleData sousTitleData2">Education</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Bachelor’s or master’s degree in Computer Science or a related field.  </span>
    </div>

    <div class="sousTitleData sousTitleData2">Experience</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>	At least 2 years of relevant experience in frontend development using Angular 5/6/7/8 … 14 framework.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Experience with JavaScript and TypeScript.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Experience with NgRx and RxJs.  </span>
    </div>



    <div class="sousTitleData sousTitleData2">Knowledge and Skills</div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>	Proven experience with Angular 5/6/7/8 … 14 framework, JavaScript and TypeScript.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>	Strong skills in fundamental frontend web technologies including ES6+, TypeScript, HTML, and CSS/SASS Ng Zorro.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Knowledge of application architecture.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Tools: Jira, CI/CD.  </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>Nice to have knowledge: ReactJS, GraphQL, AWS.  </span>
    </div>
  </div>

  <div class="col-md-6 mt-4 mb-4">
    <!--    <div class="imgForData qualif-image"><img loading="lazy"  src="assets/joinus/4.png" alt=""/></div>-->

    <div class="titleData">Primary Contacts</div>
    <div class="ssseparateurProduct"></div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> Reports to Tech Lead. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span> IT team and staff. </span>
    </div>
    <div class="respon-text">
      <img loading="lazy"  src="/assets/joinus/right.png">
      <span>CEO. </span>
    </div>
  </div>

  <div class="col-md-12 mt-4 mb-4">
    <div class="weare-text">
      We offer a fast-paced, collaborative work environment and the opportunity to work on challenging and meaningful projects. If you are a self-starter with a passion for technology and a desire to make a real impact, we would love to hear from you!
    </div>
  </div>
</div>

